<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue
    style="width: 100%; height: 100%"
    class="ag-theme-wts"
    :columnDefs="grid.columnDefs"
    :rowData="grid.rowData"
    :headerHeight="grid.rowHeight"
    :rowHeight="grid.rowHeight"
    rowSelection="single"
    @row-clicked="selectSymbol"
    @grid-ready="onGridReady" />
  </div>
</template>
  
<script>
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic

export default {
  name: "AgCoinTable",
  components: {
    AgGridVue,
  },
  props:{
    //
  },
  computed:{
    getCoinSymbolList: function(){
      return window.$store.getters['Symbol/getCoinSymbolList']
    },    
    getSelectCoinSymbol: function(){
      return window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    },
  },
  watch:{
    getCoinSymbolList(newVal){
      this.coinSelSymbol = newVal[0];
      //종목 리스트 created에서 읽지 못하는경우가 있어서 감지로 처리
      this.selectCoinSymbolList();
    },
    getSelectCoinSymbol(newVal){
      if (newVal != null){
        this.coinSelSymbol = newVal;

        let selYn = "N";
        //선택종목 셀렉트표시
        this.gridApi.forEachNode((node) => {
          if (node.data.name == newVal.name){
            node.setSelected(true, false, true);
            selYn = "Y";
          }
        });

        //선택된 종목이 없으면 전체 비선택
        if (selYn == "N"){
          this.gridApi.forEachNode((node) => {
            node.setSelected(false, false, true);
          });
        }
      }
    }
  },
  data() {
    return {
      grid:{
        columnDefs: [
          {
            headerName: "종목명", 
            field: "description",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
          { 
            headerName: "코드",
            field: "name",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
          },
        ],
        rowData: null,
        autoSizeStrategy: null,
        paginationPageSize: null,
        currentPage: '',
        totalPages: '',
        rowHeight: '',
      },
      gridApi: null,
      gridColumnApi:null,
      isGridReady:null,
      coinSelSymbol:null,
    }
  },
  created() {
    this.isMobile();
  },
  mounted(){
    this.coinSelSymbol = window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
  },
  methods: {
    isMobile() {
      if (window.name == undefined || window.name == null || window.name == ""){
        if (window.innerWidth <= 1200) {
          return this.grid.rowHeight = 40;
        } else {
          return this.grid.rowHeight = 30;
        }
      }else{
        return this.grid.rowHeight = 30;
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.isGridReady = true;

      this.selectCoinSymbolList();
    },
    selectSymbol(val){
      if (val.data.name == this.coinSelSymbol.name){
        this.$emit("selectCoinSymbol"); //모바일 닫기
        return;
      }
      this.coinSelSymbol = val.data;
      window.$store.dispatch('SocketCoinPrice/setSelectCoinSymbol', val.data);
      this.sendOmsCoinLogMessage("종목 선택 "+val.data.name);
      this.$emit("selectCoinSymbol"); //모바일 닫기
    },
    selectCoinSymbolList(){
      let coinSymbolList = window.$store.getters['Symbol/getCoinSymbolList'];
      this.grid.rowData = [];
      console.log("coinSymbolList", coinSymbolList)
      
      for (let i=0; i<coinSymbolList.length; i++){
        this.grid.rowData.push(coinSymbolList[i]);    
      }
    },
    sendOmsCoinLogMessage(message){
      if (window.name == "orderPop1" || window.name == "orderPop2"){
        message = "팝업("+window.name + ") " + message;
        window.opener.$store.dispatch('SocketCoinOms/setLogMsg', {message:message});
      }else{
        window.$store.dispatch('SocketCoinOms/setLogMsg', {message:message});
      }
    }
  },
}

</script>
  
<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>