import { render, staticRenderFns } from "./AgMarginEdit.vue?vue&type=template&id=741ffc96"
import script from "./AgMarginEdit.vue?vue&type=script&lang=js"
export * from "./AgMarginEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports