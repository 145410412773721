<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-wts"
      :columnDefs="grid.columnDefs"
      :rowData="grid.rowData"
      :headerHeight="30"
      :rowHeight="40"
      :columnHoverHighlight="true"
      :overlayNoRowsTemplate="grid.overlayNoRowsTemplate"
      @grid-ready="onGridReady"
    />
  </div>
</template>
  
<script>
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
import AgInstrument from "@/components/coin/AgInstrument.vue";
import AgPositionType from "@/components/coin/AgPositionType.vue";
import AgTime from "@/components/coin/AgTime.vue";
import AgViewTpSl from "@/components/coin/AgViewTpSl.vue";
import AgModify from "@/components/coin/AgModify.vue";
import AgOrdCancel from "@/components/coin/AgOrdCancel.vue";
import Utils from '@/modules/utils';
import EventBus from '@/modules/event-bus';
import {coinBuySellType, cellClassRulesMinus} from '@/modules/grid-utils.js';
  
export default {
  name: "AgTransactionTableUnfilled",
  components: {
    AgGridVue,
    AgInstrument,
    AgPositionType,
    AgTime,
    AgViewTpSl,
    AgModify,
    AgOrdCancel
  },
  props:{
    coinAccount: {
      type: Object
    },
  },
  computed: {
    getCoinSymbolList: function(){
      let symbolList = window.$store.getters['Symbol/getCoinSymbolList']
      return symbolList;
    },
    resultCoinOms: function(){
        return window.$store.getters['SocketCoinOms/getCompleteOrder'];
    },
    getSelectCoinSymbol: function(){
      return window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    },
    getCoinPosOrdStList: function(){
      return window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
    },
    getUsrPos: function(){
      return window.$store.getters['SocketCoinOms/getUsrPos'];
    }
  },
  watch: {
    getCoinSymbolList(coinSymbolList){
      this.coinSymbolList = coinSymbolList;
      //this.searchPosOrdStList();
    },
    resultCoinOms(){ 
      //this.searchCoinPosOrdStList();
    },
    getSelectCoinSymbol(newVal){
      if (newVal == undefined) return;
      //종목이 변경될경우 소켓데이터 연결 갱신
      this.coinSymbol = newVal;
    },
    getCoinPosOrdStList(newList){
      this.coinPosOrdStList = newList;
      this.searchCoinPosOrdStList();
    },
    coinAccount:{
      handler(newVal){       
        this.searchCoinPosOrdStList();
      }
    },
    getUsrPos(usrPos){
      if (usrPos != undefined){
        this.usrPos = usrPos;
      }
    }
  },
  data() {
    return {
      grid:{
        columnDefs: [
          { 
            headerName: "시간", 
            field: "agTime",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellRenderer: 'AgTime',
          },
          { 
            headerName: "종목", 
            field: "symCd",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellRenderer: 'AgInstrument'
          },
          { 
            headerName: "타입",
            field: "type",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) =>{
              let type = "지정가";
              if (d.data.ordType == 'SL'){
                if (d.data.slTpCd == "1"){
                  type = "익절 시장가";
                }else if (d.data.slTpCd == "2"){
                  type = "손절 시장가";
                }
              }
              return type;
            }
          },
          { 
            headerName: "구분",
            field: "ordSdNm",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: coinBuySellType,
          },
          { 
            headerName: "가격",
            field: "execPrc",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) =>{
              let val = d.value;
              let symbol = null;
              this.coinSymbolList.forEach(item => {
                if (item.symbolno == d.data.symNo){
                    symbol = item;
                    return;
                }
              });
              return val.toFixed(this.tikDotSz(symbol));
            },
          },
          { 
            headerName: "수량",
            field: "balQty",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) =>{
              let val = d.value;
              if (d.data.ordSdCd === '1'){
                val = val * -1;
              }
              if (d.data.ordType == 'SL' && d.data.ordQty == 0){
                return '청산';
              }else{
                return Utils.numberComma(val);
              }
            },
            cellClassRules: {
              blue: (d) => d.data.ordSdNm === '매도',
              red: (d) => d.data.ordSdNm === '매수',
            },
          },
          // { 
          //   headerName: "Reduced Only",
          //   field: "Reduced Only",
          //   minWidth: 80,
          //   flex: 1,
          //   cellStyle: {
          //     justifyContent: 'center'
          //   },
          //   headerClass: "center-align-header",
          //   suppressMovable: true,
          // },
          // { 
          //   headerName: "Post Only",
          //   field: "Post Only",
          //   minWidth: 80,
          //   flex: 1,
          //   cellStyle: {
          //     justifyContent: 'center'
          //   },
          //   headerClass: "center-align-header",
          //   suppressMovable: true,
          // },
          // { 
          //   headerName: "Trigger",
          //   field: "Trigger",
          //   minWidth: 80,
          //   flex: 1,
          //   cellStyle: {
          //     justifyContent: 'center'
          //   },
          //   headerClass: "center-align-header",
          //   suppressMovable: true,
          // },
          { 
            headerName: "정정",
            field: "actModify",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellRenderer: 'AgModify',
          },
          { 
            headerName: "TP/SL",
            field: "actTpSl",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellRenderer: 'AgViewTpSl',
            cellRendererParams: {
              clicked: (data) => {
                this.modifyTpSl(data);
              },
            },
          },
          { 
            headerName: "취소",
            field: "actCancel",
            minWidth: 80,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellRenderer: 'AgOrdCancel',
            cellRendererParams: {
              clicked: (data) => {
              console.log("actCancel ", data)
              if (data.ordType == 'ORD'){
                this.cancelStock(data);
              }else if (data.ordType == 'SL'){
                this.cancelTpSl(data);
              }

            },
            },

          },
        ],
        rowData: [],
        currentPage: '',
        totalPages: '',
        rowHeight: '',
        overlayNoRowsTemplate: '<span style="margin-top: 30px;">조회된 실시간 미체결이 없습니다.</span>',
      },
      gridApi: null,
      gridColumnApi:null,
      isGridReady:false,
      coinSymbolList:[],
      selectList:[],
      currencyInfo:{currency:"USDT"},
      coinSymbol: null,
      orderFlag:false,
      orderSetting: null,
      coinPosOrdStList:[],
      usrPos: {cpos:'1', leverage:1, marginMode:'1'}      
    };
  },
    created() {
    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.loadCustom();
    })
  },
  mounted(){
    this.loadCustom();
    this.searchCoinPosOrdStList();

    
    if (this.coinSymbolList == undefined || this.coinSymbolList.length == 0){
        this.coinSymbolList = window.$store.getters['Symbol/getCoinSymbolList'];
    }

    if (this.coinSymbol == undefined || this.coinSymbol == null){
        this.coinSymbol = window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    }
  },
  methods: {
    loadCustom(){
      this.orderSetting = window.$store.getters['Layout/getOrderSetting'];
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.isGridReady = true;
      this.searchCoinPosOrdStList();
    },
    async searchCoinPosOrdStList(){
      if (!this.coinAccount.accId) return;

      //this.grid.rowData = [];
      this.selectList = [];
      

      if (this.coinPosOrdStList.length == 0){
        this.coinPosOrdStList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList']
      }

      if ( this.coinPosOrdStList) {
        let rowData = [];
        this.coinPosOrdStList.forEach((data) => {
          if (data.ordType == "ORD"){
            rowData.push(data);
          }
          if (data.ordType == 'SL'){
            rowData.push(data);
          }
        });

        this.grid.rowData = rowData;        
      }
        if (this.gridApi != null){
          //this.gridApi.refreshCells(); //현재가 보이도록 셀 새로고침
        }
          
    },
    tikVal(coinSymbol){
      return Utils.tikVal(coinSymbol);
    },
    tikDotSz(coinSymbol){
      if (coinSymbol == null){
        return 0;
      }
      return Utils.tikDotSz(coinSymbol);
    },
    //스탑로스 팝업 호출
    tpslPop(){
      console.log("스탑로스 팝업 호출...");
    },
    //지정가 청산팝업 호출
    orderLimitPop(selData){
      console.log("지정가 청산 팝업 호출...");
    },
    //미체결 주문 취소
    cancelStock(data) {
      if ( data.symCd === undefined
        || data.execPrc === undefined ) {
        return;
      } 
  
      let orgOrders = new Array();
      let totCnt = 0;
      let totOrdQty = 0;

      totCnt++;
      totOrdQty += data.ordQty;

      const orgOrder = {
        accId : this.coinAccount.accId,
        symCd : data.symCd,
        symNo : data.symNo,
        trdDt : data.trdDt,
        ordId : 0,
        orgOrdNo: data.ordId,
        ordSdCd : data.ordSdCd,
        ordTpCd : data.ordTpCd,
        ordPrc : data.execPrc,
        ordQty : data.ordQty,
        repReqQty : data.repReqQty,
        cxlReqQty : data.ordQty,
        orgUsrKey : data.usrKey,
      }

      orgOrders.push(orgOrder);
        
      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.coinAccount.accId,
          symCd : data.symCd,
          symNo : data.symNo,
          //accPass : this.getUsrAccPass,
          orderList : orgOrders,
          ordSdCd : data.ordSdCd
      }

      let coinSymbolData;

      this.coinSymbolList.forEach(symbol=>{
        if (symbol.name == data.symCd){
          coinSymbolData = symbol;
        }
      })

      let message = "코인 미체결리스트 지정가 취소 클릭 "+ data.symCd;
      this.sendCoinOmsLogMessage(message);
  
      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        const orderData = {apiCode:"COMS0002", order: order, symbol:coinSymbolData ,qty:totOrdQty, price: data.execPrc, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendCoinOmsMessage('COMS0002', order)

      }
    },
    //미체결 TP/SL 취소소
    cancelTpSl(data) {
      if ( data.symCd === undefined
        || data.execPrc === undefined ) {
        return;
      }

      if (data.posSeq == undefined || data.posSeq == 0) {
        window.$alert.alert('포지션 정보가 없습니다.')
        return;
      }

      let orgTpSlOrders = new Array()
      let totCnt = 0;
      let totOrdQty = 0;
      totCnt++;
      totOrdQty += data.ordQty

      const orgOrder = {
        accId : this.coinAccount.accId,
        symCd : data.symCd,
        symNo : data.symNo,
        trdDt : data.trdDt,
        cposTpCd : data.cposTpCd,
        slId : data.ordId,
        slTpCd : data.slTpCd,
        prcTpCd : data.prcTpCd,
        slOrdPrc: data.execPrc,
        slOrdQty: data.ordQty,
        posSeq : data.posSeq,
        slOrdSdCd: data.ordSdCd,
        ordSdCd: data.ordSdCd,
      }

      orgTpSlOrders.push(orgOrder);
      
      let order = {
          usrKey : Utils.getUuidv4(),
          accId : this.coinAccount.accId,
          symCd : this.coinSymbol.name,
          symNo : this.coinSymbol.symbolno,
          slTpCd : data.slTpCd,
          //accPass : this.getUsrAccPass,
          orderList : orgTpSlOrders
      }

      let message = "코인 미체결리스트";
      if (data.slTpCd == "1"){
        message += " 익절";
      }else if (data.slTpCd == "2"){
        message += " 손절";
      }

      message += " 취소 클릭 "+ data.symCd;
      this.sendCoinOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert){
        let coinSymbolData;

        this.coinSymbolList.forEach(coinSymbol=>{
          if (coinSymbol.name == data.symCd){
            coinSymbolData = coinSymbol;
          }
        })

        const orderData = {apiCode:"COMS0008", order: order, symbol:coinSymbolData ,qty:totOrdQty, price: data.execPrc, message:message};
        this.$modal.show("modalConfirmOrder", orderData);
      }else{
        this.sendCoinOmsMessage('COMS0008', order);
      }
    },
    //손절 익절 설정
    modifyTpSl(data){
      console.log("modifyTpSl data >>> ", data)
    },
    sendCoinOmsMessage(apiCode, body) {
      console.log("sendCoinOmsMessage", apiCode, body, window.name)
      //팝업주문창여부 확인
      if (window.name == undefined || window.name == null || window.name == ""){
        if ( !window.$store.getters['SocketCoinOms/getIsConnectCoinOms'] ) {
          window.$alert.alert('코인 주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
          return
        }
        //비밀번호 추가
        // body.accPass = this.getUsrAccPass; 
        window.$store.dispatch('SocketCoinOms/sendCoinOmsMessage', {apiCode:apiCode, body:body})
      }else{
        if ( !window.opener.$store.getters['SocketCoinOms/getIsConnectCoinOms'] ) {
          window.$alert.alert('코인 주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
          return
        }

        window.opener.$store.dispatch('SocketCoinOms/sendCoinOmsMessage', {apiCode:apiCode, body:body})
      }
    },
    sendCoinOmsLogMessage(message){
      if (window.name == "orderPop1" || window.name == "orderPop2"){
        message = "코인 팝업("+window.name + ") " + message;
        window.opener.$store.dispatch('SocketCoinOms/setLogMsg', {message:message});
      }else{
        if (window.location.pathname == "/mobile"){
          message = "코인 모바일 " + message;
        }
        window.$store.dispatch('SocketCoinOms/setLogMsg', {message:message});
      }
    }
  },
};

</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>