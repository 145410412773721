<template>
  <div>
    <div style="width: 500px">
      <SliderLeverage :min="0" :max="50" :divisor="5"/>
    </div>
    <br><br><br>
    <p>inputs h30,12px mobile h40,13px</p>
    <input type="text" placeholder="ID">
    <input type="password" placeholder="PW">
    <div class="input-unit">
      <input type="text"><span class="unit">원</span>
    </div>
    
    <br><br>
    <p>buttons h30,12px mobile h40,13px</p>
    <div class="btn-container">
      <button class="btn-primary darkgray">취소</button>
      <button class="btn-primary blue">입금신청</button>
      <button class="btn-primary line">+100만</button>
    </div>
    
    
    <br><br>
    <p>dropdown h30,12px mobile h40,13px</p>
    <div class="base-dropdown">
      <div class="dropdown">
        <div class="selected">1. 일반</div>
        <img src="@/assets/icons/dropdown-triangle.svg" alt="">
      </div>
      <ul class="dropdown-list">
        <li>1. 일반</li>
        <li>2. 모의투자</li>
      </ul>
    </div>


    <br><br><br><br><br><br><br><br>
    <p>tab h40,13px, mobile h40,13px</p>
    <div class="tab-nav-container">
      <div class="tab active">입금신청</div>
      <div class="tab">출금신청</div>
    </div>


    <br><br><br>
    <p>checkmark 12px mobile 13px</p>
    <div class="checkbox-btn">
      <img class="checkbox-off" src="@/assets/icons/checkbox-off.svg" alt="">
      <img class="checkbox-on" src="@/assets/icons/checkbox-on.svg" alt="">
      <span>매수체결</span>
    </div>
    <div class="checkbox-default">
      <input type="checkbox" id="all" value="" />
      <label for="all">매수체결</label>
    </div>


    <br><br><br>
    <p>check</p>
    <Checkbox label="매수체걸" />
  </div>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue';
import SliderLeverage from '@/components/coin/SliderLeverage.vue';
import Slider from '@/components/coin/Slider.vue';

export default {
  components: { Checkbox, SliderLeverage, Slider },
  name: 'WhiteLabelComponentsPage',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>