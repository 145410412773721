<template>
  <div style="width: 100%; height: 100%">
    <ag-grid-vue style="width: 100%; height: 100%" class="ag-theme-wts" :columnDefs="grid.columnDefs"
      :rowData="grid.rowData" :headerHeight="rowHeight" :rowHeight="rowHeight" :pagination="true"
      :suppressPaginationPanel="true" :suppressScrollOnNewData="true" :columnHoverHighlight="true"
      @grid-ready="onGridReady" :overlayNoRowsTemplate="grid.overlayNoRowsTemplate" />
    <PaginationComp :paging="grid.paging" @onPage="search" />
  </div>
</template>

<script>
// import { ref } from 'vue';
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
// import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridVue } from "ag-grid-vue"; // Vue Grid Logic
// eslint-disable-next-line no-unused-vars
import PaginationComp from '@/components/agTables/PaginationComp.vue';
import Utils from '@/modules/utils'
import gridUtils from '@/modules/grid-utils';
import { buySellType, cellClassRulesMinus } from '@/modules/grid-utils.js';

export default {
  name: "AgCoinProfitLossTable",
  components: {
    AgGridVue,
    PaginationComp,
  },
  props: {
    account: {
      type: Object
    },
    setGridSearch: {
      type: Object
    }
  },
  watch: {
    setGridSearch: {
      handler(newVal) {
        console.log("setGridSearch ", newVal)
        this.gridSearch = newVal;
        this.search(1);
      },
      deep: true
    }
  },
  data() {
    return {
      gridSearch: {
        accId: 0,
        //accPass: '',
        symCd: '',
        fromDate: Utils.getDatePrevMonth(1),
        toDate: Utils.getToday(),
        //crc: 'USD',
      },
      grid: {
        columnDefs: [
          {
            headerName: "거래일자",
            field: "trdDt",
            minWidth: 110,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            valueFormatter: (d) => {
              if (d.value) {
                return d.value.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
              } else {
                return '';
              }
            },
          },
          {
            headerName: "총손익",
            field: "totPlMn",
            minWidth: 110,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          },
          {
            headerName: "매매손익합",
            field: "coinPlMn",
            minWidth: 110,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          },
          {
            headerName: "수수료합",
            field: "coinCms",
            minWidth: 110,
            flex: 1,
            cellStyle: {
              justifyContent: 'center'
            },
            headerClass: "center-align-header",
            suppressMovable: true,
            cellClassRules: cellClassRulesMinus,
            valueFormatter: gridUtils.numberComma,
          }
        ],
        rowData: [],
        bottomRowData: [],
        paging: {
          page: 1,
          size: 20,
          total: 0,
          pageSize: 10
        },
        overlayNoRowsTemplate: '<span style="margin-top: 30px;">조회된 일별손익이 없습니다.</span>',
        gridApi: null,
        autoSizeStrategy: null,
        paginationPageSize: null,
      },
      currentPage: '',
      totalPages: '',
      rowHeight: '',
      mobileMinWidth: ''
    };
  },
  created() {
    this.isMobile();
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
    },
    isMobile() {
      if (window.innerWidth <= 1200) {
        return this.rowHeight = 40;
      } else {
        return this.rowHeight = 30;
      }
    },
    async search(page) {
      if (page) {
        this.grid.paging.page = page
      }

      // 조회 조건
      this.gridSearch.accId = this.account.accId
      this.gridSearch.page = this.grid.paging.page
      this.gridSearch.size = this.grid.paging.size

      const response = await this.$lhttp.get('/api/account/coinprofit/list/page', { params: this.gridSearch })

      if (response && response.data) {
        this.grid.paging.total = response.data.total
        this.grid.rowData = response.data.content

        if (response.data.content.length <= 0) {
          this.$alert.alert('조회된 일별별손익 현황이 없습니다.', '알림')
        } else {
          let totPlMn = 0;
          let plMn = 0;
          let totCms = 0;

          let sum = [{
            trdDt: '합계',
            totPlMn: totPlMn, //총손익
            plMn: plMn, //매매손익합
            totCms: totCms //totCms
          }];
          if (response.data.sum != undefined && response.data.sum.length > 0) {
            sum = response.data.sum;
          }

          this.gridApi.setPinnedBottomRowData(sum);

        }
      }
    }
  },
};



</script>

<style lang="scss" scoped>
@import '@/assets/scss/agGridStyles.scss';
</style>