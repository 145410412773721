<template>
  <div>
    <modal name="modalMarginSelect" classes="main-modal" :adaptive="true" :width="width" :height="height"
      @before-open="beforeOpen" @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">마진모드 선택</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="modal-margin-select">
          <div class="margin-select-row">
            <button class="btn-line" :class="{ active: marginMode === '1' }" @click="chgMarginMode('1')">교차</button>
            <button class="btn-line" :class="{ active: marginMode === '2' }" @click="chgMarginMode('2')">격리</button>
          </div>
          <h1>• 마진 모드 전환은 선택한 계약에만 적용됩니다.</h1>
          <p>
            교차 마진 모드: 동일한 마진 자산의 모든 교차 포지션은 동일한 자산의 교차 마진 잔고를 공유합니다. 청산 발생 시, 자산의 잔여 오픈 포지션과
            함께 자산 전체 마진 잔고가 몰수될 수 있습니다.
          </p>
          <br />
          <p>
            격리 마진 모드: 해당 포지션에 지정된 마진이 특정 금액으로 제한됩니다. 만약 마진이 유지 마진 레벨 밑으로 떨어지면 포지션이 청산됩니다.
            그러나 이 모드에서는 원하는대로 마진을 추가하거나 제거할 수 있습니다.
          </p>
          <div class="alert-notice" v-if="!marginModeFlg">
            <img src="@/assets/icons/alert-blue.svg" alt="" />
            열린 주문/포지션을 보유하고 있는 경우 마진 모드를 변경할 수 없습니다.
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <!-- <button class="btn-primary darkgray" @click="closeModal()">취소</button> -->
        <button class="btn-primary blue" :class="{ 'btn-disabled': marginModeFlg == false }"
          :disabled="marginModeFlg == false ? true : false" @click="selectMarginMode()">확인</button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'ModalMarginSelect',

  data() {
    return {
      width: '',
      height: '',
      marginMode: "1",
      marginModeOrg: "1",
      coinSymbol: null,
      marginModeFlg: true,
      coinAccount: {},

    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 500;
      this.height = 380;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() { },

  methods: {
    opened() {

    },
    beforeOpen(e) {
      console.log("beforeOpen e.prams", e.params);
      //{marginMode: this.marginMode, coinAccount: this.coinAccount, coinSymbol:this.coinSymbol }

      // 초기화
      this.marginMode = "1";
      this.coinSymbol = null;
      this.coinAccount = null;

      this.marginMode = e.params.marginMode;
      this.marginModeOrg = e.params.marginMode;
      this.coinSymbol = e.params.coinSymbol;

      this.coinAccount = Object.assign({}, e.params.coinAccount);

      this.positionChk();

    },
    positionChk() {
      //포지션 체크
      const positionList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
      if (positionList != undefined && positionList.length > 0) {
        let balQty = 0;
        let ordQty = 0;
        let mitQty = 0;
        positionList.forEach(data => {
          if (data.symCd == this.coinSymbol.name && data.ordType == "POS") {
            balQty += data.balQty;
          } else if (data.symCd == this.coinSymbol.name && data.ordType == "ORD") {
            ordQty += data.balQty;
          } else if (data.symCd == this.coinSymbol.name && data.ordType == "ST") {
            mitQty += data.balQty;
          }
        });

        if (balQty > 0 || ordQty > 0 || mitQty > 0) {
          this.marginModeFlg = false;
        } else {
          this.marginModeFlg = true;
        }
      }
    },
    chgMarginMode(type) {
      //변경
      this.positionChk();
      if (this.marginModeFlg == true) {
        this.marginMode = type;
      }
    },
    async selectMarginMode() {

      const data = { accNo: this.coinAccount.accNo, symCd: this.coinSymbol.name, ordType: "coin" };

      await this.$lhttp.get('/api/order/rtPosOrdChk', { params: data }).then((response) => {
        console.log("response", response)
        if (response.code == 200) {
          if (response.data == null) {
            this.$alert.alert('코인선물 잔고/미체결 조회중 에러가 발생했습니다.', '안내');
            return;
          } else if (response.data.coinOrdQty != undefined && response.data.coinOrdQty > 0) {
            this.$alert.alert('코인선물 잔고/미체결 내역이 있으면 마진 모드 변경 불가합니다.', '안내');
            this.marginMode = response.data.marginMode;
            return;
          }

          //포지션 모드 저장
          const saveData = { accNo: this.coinAccount.accNo, symNo: this.coinSymbol.symbolno, marginMode: this.marginMode };
          console.log("saveData >>> ", saveData)
          this.$lhttp.post('/api/order/usrPos/save', saveData).then((res) => {
            this.$emit("event:MarginMode");
            this.closeModal();
          });

        }
      });


    },
    closeModal() {
      this.$modal.hide('modalMarginSelect');
    },
  },
};
</script>
<style lang="scss" scoped></style>
