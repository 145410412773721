<template>
  <div>
    <button class="btn-link" @click="modifyMrgAmt(params.data)">{{ getMrgAmt(params.data) }}</button>
  </div>
</template>

<script>
import Utils from '@/modules/utils';
export default {
  name: 'AgMarginEdit',
  computed: {
    getCoinSymbolList: function(){
      let symbolList = window.$store.getters['Symbol/getCoinSymbolList']
      return symbolList;
    },
  },
  watch: {
    getCoinSymbolList(coinSymbolList){
      this.coinSymbolList = coinSymbolList;
    },
  },
  data() {
    return {
      coinSymbolList: [],
    }
  },
  created(){
    
  },
  mounted(){
    if (this.coinSymbolList == undefined || this.coinSymbolList.length == 0){
        this.coinSymbolList = window.$store.getters['Symbol/getCoinSymbolList'];
    }
  },
  methods: {
    modifyMrgAmt(data){
      if (data.marginMode == "2"){ //격리모드일때 수정가능
        console.log("aaa?", data)
        let message = "코인 마진 조정 클릭"
        this.sendCoinOmsLogMessage(message);
        
        this.showModal('modalMarginEdit', data);
      }
      //
    },
    showModal(name, data) {
      this.$modal.show(name, data);
      console.log(name);
    },
    getMrgAmt(data){
      let mrgAmt = '';
      const value = data.mrgAmt;
      if (value != undefined && value != null){
        //cross 모드이면 실시간으로 마진비용 변동
        if (data.marginMode == "1"){
          mrgAmt = Number(value) + Number(data.valPl);
        }else{ //isolated 모드이면 저장된 비율로 표시
          mrgAmt = Number(value);
        }

        mrgAmt = Utils.toFixNum(mrgAmt, 2).toFixed(2) + " USDT";
      }
      return mrgAmt;
    },
    sendCoinOmsLogMessage(message) { //코인 사용자 로그 저장
      window.$store.dispatch('SocketCoinOms/setLogMsg', { message: message });
    },
  },
}
</script>
