<template>
  <div>
    <modal name="coinModalSettings" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">환경설정</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="tab-nav-container">
          <div class="tab" :class="{ active: selectedTab === 'ordSetting' }" @click="setSelectedTab('ordSetting')">기본설정</div>
          <div class="tab" :class="{ active: selectedTab === 'setPwd' }" @click="setSelectedTab('setPwd')">암호설정</div>
          <!-- <div class="tab" :class="{ active: selectedTab === 'tab3' }" @click="selectedTab = 'tab3'">오버나잇</div> -->
        </div>

        <div class="tab-content" v-show="selectedTab === 'ordSetting'">
          <div class="col2-grid settings">
            <div class="tab-inner-row">
              <h3 class="subtitle">주문알림/확인 설정</h3>
              <ul class="row-list">
                <li>
                  <div class="checkbox-default">
                    <input type="checkbox" id="messageAlert" v-model="orderSetting.messageAlert" />
                    <label for="orderAlarm">주문알림</label>
                  </div>
                </li>
                <li>
                  <div class="checkbox-default">
                    <input type="checkbox" id="ordMessageAlert" v-model="orderSetting.ordMessageAlert" />
                    <label for="orderConfirm">주문확인</label>
                  </div>
                </li>
                <li>
                  <div class="checkbox-default">
                    <input type="checkbox" id="clearMessageAlert" v-model="orderSetting.clearMessageAlert" />
                    <label for="orderClose">청산/취소 확인</label>
                  </div>
                </li>
                <li>
                  <div class="checkbox-default">
                    <input type="checkbox" id="soundAlert" v-model="orderSetting.soundAlert" />
                    <label for="orderVoice">음성</label>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tab-inner-row" v-if="mobileSet.type == 'PC'">
              <h3 class="subtitle">차트위치 설정</h3>
              <ul class="row-list">
                <li>
                  <div class="radio-default">
                    <input type="radio" id="chartLeft" name="chartPosition" value="1" v-model="orderSetting.chartPos" @click="setChartPos('1')" />
                    <label for="chartLeft">죄측</label>
                  </div>
                </li>
                <li>
                  <div class="radio-default">
                    <input type="radio" id="chartRight" name="chartPosition" value="2" v-model="orderSetting.chartPos" @click="setChartPos('2')" />
                    <label for="chartRight">우측</label>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tab-inner-row">
              <h3 class="subtitle">스타일 설정</h3>
              <ul class="row-list">
                <li>
                  <div class="style-settings-radio">
                    <input type="radio" id="greenRed" name="styleSettings" value="1" v-model="orderSetting.coinStyle" @click="setCoinStyle('1')" />
                    <label for="greenRed">
                      <!-- <div class="radio-mark"></div> -->
                      <div class="style-container">
                        <span class="buy1">매수</span>
                        <span class="red">매도</span>
                      </div>
                    </label>
                  </div>
                </li>
                <li>
                  <div class="style-settings-radio">
                    <input type="radio" id="redBlue" name="styleSettings" value="2" v-model="orderSetting.coinStyle" @click="setCoinStyle('2')" />
                    <label for="redBlue">
                      <div class="style-container">
                        <span class="red">매수</span>
                        <span class="buy">매도</span>                        
                      </div>
                    </label>
                  </div>
                </li>
              </ul>
            </div>

            <div class="tab-inner-row full shortcut" v-if="mobileSet.type == 'PC'">
              <h3 class="subtitle">키보드 단축키 설정</h3>
              <div class="shortcut-inner-grid">
                <ul class="shortcut-list">
                  <li>
                    <div class="label">중앙정렬</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetCenterKey" :options="keyOptionList" :default="defaultOption.centerKey" @ordSetCenterKey="ordSetCenterKey" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.centerKey.numberKey" @childEvent="setCenterNumberKey" />
                  </li>
                  <li>
                    <div class="label">시장가매도</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetMarketSellKey" :options="keyOptionList" :default="defaultOption.marketSellKey" @ordSetMarketSellKey="ordSetMarketSellKey" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.marketSellKey.numberKey" @childEvent="setMarketSellNumberKey" />
                  </li>
                  <li>
                    <div class="label">시장가매수</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetMarketBuyKey" :options="keyOptionList" :default="defaultOption.marketBuyKey" @ordSetMarketBuyKey="ordSetMarketBuyKey" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.marketBuyKey.numberKey" @childEvent="setMarketBuyNumberKey" />
                  </li>
                  <li>
                    <div class="label">매도취소</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetCancelSell" :options="keyOptionList" :default="defaultOption.cancelSell" @ordSetCancelSell="ordSetCancelSell" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.cancelSell.numberKey" @childEvent="setCancelSellNumberKey" />
                  </li>
                  <li>
                    <div class="label">매수취소</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetCancelBuy" :options="keyOptionList" :default="defaultOption.cancelBuy" @ordSetCancelBuy="ordSetCancelBuy" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.cancelBuy.numberKey" @childEvent="setCancelBuyNumberKey" />
                  </li>
                </ul>
                <ul class="shortcut-list">
                  <li>
                    <div class="label">익절절취소</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetStopSellKey" :options="keyOptionList" :default="defaultOption.stopSellKey" @ordSetStopSellKey="ordSetStopSellKey" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.stopSellKey.numberKey" @childEvent="setStopSellNumberKey" />
                  </li>
                  <li>
                    <div class="label">손절취소</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetStopBuyKey" :options="keyOptionList" :default="defaultOption.stopBuyKey" @ordSetStopBuyKey="ordSetStopBuyKey" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.stopBuyKey.numberKey" @childEvent="setStopBuyNumberKey" />
                  </li>
                  <li>
                    <div class="label">전종목청산</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetAllClear" :options="keyOptionList" :default="defaultOption.allClear" @ordSetAllClear="ordSetAllClear" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.allClear.numberKey" @childEvent="setAllClearNumberKey" />
                  </li>
                  <li>
                    <div class="label">현종목청산</div>
                    <div class="base-dropdown">
                      <BaseDropdown id="ordSetSymbolClear" :options="keyOptionList" :default="defaultOption.symbolClear" @ordSetSymbolClear="ordSetSymbolClear" />
                    </div>
                    +
                    <CounterComp :maxCnt="9" :maxLength=1 :value="orderSetting.symbolClear.numberKey" @childEvent="setSymbolClearNumberKey" />
                  </li>
                </ul>
              </div>
            </div>

            <!-- <div class="tab-inner modal-settings"></div> -->
            <!-- <div class="division-line"></div> -->
          </div>
        </div>

        <div class="tab-content" v-if="selectedTab === 'setPwd'">
          <validation-observer ref="validator">
            <h3 class="subtitle">비밀번호 입력 (4~16자리)</h3>
              <validation-provider :rules="{ required:true, limit: 16, }" name="현재 비밀번호" v-slot="{errors}">
                <div class="input-row">
                  <label for="passwd" class="input-label">현재 비밀번호</label>
                  <input type="password" id="passwd" v-model="form.passwd" maxlength="16"  />
                </div>
                <div class="input-row-error">
                  <label></label>
                  <div class="error" :class="[errors[0] ? 'errPadding': '']">{{errors[0]}}</div>
                </div>
              </validation-provider>
              <validation-provider :rules="{ required:true, password:true, limit: 16, }" name="변경 비밀번호" v-slot="{errors}">
                <div class="input-row">
                  <label for="changePasswd" class="input-label">변경 비밀번호</label>
                  <input type="password" id="changePasswd" v-model="form.changePasswd" maxlength="16" />
                </div>
                <div class="input-row-error">
                  <label></label>
                  <div class="error" :class="[errors[0] ? 'errPadding': '']">{{errors[0]}}</div>
                </div>
              </validation-provider>
              <validation-provider :rules="{ required:true, password:true, confirmed:'변경 비밀번호', limit: 16, }" name="변경 확인" v-slot="{errors}">
                <div class="input-row">
                  <label for="confPasswd" class="input-label">변경 확인</label>
                  <input type="password" id="confPasswd" v-model="form.confPasswd" maxlength="16" />
                </div>
                <div class="input-row-error">
                  <label></label>
                  <div class="error" :class="[errors[0] ? 'errPadding': '']">{{errors[0]}}</div>
                </div>
              </validation-provider>
          </validation-observer>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn-primary darkgray" @click="cancel()">취소</button>
        <button class="btn-primary blue" @click="save">저장</button>
      </div>
    </modal>
  </div>
</template>

<script>
import CounterComp from '@/components/CounterComp.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';

export default {
  components: { 
    CounterComp,
    BaseDropdown,
  },
  name: 'CoinModalSettings',
  props:{
    mobileSet: {
      type: Object,
      default: () => {
        return {type:"PC", ord:true, mit:true, btnBottom:true};
      }
    },
  },
  computed:{
    getDailyPlMn: function(){
      return window.$store.getters['SocketOms/getDailyPlMn'];
    },
    getLoginUserInfo: function(){
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
  },
  watch:{
    getDailyPlMn:{
      handler(res){
        this.dailyPlMn.fxPlMn = res.fxPlMn;
        this.dailyPlMn.balMn = res.balMn;
        this.dailyPlMn.usdExR = res.usdExR;
        this.dailyPlMn.usdExHkd = res.usdExHkd;
      },        
      deep:true
    },
  },
  data() {
    return {
      width: '',
      height: '',
      selectedTab: 'ordSetting',
      selectedTheme: 'blue',
      keyOptionList: [],
      orderSetting:{
        chartPos: '1',
        coinStyle: '2',
        slTikVal1: {tikVal:1},
        slTikVal2: {tikVal:1},
        clickVal1: {val:1},
        clickVal2: {val:1},
        clickVal3: {val:1},
        clickVal4: {val:1},
        clickVal5: {val:1},
        centerKey: {primaryKey: '', numberKey: 1, keyCode:null},
        marketSellKey: {primaryKey: '', numberKey: 1, keyCode:null},
        marketBuyKey: {primaryKey: '', numberKey: 1, keyCode:null},
        cancelSell: {primaryKey: '', numberKey: 1, keyCode:null},
        cancelBuy: {primaryKey: '', numberKey: 1, keyCode:null},
        stopSellKey: {primaryKey: '', numberKey: 1, keyCode:null},
        stopBuyKey: {primaryKey: '', numberKey: 1, keyCode:null},
        allClear: {primaryKey: '', numberKey: 1, keyCode:null},
        symbolClear: {primaryKey: '', numberKey: 1, keyCode:null},
        messageAlert: false,
        ordMessageAlert: false,
        clearMessageAlert: false,
        soundAlert: false,
      },
      defaultOption: {
        centerKey: '',
        marketSellKey: '',
        marketBuyKey: '',
        cancelSell: '',
        cancelBuy: '',
        stopSellKey: '',
        stopBuyKey: '',
        allClear: '',
        symbolClear: '',
      },
      loadOption: null,
      keyCodeList: [
        {keyCode:49, Number:1},
        {keyCode:50, Number:2},
        {keyCode:51, Number:3},
        {keyCode:52, Number:4},
        {keyCode:53, Number:5},
        {keyCode:54, Number:6},
        {keyCode:55, Number:7},
        {keyCode:56, Number:8},
        {keyCode:57, Number:9}
      ],
      keyCheckList: [],
      form:{
        passwd:'',
        changePasswd:'',
        confPasswd:'',
      },
      gridSearch:{
        accId:0,
        accNo:"",
        type:"search",
      },
      setGridSearch:{
        accId:0,
      },
      account:{},
    }
  },
  created() {
    if (window.innerWidth > 1200) {
      this.width = 1000;
      this.height = 730;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },
  mounted() {

  },
  methods: {
    setChartPos(type){
      let message = "";
      if (type == "1"){
        message = "코인 차트위치 좌측 체크";
      }else if (type == "2"){
        message = "코인 차트위치 우측 체크";
      }

      this.sendCoinOmsLogMessage(message);

    },
    setCoinStyle(type){
      let message = "";
      if (type == "1"){
        message = "코인 스타일 좌측 체크";
      }else if (type == "2"){
        message = "코인 스타일 우측 체크";
      }

      this.sendCoinOmsLogMessage(message);
    },
    cancel(){
      let message = "";
      if (this.selectedTab == "ordSetting"){
        message = "환경설정 취소 버튼 클릭";
      }else if (this.selectedTab == "setPwd"){
        message = "암호설정 취소 버튼 클릭";
      }

      this.sendCoinOmsLogMessage(message);
      
      this.closeModal();
    },
    closeModal() {      
      this.$modal.hide('coinModalSettings');
    },
    thousand(value) {
      if (value == undefined){
        return 0;
      }else{
        return value.toLocaleString('en-US', { minimumFractionDigits: 0 });
      } 
    },
    changeTheme() {
      //bus.$emit('selectedTheme', this.selectedTheme);
    },
    opened(){
      //주문설정
      this.orderSettingInit();
    },
    beforeOpen(e){
      //console.log("beforeOpen e.prams", e.params)
      
      //탭 초기화
      this.selectedTab = "ordSetting";

      //주문설정 저장 초기화
      this.loadOption = null;
      
      //주문 설정초기화
      this.orderSettingReset();

      //암호설정 초기화
      this.form.passwd = "";
      this.form.changePasswd = "";
      this.form.confPasswd = "";

      if (e.params != undefined && e.params != null){
        this.account = e.params.account;
      }

      //주문설정 저장
      this.loadOption = window.$store.getters['Layout/getOrderSetting'];
    },
    orderSettingInit(){
      //단축키 목록
      this.keyOptionList = [{name:'NONE'},{name:'ALT'}];

      //console.log("this.loadOption", this.loadOption)

      //설정이 없으면 초기설정값
      if (this.loadOption == null){
        this.orderSetting = {
          slTikVal1: {tikVal:5},
          slTikVal2: {tikVal:5},
          clickVal1: {val:1},
          clickVal2: {val:2},
          clickVal3: {val:3},
          clickVal4: {val:4},
          clickVal5: {val:5},
          centerKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          marketSellKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          marketBuyKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          cancelSell: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          cancelBuy: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          stopSellKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          stopBuyKey: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          allClear: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          symbolClear: {primaryKey: 'NONE', numberKey: 1, keyCode:null},
          messageAlert: true,
          ordMessageAlert: true,
          clearMessageAlert: true,
          soundAlert: true,
          chartPos: '1',
          coinStyle: '2',
        },
        
        this.defaultOption = {
          centerKey: 'NONE',
          marketSellKey: 'NONE',
          marketBuyKey: 'NONE',
          cancelSell: 'NONE',
          cancelBuy: 'NONE',
          stopSellKey: 'NONE',
          stopBuyKey: 'NONE',
          allClear: 'NONE',
          symbolClear: 'NONE',
        }

        //console.log("디폴트", this.defaultOption.centerKey)

        // this.ordMessageAlert = true;
        // this.messageAlert = true;
        // this.clearMessageAlert = true;
        // this.soundAlert = true;
      }else{
        this.orderSetting = Object.assign({}, this.loadOption);

        // this.ordMessageAlert = this.orderSetting.ordMessageAlert;
        // this.messageAlert = this.orderSetting.messageAlert;
        // this.clearMessageAlert = this.orderSetting.clearMessageAlert;
        // this.soundAlert = this.orderSetting.soundAlert;
        
        this.defaultOption = {
          centerKey: this.orderSetting.centerKey.primaryKey,
          marketSellKey: this.orderSetting.marketSellKey.primaryKey,
          marketBuyKey: this.orderSetting.marketBuyKey.primaryKey,
          cancelSell: this.orderSetting.cancelSell.primaryKey,
          cancelBuy: this.orderSetting.cancelBuy.primaryKey,
          stopSellKey: this.orderSetting.stopSellKey.primaryKey,
          stopBuyKey: this.orderSetting.stopBuyKey.primaryKey,
          allClear: this.orderSetting.allClear.primaryKey,
          symbolClear: this.orderSetting.symbolClear.primaryKey,
        }

        //선물 저장데이터일경우, 코인 설정(차트위치, 스타일) 없으면 초기설정
        if (this.orderSetting.chartPos == undefined){
          this.orderSetting.chartPos = '1';
        }

        if (this.orderSetting.coinStyle == undefined){
          this.orderSetting.coinStyle = '2';
        }
      }
    },
    orderSettingReset(){

      //팝업 설정 초기화
      this.orderSetting = {
        btnClick: 'oneClick',
        // oneClick: false,
        // dbClick: false,
        slTikVal1: {tikVal:1},
        slTikVal2: {tikVal:1},
        clickVal1: {val:1},
        clickVal2: {val:1},
        clickVal3: {val:1},
        clickVal4: {val:1},
        clickVal5: {val:1},
        centerKey: {primaryKey: '', numberKey: 1, keyCode:null},
        marketSellKey: {primaryKey: '', numberKey: 1, keyCode:null},
        marketBuyKey: {primaryKey: '', numberKey: 1, keyCode:null},
        cancelSell: {primaryKey: '', numberKey: 1, keyCode:null},
        cancelBuy: {primaryKey: '', numberKey: 1, keyCode:null},
        stopSellKey: {primaryKey: '', numberKey: 1, keyCode:null},
        stopBuyKey: {primaryKey: '', numberKey: 1, keyCode:null},
        allClear: {primaryKey: '', numberKey: 1, keyCode:null},
        symbolClear: {primaryKey: '', numberKey: 1, keyCode:null},
        messageAlert: false,
        ordMessageAlert: false,
        clearMessageAlert: false,
        soundAlert: false,
        chartPos: '1',
        coinStyle: '2',
      },

      //체크 설정 디폴트 true, 최근설정 true 화면 체크를 못하여 모두 false
      // this.ordMessageAlert = false;
      // this.messageAlert = false;
      // this.clearMessageAlert = false;
      // this.soundAlert = false;

      this.defaultOption = {
        centerKey: '',
        marketSellKey: '',
        marketBuyKey: '',
        cancelSell: '',
        cancelBuy: '',
        stopSellKey: '',
        stopBuyKey: '',
        allClear: '',
        symbolClear: '',
      }

      this.keyOptionList = [];
    },
    //손절 틱설정값 저장
    setSlTikVal1(tickVal){
      this.orderSetting.slTikVal1.tikVal = Number(tickVal);
    },
    //익절 틱설정값 저장
    setSlTikVal2(tickVal){
      this.orderSetting.slTikVal2.tikVal = Number(tickVal);
    },
    setClickVal1(val){
      this.orderSetting.clickVal1.val = Number(val);
    },
    setClickVal2(val){
      this.orderSetting.clickVal2.val = Number(val);
    },
    setClickVal3(val){
      this.orderSetting.clickVal3.val = Number(val);
    },
    setClickVal4(val){
      this.orderSetting.clickVal4.val = Number(val);
    },
    setClickVal5(val){
      this.orderSetting.clickVal5.val = Number(val);
    },
    setOrdMsg(){
      this.orderSetting.ordMessageAlert = !this.ordMessageAlert;
      this.ordMessageAlert = !this.ordMessageAlert;
    },
    setMsg(){
      this.orderSetting.messageAlert = !this.messageAlert;
      this.messageAlert = !this.messageAlert;
    },
    setClearMsg(){
      this.orderSetting.clearMessageAlert = !this.clearMessageAlert;
      this.clearMessageAlert = !this.clearMessageAlert;
    },
    setSoundMsg(){
      this.orderSetting.soundAlert = !this.soundAlert;
      this.soundAlert = !this.soundAlert;
    },
    ordSetCenterKey(val){
      this.orderSetting.centerKey.primaryKey = val.name;
    },
    ordSetMarketSellKey(val){
      this.orderSetting.marketSellKey.primaryKey = val.name;
    },
    ordSetMarketBuyKey(val){
      this.orderSetting.marketBuyKey.primaryKey = val.name;
    },
    ordSetCancelSell(val){
      this.orderSetting.cancelSell.primaryKey = val.name;
    },
    ordSetCancelBuy(val){
      this.orderSetting.cancelBuy.primaryKey = val.name;
    },
    ordSetStopSellKey(val){
      this.orderSetting.stopSellKey.primaryKey = val.name;
    },
    ordSetStopBuyKey(val){
      this.orderSetting.stopBuyKey.primaryKey = val.name;
    },
    ordSetAllClear(val){
      this.orderSetting.allClear.primaryKey = val.name;
    },
    ordSetSymbolClear(val){
      this.orderSetting.symbolClear.primaryKey = val.name;
    },
    setCenterNumberKey(val){
      this.orderSetting.centerKey.numberKey = Number(val);
    },
    setMarketSellNumberKey(val){
      this.orderSetting.marketSellKey.numberKey = Number(val);
    },
    setMarketBuyNumberKey(val){
      this.orderSetting.marketBuyKey.numberKey = Number(val);
    },
    setCancelSellNumberKey(val){
      this.orderSetting.cancelSell.numberKey = Number(val);
    },
    setCancelBuyNumberKey(val){
      this.orderSetting.cancelBuy.numberKey = Number(val);
    },
    setStopSellNumberKey(val){
      this.orderSetting.stopSellKey.numberKey = Number(val);
    },
    setStopBuyNumberKey(val){
      this.orderSetting.stopBuyKey.numberKey = Number(val);
    },
    setAllClearNumberKey(val){
      this.orderSetting.allClear.numberKey = Number(val);
    },
    setSymbolClearNumberKey(val){
      this.orderSetting.symbolClear.numberKey = Number(val);
    },
    setCustom() {
      //중복체크용
      this.keyCheckList = [];

      //숫자키 이벤트 keyCode 입력
      this.orderSetting.centerKey.keyCode = this.getKeyCode(this.orderSetting.centerKey.numberKey, this.orderSetting.centerKey.primaryKey);
      this.orderSetting.marketSellKey.keyCode = this.getKeyCode(this.orderSetting.marketSellKey.numberKey, this.orderSetting.marketSellKey.primaryKey);
      this.orderSetting.marketBuyKey.keyCode = this.getKeyCode(this.orderSetting.marketBuyKey.numberKey, this.orderSetting.marketBuyKey.primaryKey);
      this.orderSetting.cancelSell.keyCode = this.getKeyCode(this.orderSetting.cancelSell.numberKey, this.orderSetting.cancelSell.primaryKey);
      this.orderSetting.cancelBuy.keyCode = this.getKeyCode(this.orderSetting.cancelBuy.numberKey, this.orderSetting.cancelBuy.primaryKey);
      this.orderSetting.stopSellKey.keyCode = this.getKeyCode(this.orderSetting.stopSellKey.numberKey, this.orderSetting.stopSellKey.primaryKey);
      this.orderSetting.stopBuyKey.keyCode = this.getKeyCode(this.orderSetting.stopBuyKey.numberKey, this.orderSetting.stopBuyKey.primaryKey);
      this.orderSetting.allClear.keyCode = this.getKeyCode(this.orderSetting.allClear.numberKey, this.orderSetting.allClear.primaryKey);
      this.orderSetting.symbolClear.keyCode = this.getKeyCode(this.orderSetting.symbolClear.numberKey, this.orderSetting.symbolClear.primaryKey);

      for(let i=0; i<this.keyCheckList.length; i++){
        let cnt = 0;
        for (let j=0; j<this.keyCheckList.length; j++){
          if (this.keyCheckList[i] == this.keyCheckList[j]){
            cnt++;
          }
        }
        if (cnt > 1){
          this.$alert.alert("단축키는 중복키를 설정할 수 없습니다.", '알림');
          return;
        }
      }

      window.$store.dispatch('Layout/saveCustom', { type: 'OS', custom: this.orderSetting});

      //console.log("this.orderSetting", this.orderSetting);

      this.$emit('order-setting-complete');
      this.closeModal();
    },
    getKeyCode(numKey, primaryKey){
      //숫자키의 keyCode를 반환
      let keyCode = null;
      this.keyCodeList.forEach(item => {
        if (Number(numKey) === Number(item.Number)){
          keyCode = item.keyCode;
          return;
        }
      });

      //중복체크용 리스트 저장
      if (keyCode != null && primaryKey == "ALT"){
        this.keyCheckList.push(keyCode);
      }

      return keyCode;
    },
    setPassWd(){
      const { validator } = this.$refs

      validator.validate().then((result) => {
        if (result) {
          this.$alert.confirm('비밀번호를 변경 하시겠습니까?')
            .then((response) => {
              if (response) {     
                this.$lhttp
                  .post('/auth/profile/passwd/reset',
                    this.form)
                  .then((response) => {
                    if (response.data.code == 200){
                      this.$alert.alert('비밀번호가 변경 되었습니다.', '알림').then(() => {
                        this.closeModal();
                      })
                    }else{
                      this.$alert.alert(response.data.message, '알림');
                    }
                  })    
              }
            })
        }
      })
    },
    setSelectedTab(selTab){
      if (this.selectedTab == selTab) return;
      this.orderSettingReset();
      this.selectedTab = selTab;

      if (this.selectedTab == "ordSetting"){
        this.orderSettingInit();
      }else if (this.selectedTab == "setPwd"){
        this.sendCoinOmsLogMessage("암호설정 클릭");
        this.form.passwd = "";
        this.form.changePasswd = "";
        this.form.confPasswd = "";
        //
      }
    },
    save(){
      if (this.selectedTab == "ordSetting"){
        this.sendCoinOmsLogMessage("환경설정 저장 버튼 클릭");
        this.setCustom();
      }else if (this.selectedTab == "setPwd"){
        this.sendCoinOmsLogMessage("환경설정 취소 버튼 클릭");
        this.setPassWd();
      }
    },
    sendCoinOmsLogMessage(message){
      if (window.location.pathname == "/coinMobile"){
        message = "모바일 " + message;
      }
      window.$store.dispatch('SocketCoinOms/setLogMsg', { message: message });
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
.tab-content {
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: $mobile) {
    padding-left: 0;
    padding-right: 0;
  }
  .tab-inner-row {
    &.full {
      grid-column: -1/1;
    }
    &.shortcut {
      @media (max-width: $mobile) {
        display: none;
      }
    }
    .row-list {
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
</style>
