<template>
  <div class="mobile-container">
    <div class="mobile-sidemenu-container" :class="{ active: showSide }">
      <div class="bg" @click="showSide = false"></div>
      <div class="mobile-sidemenu" :class="{ active: showSide }">
        <ul>
          <li @click="showSlideMenu('showAccountSheet')"
            v-if="getLoginUserInfo != undefined && getLoginUserInfo.authId != undefined">
            <div class="inner">
              <img src="@/assets/icons/account-black.svg" alt="">
              계좌
            </div>
            <img src="@/assets/icons/chevron-right-gray.svg" alt="">
          </li>
          <li @click="
            showSide = false;
          showDepositSheet = true;
          ">
            <div class="inner">
              <img src="@/assets/icons/monitor-black.svg" alt="" />
              입출금
            </div>
            <img src="@/assets/icons/chevron-right-gray.svg" alt="" />
          </li>
          <li @click="
            showSide = false;
          showModal('coinModalSettings');
          ">
            <div class="inner">
              <img src="@/assets/icons/settings-black.svg" alt="" />
              환경설정
            </div>
          </li>
          <li @click="
            showSide = false;
          showModal('modalNotice');
          ">
            <div class="inner">
              <img src="@/assets/icons/notice-black.svg" alt="" />
              공지사항
            </div>
          </li>
          <div class="division-line"></div>
          <li @click="
            showSide = false;
          showModal('modalLogout');
          ">
            <div class="inner">
              <img src="@/assets/icons/logout-black.svg" alt="" />
              로그아웃
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="mobile-header coin">
      <div class="left">
        <button @click="showSide = true"><img src="@/assets/icons/menu-black.svg" alt="" /></button>
        <div class="instrument" @click="showModal('modalInstruments')">
          <img src="@/assets/icons/search-black.svg" alt="" />
          <h3 v-show="coinSymbol.description != undefined">{{ coinSymbol.description }}({{ coinSymbol.name }})</h3>
        </div>
      </div>
      <div class="right">
        <div v-if="futUseYn == 'Y'">
          <button class="btn-primary blue" @click="setFutPage">해외선물</button>
        </div>
      </div>
    </div>

    <div class="mobile-inner-container coin">
      <div class="mobile-tab-container coin">
        <button class="mobile-tab" :class="{ active: activeTab === 'order' }" @click="activeTab = 'order'">주문</button>
        <button class="mobile-tab" :class="{ active: activeTab === 'chart' }" @click="activeTab = 'chart'">차트</button>
        <button class="mobile-tab" :class="{ active: activeTab === 'assets' }" @click="activeTab = 'assets'">자산</button>
      </div>
      <div class="mobile-order-page" v-show="activeTab === 'order'">
        <div class="margin-row">
          <button class="btn-line" @click="chageMarginMode">{{ marginMode == "1" ? "교차" : "격리" }}</button>
          <button class="btn-line" @click="changeLeverage">{{ coinLeverage }}x</button>
          <button class="btn-line" @click="changePositionMode">{{ cpos == "1" ? "단방향" : "헤지" }}</button>
        </div>

        <div class="price-row buy">
          <!-- 클래스 buy, sell -->
          <div class="price-label">
            <div class="current-price"
              :class="{ 'color-blue': cot.buyerMaker === true && cot.curPr > 0, 'color-red': cot.buyerMaker === false && cot.curPr > 0, '': cot.curPr == 0 }">
              {{ cot.curPr.toFixed(this.tikDotSz) }}</div>
            <div class="last-price">{{ com.markPr.toFixed(this.tikDotSz) }}</div>
          </div>
          <div class="funding-label">
            <div class="simple-label">
              펀딩/남은시간
              <span class="orange">0.0%</span>
              / 00:00:00
            </div>
          </div>
        </div>

        <div class="mobile-orderbook">
          <div class="orderbook-table">
            <div class="orderbook-label">
              <div class="price">가격(USDT)</div>
              <div class="volume">수량(BTC)</div>
            </div>
            <div class="orderbook-row sell" v-for="(item, index) in asksObject.slice().reverse()" :key="'ask' + index"
              @click="selectRowItem(item)">
              <div class="price"> {{ item.price }} </div>
              <div class="volume"> {{ item.size }} </div>
              <div class="bg" :style="{ width: calculateWidth(item.sum) }"></div>
            </div>
          </div>

          <div class="orderbook-table">
            <div class="orderbook-label">
              <div class="price">가격(USDT)</div>
              <div class="volume">수량(BTC)</div>
            </div>
            <div class="orderbook-row buy" v-for="(item, index) in bidsObject" :key="'bid' + index"
              @click="selectRowItem(item)">
              <div class="price"> {{ item.price }} </div>
              <div class="volume"> {{ item.size }} </div>
              <div class="total"> {{ item.sum }} </div>
              <div class="bg" :style="{ width: calculateWidth(item.sum) }"></div>
            </div>
          </div>
        </div>

        <div class="entry-close">
          <button class="entry" :class="{ active: openCloseMode === '1' }" @click="changeOpenClose('1')">진입</button>
          <button class="close" :class="{ active: openCloseMode === '2' }" @click="changeOpenClose('2')">청산</button>
        </div>

        <div class="mobile-row coin">
          <BaseDropdown id="coinOrdType" :options="ordTpCdList" :default="ordTpCdList[0].name" :value="selOrdTpCd"
            @coinOrdType="changeOrdTpCd" />
          <div class="mobile-number-input">
            <button @click="minusBtn('stdOrdPrc')"><img src="@/assets/icons/minus-black.svg" alt="" /></button>
            <input type="number" placeholder="가격" v-model="stdOrdPrc" @input="setStdOrdPrc"
              :disabled="stdOrdTpCd == '2' ? true : false" />
            <button @click="plusBtn('stdOrdPrc')"><img src="@/assets/icons/plus-black.svg" alt="" /></button>
          </div>
        </div>

        <div class="mobile-row coin">
          <div class="mobile-number-input">
            <button @click="minusBtn('stdOrdQty')"><img src="@/assets/icons/minus-black.svg" alt="" /></button>
            <input type="text" placeholder="수량" v-model="stdOrdQty" @input="setCostUsdt" @focus="onFocus" />
            <button @click="plusBtn('stdOrdQty')"><img src="@/assets/icons/plus-black.svg" alt="" /></button>
          </div>
          <BaseDropdown id="coinOrdType" :options="coinOrdTypeList" :default="coinOrdTypeList[0].name"
            :value="selOrdType" @coinOrdType="changeStdOrdType" />
        </div>

        <div class="mobile-control-ui">
          <Slider :value="1" />
          <div class="mobile-sl">
            <div class="checkbox-default">
              <input type="checkbox" id="takeProfit" name="stopProfit" v-model="showTpSlFlag" @input="setTpSlFlag" />
              <label for="takeProfit">익절/손절</label>
            </div>
            <div class="mobile-row coin" v-show="showTpSlFlag">
              <CodeDropDown grpCd="PRCTP" v-model="stdOrdTpType" popup @input="changeTpType" />
              <div class="mobile-number-input">
                <button @click="minusBtn('stdOrdTpPrc')"><img src="@/assets/icons/minus-black.svg" alt="" /></button>
                <input type="number" placeholder="가격" v-model="stdOrdTpPrc" />
                <button @click="plusBtn('stdOrdTpPrc')"><img src="@/assets/icons/plus-black.svg" alt="" /></button>
              </div>
            </div>
            <div class="mobile-row coin" v-show="showTpSlFlag">
              <CodeDropDown grpCd="PRCTP" v-model="stdOrdSlType" popup @input="changeSlType" />
              <div class="mobile-number-input">
                <button @click="minusBtn('stdOrdSlPrc')"><img src="@/assets/icons/minus-black.svg" alt="" /></button>
                <input type="number" placeholder="가격" v-model="stdOrdSlPrc" />
                <button @click="plusBtn('stdOrdSlPrc')"><img src="@/assets/icons/plus-black.svg" alt="" /></button>
              </div>
            </div>
          </div>

          <div class="row-info">
            <div class="label">Avbl</div>
            <div class="value">{{ avbl }} USDT</div>
          </div>
          <!-- <div class="row-info">
            <div class="label">Order Value</div>
            <div class="value">0.00000000 BTC</div>
          </div> -->

          <div class="buysell-btn-container">
            <div class="coin-btn-container">
              <button class="order-btn buy" @click="stdCoinSellBuy('buy')">매수/Long</button>
              <div class="simple-label">
                Cost:
                <b>{{ buyCostUsdt }} USDT</b>
              </div>
            </div>
            <div class="coin-btn-container">
              <button class="order-btn sell" @click="stdCoinSellBuy('sell')">매도/Short</button>
              <div class="simple-label">
                Cost:
                <b>{{ sellCostUsdt }} USDT</b>
              </div>
            </div>
          </div>
        </div>

        <div class="mobile-transaction-table">
          <div class="order-type-tab-container">
            <button class="order-type-tab" :class="{ active: coinPositionType === 'POS' }"
              @click="coinPositionType = 'POS'">포지션 ({{ rtPosCnt }})</button>
            <button class="order-type-tab" :class="{ active: coinPositionType === 'ORD' }"
              @click="coinPositionType = 'ORD'">미체결 ({{ rtPosOrdCnt }})</button>
          </div>
          <div class="mobile-transaction-ui">
            <button class="btn-line" v-if="coinPositionType === 'POS'" @click="clearOrderAll('all')">전체청산</button>
            <button class="btn-line" v-if="coinPositionType === 'ORD'" @click="cancelAll('all')">전체취소</button>
          </div>
          <ul class="mobile-transaction-list" v-if="coinPositionType === 'POS'">
            <li v-for="(item, index) of coinPosList" :key="index">
              <div class="list-inner">
                <div class="transaction-summary">
                  <div>
                    <div class="label">{{ item.symCd }}</div>
                    <div class="value margin">
                      {{ item.marginMode == '1' ? "CROSS" : "ISOLATED" }} / {{ item.lv }}x
                    </div>
                  </div>
                  <div class="pnl">
                    <div class="label">PNL(ROE)</div>
                    <div class="value" :class="{ 'plus': item.valPl > 0, 'minus': item.valPl < 0 }">{{ item.valPl
                      }}({{ item.valRoi }}%)</div>
                  </div>
                </div>
                <div class="transaction-detail">
                  <div>
                    <div class="label">구분</div>
                    <div class="value" :class="{ 'plus': item.ordSdCd == '2', 'minus': item.ordSdCd == '1' }">{{
                      item.ordSdNm}}</div>
                  </div>
                  <div>
                    <div class="label">수량</div>
                    <div class="value" :class="{ 'plus': item.ordSdCd == '2', 'minus': item.ordSdCd == '1' }">{{
                      item.ordSdCd == '1' ? NumberComma(item.balQty * -1) : NumberComma(item.balQty) }}</div>
                  </div>
                  <div>
                    <div class="label">마진</div>
                    <div class="value"><button @click="chgMargin(item)">{{ item.mrgAmt }}</button></div>
                  </div>
                  <!-- <div>
                    <div class="label">비율</div>
                    <div class="value minus">-7.9%</div>
                  </div> -->
                  <div>
                    <div class="label">진입가격</div>
                    <div class="value">{{ toFixNum(item.symCd, item.execPrc) }}</div>
                  </div>
                  <div>
                    <div class="label">시장평균가</div>
                    <div class="value">{{ toFixNum(item.symCd, item.markPr) }}</div>
                  </div>
                  <div>
                    <div class="label">청산가격</div>
                    <div class="value margin-call">-</div>
                  </div>
                </div>
                <div class="transaction-actions">
                  <button class="btn-line" @click="posTpSlOrder(item)">TP/SL</button>
                  <button class="btn-line" @click="limitClearOrd(item)">지정가청산</button>
                  <button class="btn-line" @click="orderClear(item)">시장가청산</button>
                </div>
              </div>
            </li>
          </ul>

          <ul class="mobile-transaction-list unfilled" v-if="coinPositionType === 'ORD'">
            <li v-for="(item, index) of coinOrdSlList" :key="index">
              <div class="transaction-summary">
                <div>
                  <div class="label">{{ item.symCd }}</div>
                  <div class="value margin">
                    {{ item.marginMode == '1' ? "CROSS" : "ISOLATED" }} / {{ item.lv }}x
                  </div>
                </div>
              </div>
              <div class="transaction-detail">
                <div>
                  <div class="label">구분</div>
                  <div class="value" :class="{ 'plus': item.ordSdCd == '2', 'minus': item.ordSdCd == '1' }">{{
                    item.ordSdNm}}</div>
                </div>
                <div>
                  <div class="label">타입</div>
                  <div class="value">{{ item.typeNm }}</div>
                </div>
                <div>
                  <div class="label">수량</div>
                  <div class="value"
                    :class="{ '': Number(item.balQty) == 0, 'plus': item.ordSdCd == '2' && Number(item.balQty) > 0, 'minus': item.ordSdCd == '1' && Number(item.balQty) > 0 }">
                    {{ item.ordType == 'SL' && Number(item.balQty) == 0 ?
                      '청산' :
                      item.ordSdCd == '1' ? NumberComma(item.balQty * -1) : NumberComma(item.balQty)
                    }}
                  </div>
                </div>
                <div>
                  <div class="label">가격</div>
                  <div class="value">{{ toFixNum(item.symCd, item.execPrc) }}</div>
                </div>
              </div>
              <div class="transaction-actions">
                <button class="btn-line" :disabled="item.ordType != 'ORD' || item.slOrdCnt > 0"
                  @click="modifyOrderPop(item)">
                  {{ item.ordType != 'ORD' || item.slOrdCnt > 0 ? '--' : '주문 정정' }}
                </button>
                <button class="btn-line" :disabled="item.slOrdCnt <= 0" @click="modifyTpSlPop(item)">
                  {{ item.slOrdCnt <= 0 ? '--' : 'TP/SL' }} </button>
                    <button class="btn-line" @click="cancelOrdSl(item)">취소</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="mobile-chart-page" v-show="activeTab === 'chart'">
        <CoinChartBox :symbol="coinSymbol" :symbolList="coinSymbolList" :height="height" />
      </div>
      <div class="mobile-assets-page" v-if="activeTab === 'assets'">
        <div class="info-panel">
          <div class="panel-header">
            <div class="header-title">예탁금</div>
            <button class="icon" @click="coinTrasnfer">
              자산이동
              <img src="@/assets/icons/transfer.svg" alt="" />
            </button>
          </div>
          <table class="info-list-table">
            <tbody>
              <tr>
                <th>보유자산</th>
                <td>{{ coinDailyPlMn.usdt == undefined ? 0 : NumberComma(Number(coinDailyPlMn.usdt)) }}</td>
              </tr>
              <tr>
                <th>사용가능자산</th>
                <td>{{ coinDailyPlMn.ableUsdt == undefined ? 0 : NumberComma(Number(coinDailyPlMn.ableUsdt)) }}</td>
              </tr>
              <tr>
                <th>사용중자산</th>
                <td>{{ coinDailyPlMn.useUsdt == undefined ? 0 : NumberComma(Number(coinDailyPlMn.useUsdt)) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="info-panel last">
          <div class="panel-header">
            <div class="header-title">계약정보</div>
          </div>
          <table class="info-list-table">
            <tbody>
              <tr>
                <th>만료일자</th>
                <td>0</td>
              </tr>
              <tr>
                <th>자수가격</th>
                <td>0</td>
              </tr>
              <tr>
                <th>표시가격</th>
                <td>0</td>
              </tr>
              <tr>
                <th>24H 판매량</th>
                <td>0</td>
              </tr>
              <tr>
                <th>24H 거래량</th>
                <td>0</td>
              </tr>
              <tr>
                <th>계약가액</th>
                <td>0</td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </div>

    <!-- 계좌 sheet -->
    <div class="mobile-sheet-container" :class="{ active: showAccountSheet }">
      <div class="bg" @click="showAccountSheet = false"></div>
      <div class="mobile-sheet" :class="{ active: showAccountSheet }">
        <div class="mobile-sheet-header">
          <div class="title">계좌</div>
          <button @click="showAccountSheet = false"><img src="@/assets/icons/close-sheet.svg" alt="" /></button>
        </div>
        <div class="mobile-sheet-body">
          <ul>
            <li @click="
              showAccountSheet = false;
            showModal('modalTransactionTable');
            ">
              거래/체결내역
            </li>
            <li @click="
              showAccountSheet = false;
            showModal('modalProfitLossTable');
            ">
              매매손익
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="mobile-sheet-container" :class="{ active: showDepositSheet }">
      <div class="bg" @click="showDepositSheet = false"></div>
      <div class="mobile-sheet" :class="{ active: showDepositSheet }">
        <div class="mobile-sheet-header">
          <div class="title">입출금/전환내역</div>
          <button @click="showDepositSheet = false"><img src="@/assets/icons/close-sheet.svg" alt="" /></button>
        </div>
        <div class="mobile-sheet-body">
          <ul>
            <li @click="
              showDepositSheet = false;
            showModal('modalDepositWithdraw');
            ">
              입출금/전환신청
            </li>
            <li @click="
              showDepositSheet = false;
            showModal('modalDepositWithdrawTable');
            ">
              입출금/전환내역
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="mobile-sheet-container" :class="{ active: showMarginSheet }">
      <div class="bg" @click="showMarginSheet = false"></div>
      <div class="mobile-sheet" :class="{ active: showMarginSheet }">
        <div class="mobile-sheet-header">
          <div class="title">마진모드 선택</div>
          <button @click="showMarginSheet = false"><img src="@/assets/icons/close-sheet.svg" alt="" /></button>
        </div>
        <div class="mobile-sheet-body">
          <div class="modal-margin-select">
            <div class="margin-select-row">
              <button class="btn-line" :class="{ active: selMarginMode === '1' }"
                @click="chgMarginMode('1')">교차</button>
              <button class="btn-line" :class="{ active: selMarginMode === '2' }"
                @click="chgMarginMode('2')">격리</button>
            </div>
            <h1>• 마진 모드 전환은 선택한 계약에만 적용됩니다.</h1>
            <p>
              교차 마진 모드: 동일한 마진 자산의 모든 교차 포지션은 동일한 자산의 교차 마진 잔고를 공유합니다. 청산 발생 시, 자산의 잔여 오픈 포지션과
              함께 자산 전체 마진 잔고가 몰수될 수 있습니다.
            </p>
            <br />
            <p>
              격리 마진 모드: 해당 포지션에 지정된 마진이 특정 금액으로 제한됩니다. 만약 마진이 유지 마진 레벨 밑으로 떨어지면 포지션이 청산됩니다.
              그러나 이 모드에서는 원하는대로 마진을 추가하거나 제거할 수 있습니다.
            </p>
            <div class="alert-notice" v-if="!marginModeFlg">
              <img src="@/assets/icons/alert-blue.svg" alt="" />
              열린 주문/포지션을 보유하고 있는 경우 마진 모드를 변경할 수 없습니다.
            </div>
          </div>
        </div>
        <div class="mobile-sheet-footer">
          <!-- <button class="btn-primary" @click="showMarginSheet = false">확인</button> -->
          <button class="btn-primary" :class="{ 'btn-disabled': !marginModeFlg }" :disabled="!marginModeFlg"
            @click="selectMarginMode">확인</button>
        </div>
      </div>
    </div>

    <div class="mobile-sheet-container" :class="{ active: showLeverageSheet }">
      <div class="bg" @click="showLeverageSheet = false"></div>
      <div class="mobile-sheet" :class="{ active: showLeverageSheet }">
        <div class="mobile-sheet-header">
          <div class="title">레버리지 조정</div>
          <button @click="showLeverageSheet = false"><img src="@/assets/icons/close-sheet.svg" alt="" /></button>
        </div>
        <div class="mobile-sheet-body">
          <div class="modal-leverage-select">
            <div class="leverage-select-input">
              <div class="label">레버리지</div>
              <div class="input-group">
                <!-- <button><img src="@/assets/icons/plus-black.svg" alt="" /></button>
                <div class="leverage-input">{{coinLeverage}}x</div>
                <button><img src="@/assets/icons/minus-black.svg" alt="" /></button> -->
                <button @click="coinLeverageDown"><img src="@/assets/icons/minus-black.svg" alt=""></button>
                <!-- <div class="leverage-input">{{ coinLeverage }}x</div> -->
                <input type="text" v-model="formattedValue" maxlength="4" class="leverage-input">
                <button @click="coinLeverageUp"><img src="@/assets/icons/plus-black.svg" alt=""></button>
              </div>
            </div>
            <div>
              <SliderLeverage :leverageModeFlg="leverageModeFlg" @updateSliderLeverage="updateSliderLeverage"
                :value="Number(popCoinLeverage)" :min="1" :max=Number(maxLv) :divisor="5" />
            </div>
            <p>• 현재 레버리지에서의 최대 포지션 : 600,000 USDT</p>
            <p>• 레버리지 변경은 미체결 포지션 및 미체결 주문에도 적용됩니다.</p>
            <div class="alert-notice">[10x]와 같은 더 높은 레버리지를 선택하면 청산 위험이 커집니다. 항상 위험 수준을 관리하세요.</div>
          </div>
        </div>
        <div class="mobile-sheet-footer">
          <button class="btn-primary" @click="selectCoinLeverage">확인</button>
        </div>
      </div>
    </div>

    <div class="mobile-sheet-container" :class="{ active: showTradeModeSheet }">
      <div class="bg" @click="showTradeModeSheet = false"></div>
      <div class="mobile-sheet" :class="{ active: showTradeModeSheet }">
        <div class="mobile-sheet-header">
          <div class="title">거래모드 선택</div>
          <button @click="showTradeModeSheet = false"><img src="@/assets/icons/close-sheet.svg" alt="" /></button>
        </div>
        <div class="mobile-sheet-body">
          <div class="trade-mode-container">
            <button class="trade-mode-btn" :class="{ active: selCpos === '1' }" @click="chgPosition('1')">
              <img src="@/assets/icons/oneway-mode.svg" alt="" />
              <div class="desc-group">
                <div class="title">단방향 모드</div>
                <div class="desc-p">단방향 모드에서는 기호가 단방향으로만 위치를 유지할 수 있습니다.</div>
              </div>
            </button>
            <button class="trade-mode-btn" :class="{ active: selCpos === '2' }" @click="chgPosition('2')">
              <img src="@/assets/icons/hedge-mode.svg" alt="" />
              <div class="desc-group">
                <div class="title">헤지 모드</div>
                <div class="desc-p">
                  헤지 모드에서는 한 종목이 롱 포지션과 숏 포지션을 동시에 유지하는 것을 지원하며, 실현되지 않은 손익은 두 종목의 포지션 사이에서
                  상쇄될 수 있습니다.
                </div>
              </div>
            </button>
            <p>
              • 포지션 모드는 오픈 포지션이나 오픈 오더로 조정할 수 없습니다. 포지션 모드 조정은 모든 심볼에 유효합니다. 이설정은 USDⓢ-M 선물에만
              적용됩니다.
            </p>
          </div>
        </div>
        <div class="mobile-sheet-footer">
          <!-- <button class="btn-primary" @click="showTradeModeSheet = false">확인</button> -->
          <button class="btn-primary blue" :class="{ 'btn-disabled': !cposFlg }" :disabled="!cposFlg"
            @click="savePositionMode()">확인</button>
        </div>
      </div>
    </div>

    <div class="mobile-sheet-container" :class="{ active: showTPSLSheet }">
      <div class="bg" @click="showTPSLSheet = false"></div>
      <div class="mobile-sheet" :class="{ active: showTPSLSheet }">
        <div class="mobile-sheet-header">
          <div class="title">TP/SL</div>
          <button @click="showTPSLSheet = false"><img src="@/assets/icons/close-sheet.svg" alt="" /></button>
        </div>
        <div class="mobile-sheet-body">
          <div class="modal-tpsl-all">
            <table class="modal-tpsl-all-table">
              <tbody>
                <tr>
                  <td>
                    <div class="label">심볼</div>
                    <div class="value"><span class="orange">{{ ordInfo.symCd }} {{ ordInfo.lv }}x</span></div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label">진입가격</div>
                    <div class="value">{{ ordInfo.execPrc }} USDT</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label">시장평균가</div>
                    <div class="value">{{ ordInfo.markPr }} USDT</div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="order-input-group">
              <div class="order-input-container">
                <label for="">이익실현(TP)</label>
                <input type="number" placeholder="이익실현(TP)" v-model="tpOrdPrc" />
                <div class="unit">
                  <CodeDropDown grpCd="PRCTP" v-model="ordTpType" popup />
                </div>
              </div>
              <p>
                <span class="blue">시장평균가</span>
                가
                <span class="blue">{{ tpOrdPrc == '' ? 0 : tpOrdPrc }}</span>
                에 도달하면 이익실현 시장가 주문을 발동하여 포지션을 종료합니다. 예상 PNL은 다음과 같습니다.
                <span class="red">-USDT</span>
              </p>
            </div>

            <div class="order-input-group">
              <div class="order-input-container">
                <label for="">스탑로스(SL)</label>
                <input type="number" placeholder="이익실현(TP)" v-model="slOrdPrc" />
                <div class="unit">
                  <CodeDropDown grpCd="PRCTP" v-model="ordSlType" popup />
                </div>
              </div>
              <p>
                <span class="blue">시장평균가</span>
                가
                <span class="blue">{{ slOrdPrc == '' ? 0 : slOrdPrc }}</span>
                에 도달하면 이익실현 시장가 주문을 발동하여 포지션을 종료합니다. 예상 PNL은 다음과 같습니다.
                <span class="blue">-USDT</span>
              </p>
            </div>
          </div>
        </div>
        <div class="mobile-sheet-footer">
          <button class="btn-primary" @click="saveTpSlPos">확인</button>
        </div>
      </div>
    </div>

    <div class="mobile-sheet-container" :class="{ active: showLimitClearSheet }">
      <div class="bg" @click="showLimitClearSheet = false"></div>
      <div class="mobile-sheet" :class="{ active: showLimitClearSheet }">
        <div class="mobile-sheet-header">
          <div class="title">지정가 청산</div>
          <button @click="showLimitClearSheet = false"><img src="@/assets/icons/close-sheet.svg" alt="" /></button>
        </div>
        <div class="mobile-sheet-body">
          <div class="modal-edit-order">
            <div class="order-input-group">
              <div class="order-input-container">
                <label for="">가격</label>
                <input type="number" placeholder="가격" v-model="limitOrdPrc" />
                <div class="unit">USDT</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mobile-sheet-footer">
          <button class="btn-primary darkgray" @click="showLimitClearSheet = false">취소</button>
          <button class="btn-primary" @click="saveLimitOrder">확인</button>
        </div>
      </div>
    </div>

    <div class="mobile-sheet-container" :class="{ active: showEditOrderSheet }">
      <div class="bg" @click="showEditOrderSheet = false"></div>
      <div class="mobile-sheet" :class="{ active: showEditOrderSheet }">
        <div class="mobile-sheet-header">
          <div class="title">주문정정</div>
          <button @click="cancelModifyOrder"><img src="@/assets/icons/close-sheet.svg" alt="" /></button>
        </div>
        <div class="mobile-sheet-body">
          <div class="modal-edit-order">
            <div class="order-input-group">
              <div class="order-input-container">
                <label for="">가격</label>
                <input type="number" v-model="modOrdPrc" placeholder="가격" value="0" />
                <div class="unit">USDT</div>
              </div>

              <div class="order-input-container">
                <label for="">수량</label>
                <input type="number" v-model="modOrdQty" placeholder="수량" value="0" />
                <div class="unit">{{ modOrdType }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mobile-sheet-footer">
          <button class="btn-primary darkgray" @click="cancelModifyOrder">취소</button>
          <button class="btn-primary" @click="modifyOrder">확인</button>
        </div>
      </div>
    </div>

    <div class="mobile-sheet-container" :class="{ active: showMarginEditSheet }">
      <div class="bg" @click="showMarginEditSheet = false"></div>
      <div class="mobile-sheet" :class="{ active: showMarginEditSheet }">
        <div class="mobile-sheet-header">
          <div class="title">마진 조정</div>
          <button @click="showMarginEditSheet = false"><img src="@/assets/icons/close-sheet.svg" alt="" /></button>
        </div>
        <div class="mobile-sheet-body">
          <div class="modal-margin-edit">
            <div class="margin-edit-balance-row">
              <div class="label">수량(USDT)</div>
              <div class="value">최대 0 가능 금액: 0 USDT</div>
            </div>
            <div class="order-input-container margin-edit">
              <!-- <label for="">익절</label> -->
              <div class="base-dropdown no-border">
                <div class="dropdown">
                  제거
                  <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
                  <!-- <ul class="dropdown-list">
                  <li>추가</li>
                  <li>제거</li>
                </ul> -->
                </div>
              </div>
              <input type="number" placeholder="수량" value="0" />
              <button class="unit-btn">Max</button>
            </div>
            <ul class="margin-edit-table-row">
              <li>
                <div class="label">현재 할당된 마진</div>
                <div class="value">100.00 USDT</div>
              </li>
              <li>
                <div class="label">최대 0 가능</div>
                <div class="value">100.00 USDT</div>
              </li>
              <li>
                <div class="label">0 후 예상 청산 가격</div>
                <div class="value">100.00 USDT</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="mobile-sheet-footer">
          <button class="btn-primary" @click="showMarginEditSheet = false">확인</button>
        </div>
      </div>
    </div>

    <div class="mobile-sheet-container" :class="{ active: showOrdTpSlFlag }">
      <div class="bg" @click="showOrdTpSlFlag = false"></div>
      <div class="mobile-sheet flex-scroll" :class="{ active: showOrdTpSlFlag }">
        <div class="mobile-sheet-header">
          <div class="title">이익실현(TP)/스탑로스(SL)</div>
          <button @click="showOrdTpSlFlag = false"><img src="@/assets/icons/close-sheet.svg" alt="" /></button>
        </div>
        <div class="mobile-sheet-body">
          <div class="modal-tpsp">
            <div class="label table-label" v-if="ordInfo.slOrdCnt < 2">주문A가 실행되면 주문B를 제출합니다.</div>
            <div class="label table-label" v-if="ordInfo.slOrdCnt == 2">주문A가 실행되면 주문B, 주문C를 제출합니다.</div>
            <table class="modal-tpsp-table">
              <thead>
                <tr>
                  <th>주문A</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="label">{{ ordInfo.ordTpCd == "1" ? "지정" : "시장" }}</div>
                    <div class="value">{{ ordInfo.ordType == "ORD" ? "미체결" : "체결" }} </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label">사이드</div>
                    <div class="value"><span class="red"
                        :class="{ 'red': ordInfo.ordSdCd == '2', 'blue': ordInfo.ordSdCd == '1' }">{{ ordInfo.ordSdNm
                        }}</span></div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label">수량</div>
                    <div class="value">{{ ordInfo.execPrc }} USDT</div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="division-line-tpsl" v-if="ordInfoList.length > 0"><span></span></div>

            <div v-for="(item, index) of ordInfoList" :key="index">
              <table class="modal-tpsp-table">
                <thead>
                  <tr>
                    <th>주문{{ item.ordEng }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="label">{{ item.slTpCd == '1' ? '익절' : '손절' }} 시장가</div>
                      <div class="value">{{ ordInfo.ordType == "ORD" ? "보류중" : "신규" }}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="label">사이드</div>
                      <div class="value"><span class="red"
                          :class="{ 'red': item.slOrdSdCd == '2', 'blue': item.slOrdSdCd == '1' }">{{ item.slOrdSdNm
                          }}</span></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="label">수량</div>
                      <div class="value">{{ NumberComma(item.slOrdQty) }} BTC</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="label">가격</div>
                      <div class="value">
                        <div class="order-input-container no-border">
                          <input type="text" placeholder="가격" :value="item.slOrdPrc"
                            @input="item.slOrdPrc = checkValuePoint($event)" maxlength="13"
                            :disabled="ordInfo.ordType == 'ORD' ? false : true" />
                          <div class="unit">USDT</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="label">발동</div>
                      <div class="value">
                        <CodeDropDown grpCd="PRCTP" v-model="item.prcTpCd" popup
                          @input="changeTpSlType(index, $event)" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="division-line-tpsl" v-if="ordInfoList.length > 0 && index == 0"><span></span></div>
            </div>

            <div class="alert-notice">
              <img src="@/assets/icons/alert-blue.svg" alt="" />
              One-Triggers-a One Cancels the-Other(OTOCO) 주문을 사용하면 두 개의 주문, 즉 기본 주문과 두 개의 보조 주문을 동시에 제출할 수 있습니다.
            </div>
          </div>
        </div>
        <div class="mobile-sheet-footer">
          <button class="btn-primary" @click="saveTpSl">확인</button>
        </div>
      </div>
    </div>

    <!-- <div class="mobile-sheet-container" :class="{ active: showTpSlFlag }">
      <div class="bg" @click="showTpSlFlag = false"></div>
      <div class="mobile-sheet flex-scroll" :class="{ active: showTpSlFlag }">
        <div class="mobile-sheet-header">
          <div class="title">이익실현(TP)/스탑로스(SL)</div>
          <button @click="showTpSlFlag = false"><img src="@/assets/icons/close-sheet.svg" alt="" /></button>
        </div>
        <div class="mobile-sheet-body">
          <div class="modal-tpsp">
          <div class="label table-label">주문A가 실행되면 주문B를 제출합니다.</div>
          <table class="modal-tpsp-table">
            <thead>
              <tr>
                <th>주문A</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="label">시장</div>
                  <div class="value">미체결</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">사이드</div>
                  <div class="value"><span class="red">매수</span></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">수량</div>
                  <div class="value">
                    1,123 BTC
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="division-line-tpsl"><span></span></div>
          <div class="label table-label">주문B가 제출되면, 주문C는 취소됩니다.</div>
          <table class="modal-tpsp-table">
            <thead>
              <tr>
                <th>주문B</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="label">이익 현실 시장가</div>
                  <div class="value">신규</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">사이드</div>
                  <div class="value"><span class="blue">매도</span></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">수량</div>
                  <div class="value">1,234 BTC</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">스탑</div>
                  <div class="value">
                    <div class="order-input-container no-border">
                      <input type="number" placeholder="가격" value="0" />
                      <div class="unit">USDT</div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">발동</div>
                  <div class="value">
                    <div class="base-dropdown no-border">
                      <div class="dropdown">
                        Mark
                        <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
                        <ul class="dropdown-list">
                          <li>Mark</li>
                          <li>Last</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="division-line-tpsl"><span></span></div>
          <div class="label table-label">주문C가 제출되면, 주문B는 취소됩니다.</div>
          <table class="modal-tpsp-table">
            <thead>
              <tr>
                <th>주문C</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="label">이익 현실 시장가</div>
                  <div class="value">신규</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">사이드</div>
                  <div class="value"><span class="blue">매도</span></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">수량</div>
                  <div class="value">1,234 BTC</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">스탑</div>
                  <div class="value">
                    <div class="order-input-container no-border">
                      <input type="number" placeholder="가격" value="0" />
                      <div class="unit">USDT</div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">발동</div>
                  <div class="value">
                    <div class="base-dropdown no-border">
                      <div class="dropdown">
                        Mark
                        <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
                        <ul class="dropdown-list">
                          <li>Mark</li>
                          <li>Last</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="alert-notice">
            <img src="@/assets/icons/alert-blue.svg" alt="" />
            One-Triggers-a One Cancels the-Other(OTOCO) 주문을 사용하면 두 개의 주문, 즉 기본 주문과 두 개의 보조 주문을 동시에 제출할 수 있습니다.
          </div>
        </div>
        </div>
        <div class="mobile-sheet-footer">
          <button class="btn-primary" @click="showTpSlFlag = false">확인</button>
        </div>
      </div>
    </div> -->

    <!-- modals -->
    <ModalTransactionTable />
    <ModalProfitLossTable />

    <ModalDepositWithdrawTable />

    <ModalSettings />
    <ModalDepositWithdraw />
    <ModalNotice />
    <ModalNoticeDetail />
    <ModalBasic />
    <ModalLogout />
    <ModalInstruments :coinSymbolList="coinSymbolList" />
  </div>
</template>

<script>
import ModalSettings from '@/components/coin/ModalSettings.vue';
import ModalDepositWithdraw from '@/components/coin/ModalDepositWithdraw.vue';
import Slider from '@/components/coin/Slider.vue';
import SliderLeverage from '@/components/coin/SliderLeverage.vue';

import AvatarComp from '@/components/AvatarComp.vue';
import IconChevronDown from '@/components/icons/IconChevronDown.vue';
import CounterComp from '@/components/CounterComp.vue';
import AgPositionTable from '@/components/agTables/AgPositionTable.vue';
import AgFilledTable from '@/components/agTables/AgFilledTable.vue';
import ModalTransactionTable from '@/components/coin/ModalTransactionTable.vue';
import ModalProfitLossTable from '@/components/coin/ModalProfitLossTable.vue';
import ModalDepositWithdrawTable from '@/components/coin/ModalDepositWithdrawTable.vue';
import ModalNotice from '@/components/ModalNotice.vue';
import ModalNoticeDetail from '@/components/ModalNoticeDetail.vue';
import ModalLogout from '@/components/ModalLogout.vue';
import AgOptionsTable from '@/components/agTables/AgOptionsTable.vue';
import ModalInstruments from '@/components/coin/ModalInstruments.vue';
import { stringCount } from '@/modules/helper';
import Utils from '@/modules/utils';
import Const from '@/const';
import EventBus from '@/modules/event-bus';
import DomGridClick from '@/components/DomGridClick.vue';
import CoinChartBox from '@/components/CoinChartBox';
import ModalConfirmOrder from '@/components/ModalConfirmOrder.vue';
import ModalBasic from '@/components/ModalBasic.vue';
import CoinDropDown from '@/components/coin/CoinDropDown.vue';
import CodeDropDown from '@/components/CodeDropDown.vue'
import BaseDropdown from '@/components/BaseDropdown.vue'

export default {
  name: 'CoinMobilePage',
  components: {
    ModalSettings,
    ModalDepositWithdraw,
    Slider,
    SliderLeverage,
    AvatarComp,
    IconChevronDown,
    CounterComp,
    AgPositionTable,
    AgFilledTable,
    ModalTransactionTable,
    ModalProfitLossTable,
    ModalDepositWithdrawTable,
    ModalNotice,
    ModalNoticeDetail,
    ModalLogout,
    AgOptionsTable,
    ModalInstruments,
    DomGridClick,
    CoinChartBox,
    ModalConfirmOrder,
    ModalBasic,
    CoinDropDown,
    CodeDropDown,
    BaseDropdown,
  },
  computed: {
    // 로그인 여부
    isLogin() {
      return window.$store.getters['Login/isLogin'];
    },
    //소켓 코인 시세
    changeCocData: function () {
      let cocData = window.$store.getters['SocketCoinPrice/getCocData'];
      return cocData;
    },
    //소켓 코인 호가
    changeCohData: function () {
      let cohData = window.$store.getters['SocketCoinPrice/getCohData']
      return cohData
    },
    //소켓 코인 센터가
    changeCotData: function () {
      let cotData = window.$store.getters['SocketCoinPrice/getCotData']
      return cotData
    },
    //소켓 코인 청산가
    changeComData: function () {
      let comData = window.$store.getters['SocketCoinPrice/getComData']
      return comData
    },
    //소켓 코인 시/고/저 지수등
    changeChlData: function () {
      let chlData = window.$store.getters['SocketCoinPrice/getChlData']
      return chlData
    },
    //폴링시세
    changeCocDataList: function () {
      let cocData = window.$store.getters['SocketCoinPrice/getCocDataList'];
      return cocData;
    },
    //폴링호가
    changeCohDataList: function () {
      let cohData = window.$store.getters['SocketCoinPrice/getCohDataList']
      return cohData
    },
    changeFvSymbol: function () {
      let custom = window.$store.getters['Layout/getFavoriteGridCustom'];
      if (custom == undefined) {
        return null;
      }
      return { socketSymbol: custom.socketSymbol, socketSymbolCnt: this.socketSymbolCnt + 1 };
    },
    getSelectCoinSymbol: function () {
      return window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];
    },
    tikDotSz() {
      return Utils.tikDotSz(this.coinSymbol);
    },
    resultCoinOms: function () {
      return window.$store.getters['SocketCoinOms/getCompleteOrder'];
    },
    getAbleQty: function () {
      return window.$store.getters['SocketCoinOms/getAbleQty'];
    },
    resultMsg: function () {
      return window.$store.getters['SocketMsg/getMessage'];
    },
    getCoinDailyPlMn: function () {
      return window.$store.getters['SocketCoinOms/getCoinDailyPlMn'];
    },
    getLoginUserInfo: function () {
      let loginInfo = window.$store.getters['Login/getLoginUserInfo'];
      return loginInfo;
    },
    getCoinPosOrdStList: function () {
      return window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
    },
    getUsrPos: function () {
      return window.$store.getters['SocketCoinOms/getUsrPos'];
    },
    //ask 호가 표시개수만큼 자르기
    asksObject() {
      //viewCnt +1 라인부터 , viewCnt +1 + viewCnt 목록개수보다 적으면 .asksListlength
      let first = 0;
      let last = 20;
      const maxlen = this.asksList.length;
      //16 < 20
      if (this.viewCnt < this.asksList.length) {
        first = this.asksList.length - this.viewCnt + 1;
        //17 + 16 ...
        //9 ~ 20
      }
      //return this.viewCnt ? this.asksList.slice(this.viewCnt+1, this.viewCnt+1+this.viewCnt < this.asksList.length ? this.viewCnt+1+this.viewCnt : this.asksList.length) : this.asksList
      return this.viewCnt ? this.asksList.slice(0, this.viewCnt > this.bidsList.length ? this.bidsList.length : this.viewCnt) : this.asksList
    },
    askBidMax() {
      //viewCnt +1 라인부터 , viewCnt +1 + viewCnt 목록개수보다 적으면 .asksListlength
      let first = 0;
      let last = 20;
      const maxlen = this.asksList.length;
      //16 < 20
      if (this.viewCnt < this.asksList.length) {
        first = this.asksList.length - this.viewCnt + 1;
        //17 + 16 ...
        //9 ~ 20
      }
      //return this.viewCnt ? this.asksList.slice(this.viewCnt+1, this.viewCnt+1+this.viewCnt < this.asksList.length ? this.viewCnt+1+this.viewCnt : this.asksList.length) : this.asksList
      const ask = this.viewCnt ? this.asksList.slice(0, this.viewCnt > this.bidsList.length ? this.bidsList.length : this.viewCnt) : this.asksList
      const bid = this.viewCnt ? this.bidsList.slice(0, this.viewCnt > this.bidsList.length ? this.bidsList.length : this.viewCnt) : this.bidsList

      let maxval = 0
      ask.forEach(item => {
        if (maxval < Number(item.sum)) {
          maxval = Number(item.sum)

        }
      })

      bid.forEach(item => {
        if (maxval < Number(item.sum)) {
          maxval = Number(item.sum)
        }
      })

      return maxval
    },
    //bid 호가 표시개수만큼 자르기
    bidsObject() {
      return this.viewCnt ? this.bidsList.slice(0, this.viewCnt > this.bidsList.length ? this.bidsList.length : this.viewCnt) : this.bidsList
    },
    getCoinSymbolInfoList: function () {
      return window.$store.getters['Symbol/getCoinSymbolInfoList'];
    },
    formattedValue: {
      get() {
        return `${this.popCoinLeverage}x`; // aaa 뒤에 X를 붙여서 표시
      },
      set(value) {
        const numericValue = parseInt(value.replace(/X$/, ''), 10); // 숫자만 추출
        if (!isNaN(numericValue)) {
          this.popCoinLeverage = numericValue;
        }
      },
    },
  },
  watch: {
    //소켓시세
    changeCocData(newPrice) {
      //console.log("changeOvcData >>>> ", newPrice)

      this.cocMap.set(newPrice.symbol, newPrice);
      this.setGridPrice(); //현재가, 평가손익 새로고침

      if (newPrice.symbol == this.coinSymbol.name) {
        this.coc = newPrice;
        this.cot = newPrice

        this.coinNowPrice.curPr = newPrice.curPr;
        this.coinNowPrice.buyerMaker = newPrice.buyerMaker;
      }
      //
    },
    //소켓호가
    changeCohData(newPrice) {
      // apiType
      // asks
      // bids
      // hotTime
      // symbol
      if (newPrice.symbol == this.coinSymbol.name) {
        this.coinCohPrice = newPrice;
        this.setPriceCost();
        this.setCostUsdt();
        this.updateCoh(newPrice);
      }
    },
    //소켓 코인 센터가
    changeCotData(newPrice) {
      if (newPrice.symbol == this.coinSymbol.name) {

        // apiType: "COT"
        // buyerMaker: false
        // curPr: 61094.7
        // korDate: "20241011"
        // korTm: "191003"
        // qty: 0.017
        // symbol: "BTCUSDT"
        // trdTm: 1728641403276


        //this.cot = newPrice
      }
    },
    //소켓 코인 청산가
    changeComData(newPrice) {
      // apiType
      // korDate
      // korTm
      // markPr
      // rate
      // symbol
      // trdTm
      this.comMap.set(newPrice.symbol, newPrice);
      if (newPrice.symbol == this.coinSymbol.name) {
        this.com = newPrice;
        this.coinNowPrice.markPr = (Math.floor(newPrice.markPr * 100) / 100).toFixed(this.tikDotSz);
      }
    },
    //소켓 코인 시/고/저 지수등
    changeChlData(newPrice) {
      if (newPrice.symbol == this.coinSymbol.name) {
        // chPr
        // chPrate
        // close
        // closeQty
        // high
        // korDate
        // korTm
        // low
        // open
        // symbol
        // totQ
        // totV
        // trdTm
        // whAvgPr
        this.chl = newPrice;
        this.coinNowPrice.chPrRate = newPrice.chPr.toFixed(this.tikDotSz);
        this.coinNowPrice.chGrate = newPrice.chPrate;
        this.coinNowPrice.funding = 0;
        this.coinNowPrice.CountDown = 0;
        this.coinNowPrice.highPr = newPrice.high.toFixed(this.tikDotSz);
        this.coinNowPrice.lowPr = newPrice.low.toFixed(this.tikDotSz);
        this.coinNowPrice.indexPr = 0;
        this.coinNowPrice.usdtVol = Utils.numberComma(newPrice.totQ);
        this.coinNowPrice.btcVol = Utils.numberComma(newPrice.totV);
        this.coinNowPrice.trdTm = newPrice.trdTm;
        this.coinNowPrice.usdtOpenInterest = 0;

        // this.coinNowPrice.chPr = newPrice.chPr.toFixed(this.tikDotSz);
        // this.coinNowPrice.chPrate = newPrice.chPrate;
        // this.coinNowPrice.close = newPrice.close;
        // this.coinNowPrice.closeQty = newPrice.closeQty;
        // this.coinNowPrice.high = newPrice.high;
        // this.coinNowPrice.low = newPrice.low;
        // this.coinNowPrice.open = newPrice.open;
        // this.coinNowPrice.totQ = newPrice.totQ;
        // this.coinNowPrice.totV = newPrice.totV;
        // this.coinNowPrice.trdTm = newPrice.trdTm;
        // this.coinNowPrice.whAvgPr = newPrice.whAvgPr;
        //this.coinNowPrice.markPr = Math.floor(newPrice.markPr * 100) / 100;
      }
    },
    //폴링시세
    changeCocDataList(newPrice) {
      //
    },
    //폴링호가
    changeCohDataList(newPrice) {
      //
    },
    changeFvSymbol(val) {
      this.updateSubscribeOnPrice()
    },
    getSelectCoinSymbol(newVal) {
      if (newVal == undefined) return;
      // this.rStock = Object.assign({}, null);

      //종목이 변경될경우 소켓데이터 연결 갱신
      this.coinSymbol = Object.assign({}, newVal);

      this.coinNowPrice = { symCd: newVal.name, symNo: newVal.symbolno, chGrate: 0, chPrRate: 0, curPr: 0, markPr: 0, indexPr: 0, funding: 0, CountDown: 0, highPr: 0, lowPr: 0, btcVol: 0, usdtVol: 0, usdtOpenInterest: 0, buyerMaker: false };

      this.coinTikSize = newVal.minmov / newVal.pricescale;

      this.coinOrdStdPrc = 0; //일반주문 가격

      this.bidsList = [];
      this.asksList = [];
      this.coc.curPr = 0;
      this.coc.qty = 0;
      this.cot.curPr = 0;
      this.com.markPr = 0;

      this.setStockInfo(newVal);

      // //this.symbolDropdown(newVal);

      this.updateSubscribeOnCoinPrice();

      // this.selectSymbolTikPrice();

      // this.selectOrder(this.symbol.name);

      //accId가 있으면
      if (this.coinAccount.accId && this.coinAccount.accId > 0) {
        this.searchCoinUsrData();
        this.selectCoinUsrPos();
      } else {
        //accId가 로딩중이면 기다렸다 실행
        window.$store.watch(() => this.coinAccount.accId, isAccount => {
          if (isAccount) {
            this.searchCoinUsrData();
            this.selectCoinUsrPos();
          }
        })
      }

      this.stdOrdPrc = ''; //주문 가격
      this.stdOrdQty = ''; //주문 수량
      this.stdOrdSlPrc = '';
      this.stdOrdTpPrc = '';
      this.onSliderUpdate(0);
      this.stdOrdQty = '';

      for (let item of this.coinSymbolInfoList) {
        if (item.symNo == this.coinSymbol.symbolno) {
          this.coinSymbolInfo = item;

          if (this.stdOrdType != "USDT") {
            // 수량 리스트 변경
            this.coinOrdTypeList[1].name = item.artcCd;
            this.selOrdType = '2'; //코인 표시로 바뀌도록 설정
            this.stdOrdType = item.artcCd;
            this.setPriceCost();
          }
          break;
        }
      }

      this.setCostUsdt();

      //종목 저장
      /*
      if (this.account.accId && this.account.accId > 0 && (this.favoriteSymbol == null || this.favoriteSymbol != this.symbol.symbolno)){
        this.favoriteSymbol = this.symbol.symbolno;
        window.$store.dispatch('Layout/saveCustom', { type: 'SYMBOL', custom: this.favoriteSymbol});
      }else if ((!this.account.accId || this.account.accId == 0) && (this.favoriteSymbol == null || this.favoriteSymbol != this.symbol.symbolno)){
        //accId가 로딩중이면 기다렸다 실행
        window.$store.watch(() => this.account.accId, isAccount => {
          if (isAccount) {
            window.$store.dispatch('Layout/saveCustom', { type: 'SYMBOL', custom: this.favoriteSymbol});
          }
        })
      }
      */
    },
    resultCoinOms(res) {
      if (res.result == "S") {
        this.completeCoinOrder(res);
        this.resetUsdt();
      } else {
        if (res.apiCode == "COMS9990") {
          if (this.getLoginUserInfo != undefined && this.getLoginUserInfo.authId != undefined) {
            window.$http.post('/auth/login/refresh', { params: {} });
          }
          return;
        } else {
          this.procFailMessage(res);

          //실패시 리스트목록 주문 목록 재조회
          if (res.body.code != "OMSFL007" && res.body.code != "OMSFL011" && res.body.code != "OMSFL002" && res.body.code != "OMSFL012"
            && res.body.code != "OMSFL014" && res.body.code != "OMSFL016" && res.body.code != "OMSFL017") {
            this.searchCoinUsrData();
          }
        }
      }
    },
    resultMsg: {
      handler(res) {
        //console.log("resultMsg >>>> ", res)
        if (res.apiCode == Const.MSG_API_TYPE.BANK) {
          //유저 데이터 재조회
          window.$store.dispatch('SocketOms/selectUsrData', { accId: this.account.accId, symCd: this.symbol.name, symNo: this.symbol.symbolno, lv: this.leverage });
        } else if (res.apiCode == Const.MSG_API_TYPE.LOGOUT) {

          window.$store.dispatch('Login/logout');
          this.disConnect();
          this.disConnectMsg();

          this.$alert.alert('다른 브라우저에서 로그인하여 <span style="color:red;">로그아웃</span> 되었습니다.<br/><br/>로그인을 다시 시도하시거나 사용을 종료 해 주십시오.', '안내')
            .then(() => {
              this.$router.push('/login');
            });

          return;
        } else if (res.apiCode == Const.MSG_API_TYPE.LOGINKILL) {
          //LOGINKILL("MSG0103"),		// 관리자 로그아웃처리
          window.$store.dispatch('Login/logout');

          this.disConnect();
          this.disConnectMsg();
          this.$alert.alert('접속할 수 없습니다.<br/>관리자에게 문의하시기 바랍니다.', '안내')
            .then(() => {
              this.$router.push('/login');
            });
          //return;
        } else if (res.apiCode == Const.MSG_API_TYPE.NOTLOGIN) { //로그인 정보가 없으면 브라우저 로그인 되어있는지 확인 후 로그아웃처리
          if (this.getLoginUserInfo != undefined && this.getLoginUserInfo.authId != undefined) {
            window.$http.post('/auth/login/refresh', { params: {} });
          }
          return;
        } else if (res.apiCode == Const.MSG_API_TYPE.LVCHANGE) {
          //코인은 레버리지 조정이 없음
          return;
        }

        //공지 팝업 호출
        if (res.body.post != undefined && res.body.post.popupFlg != undefined && res.body.post.popupFlg == "Y") {
          this.$modal.show("modalBasic", res.body);
          return;
        } else {
          this.$notify({
            group: 'notification',
            //text : '<span style="color: var(--main-red)">알림</span> '+ res.body.msg
            text: res.body.msg
          });
          return;
        }
      },
      deep: true
    },
    getDailyPlMn: {
      handler(res) {
        this.dailyPlMn.fxPlMn = res.fxPlMn; // 해외손익금액
        this.dailyPlMn.fxCms = res.fxCms; // 해외수수료
        this.dailyPlMn.nFxPlMn = res.nFxPlMn; // 해외순손익
        this.dailyPlMn.futPlMn = res.futPlMn; // 국내손익금액
        this.dailyPlMn.futCms = res.futCms; // 국내수수료
        this.dailyPlMn.nFutPlMn = res.nFutPlMn; // 국내순손익
        this.dailyPlMn.totPlMn = res.totPlMn; // 총손익금액
        this.dailyPlMn.totNPlMn = res.totNPlMn; // 총순손익
        this.dailyPlMn.totCms = res.totCms; // 총수수료
        this.dailyPlMn.niPlMn = res.niPlMn;
        this.dailyPlMn.balMn = res.balMn;
        this.dailyPlMn.hkdExR = res.hkdExR;
        this.dailyPlMn.usdExR = res.usdExR;
        this.dailyPlMn.usdExHkd = res.usdExHkd;
      },
      deep: true
    },
    getAbleQty: {
      handler(res) {
        //
      },
      deep: true
    },
    getCoinDailyPlMn: {
      handler(res) {
        this.coinDailyPlMn = res;

        //자리수 소수점 2자리까지 자름
        if (this.coinDailyPlMn != undefined) {
          if (this.coinDailyPlMn.usdt != undefined) {
            this.coinDailyPlMn.usdt = Utils.toFixNum(this.coinDailyPlMn.usdt, 4).toFixed(4);
          }

          if (this.coinDailyPlMn.useUsdt != undefined) {
            this.coinDailyPlMn.orgUseUsdt = Utils.toFixNum(res.useUsdt, 4).toFixed(4);
            this.coinDailyPlMn.useUsdt = Utils.toFixNum(this.coinDailyPlMn.useUsdt, 4).toFixed(4);
          }

          if (this.coinDailyPlMn.ableUsdt != undefined) {
            this.coinDailyPlMn.orgAbleUsdt = Utils.toFixNum(res.ableUsdt, 4).toFixed(4);
            this.coinDailyPlMn.ableUsdt = Utils.toFixNum(this.coinDailyPlMn.ableUsdt, 4).toFixed(4);
            this.avbl = Utils.toFixNum(this.coinDailyPlMn.ableUsdt, 2).toFixed(2);
          }

        }

      },
      deep: true
    },
    getLoginUserInfo: {
      handler(userInfo) {
        // this.loginUserInfo = userInfo;
      }
    },
    getCoinPosOrdStList(newList) {
      //console.log("getCoinPosOrdStList", newList)
      //보유포지션 목록 시세 갱신요청
      this.rtPosCoinSymbolList = [];
      let rtPosCnt = 0;
      let rtPosOrdCnt = 0;
      for (const data of newList) {
        if (data.ordType == "POS") {
          this.rtPosCoinSymbolList.push(data);
          if (data.balQty > 0) {
            rtPosCnt++;
          }
        } else if (data.ordType == "ORD" || data.ordType == "SL") {
          rtPosOrdCnt++;
        }

      }

      this.rtPosCnt = rtPosCnt;
      this.rtPosOrdCnt = rtPosOrdCnt;

      this.updateSubscribeOnCoinPrice();

      this.coinPosOrdStList = newList;
      this.searchCoinPosOrdStList();
    },
    getUsrPos(usrPos) {
      console.log("getUsrPos ", usrPos);
      this.marginMode = usrPos.marginMode;
      this.coinLeverage = usrPos.leverage;
      this.tierList = usrPos.tierList;
      this.cpos = usrPos.cpos;
    },
    getCoinSymbolInfoList(newList) {
      this.coinSymbolInfoList = newList;
      if (newList != undefined && newList.length > 0) {
        this.coinSymbolInfo = this.coinSymbolInfoList[0];

        // this.coinBuyUsdt = 0.00;
        // this.coinSellUsdt = 0.00;
        // this.coinBuyQty = Utils.toFixInit(0, this.coinSymbolInfo.costDotCnt);
        // this.coinSellQty = Utils.toFixInit(0, this.coinSymbolInfo.costDotCnt);
        this.setPriceCost();
      }
    },
  },
  data() {
    return {
      activeTab: 'order',
      showMarginSheet: false,
      showLeverageSheet: false,
      showTradeModeSheet: false,
      tradeMode: 'oneway',
      showTPSLSheet: false,
      showEditOrderSheet: false,
      showMarginEditSheet: false,
      showTPSLABSheet: false,
      showTPSLABCSheet: false,
      showLimitClearSheet: false,

      // 마진조정
      marginAction: '추가',


      mobileSelectedTab: 'chart',
      mobileSelectedStandardOrder: 'sell',
      tabName: [{ name: "클릭", useYn: true }, { name: "MIT", useYn: false }, { name: "일반", useYn: false }, { name: "잔고", useYn: false }, { name: "체결", useYn: false }, { name: "차트", useYn: true }],
      tap1AuthShow: { ord: true, posList: false },
      tap2AuthShow: { mit: true, },
      tap3AuthShow: { mit: true, stopLoss: true },
      tap4AuthShow: { totCms: true },
      tap5AuthShow: {},
      showSide: false,
      showAccountSheet: false,
      showDepositSheet: false,
      showCounterSheet: false,
      // showInstrumentSheet: false,
      // mobileSheetTab: 'intFutures',
      containerHeight: '',


      showPanel: true,
      showInstruments: 'global',
      setIntervalId: null,
      selectedTheme: 'black',
      kospiList: [],
      futuresList: [],
      accId: null,
      account: {
        accNm: '',
        accPass: '',
        accCnt: 0,
        accId: 0,
      },
      coinAccount: {
        accNm: '',
        accPass: '',
        accCnt: 0,
        accId: 0,
      },
      accountList: [],
      chgSymbol: null,
      priceReqType: "SCKTP01", //시세 받는방식 socket(SCKTP01), polling(SCKTP02/SCKTP03)
      currencyInfo: { currency: "USDT" },
      orderSetting: null,
      totalValPl: null,
      orderType: 'click',
      tikSize: 0,
      avgPrice: null,
      popupId: null,
      width: 757,
      height: 600,
      layout: {},
      mobileSet: { type: "MO", fixNm: "고정", posList: false, fix: true, ord: false, mit: false, btnBottom: false },
      coc: {
        curPr: 0,
        qty: 0,
        buyerMaker: false,
      },
      cot: {
        curPr: 0,
        qty: 0,
        buyerMaker: false,
      },
      com: {
        markPr: 0,
      },
      chl: {
        chPr: 0,
        chPrate: 0,
      },
      //코인주문 관련
      coinSymbolList: [],
      coinSymbolPrepList: [],
      selCoinSymbolPrep: { name: null, base_name: null },
      coinOrdStdType: "1",
      coinOrdStdQty: 0,
      coinNowPrice: { symCd: null, symNo: 0, chGrate: 0, chPrRate: 0, curPr: 0, markPr: 0, indexPr: 0, funding: 0, CountDown: 0, highPr: 0, lowPr: 0, btcVol: 0, usdtVol: 0, usdtOpenInterest: 0, buyerMaker: false },
      coinSymbol: { name: null, base_name: null, symbolno: 0 },
      coinOrdStdPrc: 0,
      btnStdCoinFlag: true,
      btnClearAllFlag: true,
      btnCancelAllFlag: true,
      rtPosCoinSymbolList: [],
      popCoinLeverage: 1,
      coinLeverage: 1,
      beforeLeverage: 1,
      maxLv: 1,
      tierList: [],
      maxOrdAmt: '',
      leverageModeFlg: true,
      coinSellAbleQty: 0,
      coinBuyAbleQty: 0,
      cpos: "1",
      selCpos: "1",
      cposFlg: true,
      marginMode: "1",
      selMarginMode: "1",
      coinTikSize: 0,
      rtPosCnt: 0,
      rtPosOrdCnt: 0,
      coinPositionType: "POS",
      //코인주문 관련
      futUseYn: "N",
      coinUseYn: "N",
      coinTotalValPl: { totalQty: 0, totalValPl: 0, crossTotalValPl: 0, isolratedTotalValPl: 0 },
      rStock: null,
      stopTime: null,
      orderFullSize: false,
      showTpSlFlag: false,
      showOrdTpSlFlag: false,
      showTpFlag: false,
      showSlFlag: false,
      marginModeFlg: true,
      viewCnt: 6,
      bidsList: [],
      asksList: [],
      openCloseMode: "1",
      stdOrdTpCd: '1',
      stdOrdPrc: '',
      stdOrdQty: '',
      stdOrdType: 'USDT',
      selOrdType: '',
      selOrdTpCd: '',
      stdOrdTpType: '1',
      stdOrdSlType: '1',
      stdOrdTpPrc: '',
      stdOrdSlPrc: '',
      coinOrdTypeList: [{ cd: "1", name: 'USDT' }, { cd: "2", name: 'BTC' }],
      ordTpCdList: [{ cd: "1", name: '지정가' }, { cd: "2", name: '시장가' }],
      coinTier: [],
      coinSymbolInfo: { symCd: 'BTCUSDT', symNo: 101, tikSz: 0.1, artcCd: 'BTC', dotCnt: 1, korNm: '비트코인', lmtOrdMaxQty: 0, lmtOrdPrcCfRat: 0, maxOpnOrdCnt: 0, minOrdAmt: 0, minTrdAmt: 0, mktOrdMaxQty: 0 },
      coinDailyPlMn: { fxPlMn: "0", fxCms: "0", nFxPlMn: "0", futPlMn: "0", futCms: "0", nFutPlMn: "0", totPlMn: "0", totNPlMn: "0", totCms: "0", niPlMn: "0", balMn: "0", hkdExR: "0", usdExR: "0", usdExHkd: "0", totalValPl: "0", totalLossCut: "0", data: null, usdt: 0, useUsdt: 0, ableUsdt: 0, orgAbleUsdt: 0 },
      coinBuyQty: 0.00,
      coinSellQty: 0.00,
      coinBuyCostPrc: 0.00,
      coinSellCostPrc: 0.00,
      coinCohPrice: {},
      avbl: 0.00,
      buyCostUsdt: 0.00,
      sellCostUsdt: 0.00,
      sliderValue: 0,
      selectList: [],
      coinPosOrdStList: [],
      coinPosList: [],
      coinOrdSlList: [],
      cocMap: new Map(),
      comMap: new Map(),
      limitOrdPrc: '',
      limitOrdInfo: {},
      modOrdPrc: '',
      modOrdQty: '',
      modOrdType: 'BTC',
      modOrdInfo: {},

      //TP/SL
      ordTpType: '1',
      ordSlType: '1',
      tpOrdPrc: '',
      slOrdPrc: '',
      tpId: 0,
      slId: 0,
      ordInfo: {},
      ordInfoList: [],
      orgOrdInfoList: [],
      ordTpSlType: ['1', '1'],

    };
  },
  created() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    let cpnAuthList = window.$store.getters['Layout/getCpnAuthList'];
    if (cpnAuthList != undefined) {
      this.setCpnAuth(cpnAuthList);
    }

    this.domainCheck();

    //설정관련 처리필요
    //this.mobileSet.ord = true;

    const res = this.loginChk();
    res.then(response => {
      const data = response.data;
      if (data.code != 200) {
        this.mobileSelectedTab = 'chart';
      } else {
        if (data.loginUserInfo != undefined && data.loginUserInfo.authId != undefined) {
          this.mobileSelectedTab = 'clickOrder';
        } else {
          this.mobileSelectedTab = 'chart';
        }
      }

      //this.loadLayout();

      //this.selectAccountList();
    }).catch((e) => {
      //this.selectCoinSymbolList();
    });

    this.selectCoinSymbolList();

    //message 소켓 서버 연결
    this.connectMsg();

    EventBus.$on('Layout:event:loadLayout', (layout) => {
      this.setLayout(layout)
    })

    //절전모드 체크
    document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
  },
  mounted() {
    //화면 회전 체크...
    window.addEventListener("orientationchange", function (event) {
      console.log("orientationchange ", window.screen, window.screen.orientation.angle)
      if (window.matchMedia('(orientation: portrait)').matches) { //가로
        console.log("portrait")
      } else {
        console.log("Landscape")
      }
    });

    const chartPanel = document.querySelector('.mobile-inner-container');

    //차트 사이즈 자동인식
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        this.height = Number(height) - 80;
        //this.width = width;
      }
    });

    //자동으로 인식할 부분 추가
    observer.observe(chartPanel);

    // //공지팝업 테스트용
    // let test = {postNo:22};
    // this.$modal.show("modalBasic", test);    
  },
  methods: {
    toFixNum(symCd, execPrc) {
      let rtnValue = '';
      const value = execPrc;

      let symbolData = null;

      this.coinSymbolList.forEach(item => {
        if (item.name == symCd) {
          symbolData = item;
          return;
        }
      });

      //data.markPr = this.comMap.get(data.symCd).markPr.toFixed(Utils.tikDotSz(symbolData));

      if (value != undefined && value != null) {
        rtnValue = Utils.toFixNum(value, Utils.tikDotSz(symbolData)).toFixed(Utils.tikDotSz(symbolData));
      }
      return rtnValue;
    },
    async domainCheck() {
      const host = window.location.host;
      const formData = { hostUrl: host };

      await this.$lhttp.post('/auth/domainChk', formData).then((response) => {
        //console.log("response... ", response);
        if (response.code == 200) {
          if (response.data != undefined && response.data.cpnId > 0) {
            //접속방식 설정
            window.$store.dispatch('SocketCoinPrice/setPollingType', { sckTpCd: response.data.sckTpCd, sckTpDsc: response.data.sckTpDsc });
            this.coinPriceReqType = window.$store.getters['SocketCoinPrice/getCoinPriceReqType'];

            //코인 시세 설정
            window.$store.dispatch('SocketCoinPrice/setPollingType', { sckTpCd: response.data.sckTpCd, sckTpDsc: response.data.sckTpDsc });

            //커스텀 화면 설정
            let cpnAuthList = window.$store.getters['Layout/getCpnAuthList']
            if (cpnAuthList == undefined || cpnAuthList == null || cpnAuthList.length) {
              window.$store.dispatch('Layout/setCpnAuth', { data: response.data.cpnAuthList })
              cpnAuthList = response.data.cpnAuthList;
              this.setCpnAuth(cpnAuthList);
            }

            //선물, 코인 사용여부
            if (response.data.futUseYn != undefined) {
              this.futUseYn = response.data.futUseYn;
            }
            if (response.data.coinUseYn != undefined) {
              this.coinUseYn = response.data.coinUseYn;
            }

            if (this.coinUseYn == "Y") {
              //
            } else {
              //코인 사용조건이 없을경우
              this.$alert.alert('코인선물 사용권한이 없습니다.', '알림').then(() => {
                if (this.futUseYn == "N") { //해외 선물권한도 없을경우
                  this.$router.push('/login');
                } else {
                  this.$router.push('/mobile');
                }
              });
            }
          } else {
            this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
            return;
          }
        } else {
          this.$alert.alert('접속하신 도메인정보의 회사정보가 없습니다.\n관리자에게 문의하세요.', '알림');
          return;
        }

      })

      this.selectAccountList();

      //소켓연결시
      if (this.coinPriceReqType == "SCKTP01") {
        this.connectCoinPrice();
      }

      //코인 주문 소켓 서버 연결
      if (this.coinUseYn == "Y") {
        this.connectCoinOms();
      }
    },
    // 코인 시세 socket 연결
    connectCoinPrice(symbolList) {
      if (this.coinPriceReqType == "SCKTP01") {
        window.$store.dispatch('SocketCoinPrice/setConectCoinPrice', { sessionStorage: this.$sessionStorage.get('xDkToken') });
      } else if (this.coinPriceReqType == "SCKTP02" || this.coinPriceReqType == "SCKTP03") {
        window.$store.dispatch('SocketCoinPrice/setPollingCoinPrice', { symCd: this.symbolList[0].name, symbolList: symbolList });
      }
    },
    async selectAccountList() {
      await this.$http
        .get('/api/account/list')
        .then((result) => {
          if (result && result.data !== null) {
            //console.log("account result ", result)

            this.accountList = result.data;

            //첫번째 계좌는 선물계좌로 사용(순번 desc 순서)
            if (result.data.length > 0) {
              for (let item of result.data) {
                //console.log("account item ", item)
                if (item.accDivCd == 'ACCDIV04' && this.account.accId == 0) {
                  this.account = item;
                  this.$sessionStorage.set('account', JSON.stringify(item));
                }

                if (item.accDivCd == 'ACCDIV05' && this.coinAccount.accId == 0) {
                  this.coinAccount = Object.assign({}, item);
                  this.$sessionStorage.set('coinAccount', JSON.stringify(item));
                }
              }
            }
          }
        });
    },
    loadLayout() {
      window.$store
        .dispatch('Layout/load')
        .then(() => {
          this.layout = window.$store.getters['Layout/getLayout']

          EventBus.$emit('Layout:event:loadLayout', this.layout)
        })
    },
    setLayout(layout) {
      //this.orderSetting = layout.orderSetting;
      this.loadCustom();
    },
    loadCustom() {
      this.orderSetting = window.$store.getters['Layout/getOrderSetting']

      //환경설정 추가 필요
    },
    setCpnAuth(cpnAuthList) { //커스텀 권한 설정
      //
    },
    // coin oms socket 연결
    async connectCoinOms() {
      await window.$store.dispatch('SocketCoinOms/setConectCoinOms', { sessionStorage: this.$sessionStorage.get('xDkToken'), subscriptionUID: sessionStorage.getItem('subscriptionUID') })
    },
    async connectMsg() {
      if (!window.$store.getters['SocketMsg/getIsConnectMsg']) {
        await window.$store.dispatch('SocketMsg/setConectMsg', { sessionStorage: this.$sessionStorage.get('xDkToken'), subscriptionUID: sessionStorage.getItem('subscriptionUID') })
      }
    },
    setFutPage() { //해외선물 페이지 이동
      if (this.futUseYn == "Y") {
        window.$loading.show();
        //코인선물 초기화
        window.$store.dispatch('SocketCoinPrice/resetCoinSymbol');
        this.sendCoinOmsLogMessage("모바일 해외선물 클릭");
        setTimeout(() => {
          this.$router.push({ name: 'Mobile Page', params: { instruments: 'global' } });
        }, 1000);
      }
    },
    //종목 조회
    async selectCoinSymbolList() {

      //console.log("this.coinSymbolList ", this.coinSymbolList, window.$store.getters['Symbol/getCoinSymbolList'].length);
      await window.$store.dispatch('Symbol/coinLoad'); //코인 종목 조회

      this.coinSymbolList = window.$store.getters['Symbol/getCoinSymbolList'];

      await window.$store.dispatch('Symbol/coinInfoLoad'); //코인 추가정보 조회



      //console.log("coinSymbolList", this.coinSymbolList[0].name)

      if (this.coinSymbolList != undefined && this.coinSymbolList.length > 0) {
        //console.log("this.coinSymbolList ", this.coinSymbolList);

        let prepList = [];

        //종목선택 div 목록 생성
        for (let item of this.coinSymbolList) {
          const coinSymbol = { base_name: item.base_name[0], symCd: item.name, symbolno: item.symbolno, description: item.description, full_name: item.full_name, lstPrc: 0, chgRate: 0, fundRate: 0, coinSymbol: item };
          prepList.push(coinSymbol);
        }

        //종목 선택 목록
        this.coinSymbolPrepList = prepList;

        //첫번째 종목 선택       
        //this.coinSymbol = this.coinSymbolList[0];
        if (!window.$store.getters['SocketCoinPrice/getIsConnectCoinPrice']) {
          window.$store.watch(() => window.$store.getters['SocketCoinPrice/getIsConnectCoinPrice'], socketPrice => {
            setTimeout(() => {
              window.$store.dispatch('SocketCoinPrice/setSelectCoinSymbol', this.coinSymbolList[0]);
            }, 500);
          })
        } else {
          setTimeout(() => {
            window.$store.dispatch('SocketCoinPrice/setSelectCoinSymbol', this.coinSymbolList[0]);
          }, 500);
        }
      }
    },
    selectCoinSymbol(newVal) {
      console.log("selectCoinSymbol", newVal)
      window.$store.dispatch('SocketCoinPrice/setSelectCoinSymbol', newVal.coinSymbol);
      //this.showCoinSymbolPrep = !this.showCoinSymbolPrep;
    },
    updateCoh(newPrice) {
      let asksList = [];
      let askSum = 0;
      let revsAskList = [];

      //ask
      if (newPrice.asks != undefined) {
        //sum값 계산, 배열 순번이 뒤로 갈수록 합산
        newPrice.asks.forEach(item => {
          askSum += Number(item[1]);
          item[2] = askSum.toFixed(3);
        })

        //리스트 리버스
        for (let i = newPrice.asks.length - 1; i >= 0; i--) {
          let item = newPrice.asks[i];
          asksList.push({ price: item[0], size: item[1], sum: item[2] });
        }

        this.asksList = asksList;
      }

      //bid
      if (newPrice.bids != undefined) {
        let bidsList = [];
        let bidSum = 0;
        newPrice.bids.forEach(item => {
          bidSum += Number(item[1]);
          bidsList.push({ price: item[0], size: item[1], sum: bidSum.toFixed(3) });
        });

        this.bidsList = bidsList;
      }
    },
    updateSubscribeOnCoinPrice() {
      //소켓 구독 종목 업데이트
      this.coinSymbolCdList = [];

      //잔고목록
      this.rtPosCoinSymbolList.forEach(data => {
        if (data.balQty > 0) {
          let chkYn = "N";
          for (let item of this.coinSymbolCdList) {

            if (item == data.symCd) {
              chkYn = "Y"
            }
          }

          if (chkYn == "N")
            this.coinSymbolCdList.push(data.symCd);

        }
      });

      //선택 종목
      const selCoinSymbol = window.$store.getters['SocketCoinPrice/getSelectCoinSymbol'];

      //console.log("selCoinSymbol", selCoinSymbol)

      //팝업 선택 종목
      let popupSelCoinSymbolList = [];
      //stock_trader_popup1SelSymbol

      if (JSON.parse(this.$sessionStorage.get('popup1SelCoinSymbol')) != null) {
        const popupCoinSymbol = JSON.parse(this.$sessionStorage.get('popup1SelCoinSymbol'));
        popupSelCoinSymbolList.push(popupCoinSymbol.name);
      }

      if (this.$sessionStorage.get('popup2SelCoinSymbol') != null) {
        const popupCoinSymbol = JSON.parse(this.$sessionStorage.get('popup2SelCoinSymbol'));
        popupSelCoinSymbolList.push(popupCoinSymbol.name);
      }

      //팝업 종목 추가
      popupSelCoinSymbolList.forEach(item => {
        let chkYn = "N";
        this.rtPosCoinSymbolList.forEach(data => {
          if (data == item) {
            chkYn = "Y";
            return;
          }
        });

        if (chkYn == "N") {
          this.coinSymbolCdList.push(item);
        }
      });

      if (selCoinSymbol != undefined && selCoinSymbol.name) {
        let chkYn = "N";
        this.coinSymbolCdList.forEach(item => {
          if (selCoinSymbol.name == item) {
            chkYn = "Y";
            return;
          }
        })
        if (chkYn == "N") {
          this.coinSymbolCdList.push(selCoinSymbol.name);
        }
      }

      //console.log("소켓 구독 수정", this.coinSymbolCdList)

      this.subscribeOnCoinPriceModify(this.coinSymbolCdList, popupSelCoinSymbolList);
    },
    subscribeOnCoinPriceModify(coinSymbolList, popupSelCoinSymbolList) {
      if (this.coinPriceReqType == "SCKTP01") {
        window.$store.dispatch('SocketCoinPrice/setUpdateSubscribeOnPriceList', { coinSymbolList, popupSelCoinSymbolList });
      } else if (this.coinPriceReqType == "SCKTP02" || this.coinPriceReqType == "SCKTP03") {
        window.$store.dispatch('SocketCoinPrice/setPollingPrice', { symCd: this.coinSymbol.name, coinSymbolList: coinSymbolList, popupSelCoinSymbolList: popupSelCoinSymbolList });
      }
    },
    async setStockInfo(newSymbol) {

      //const rStock = await window.$http.get('chart/datafeed/stockdata', { params: {  } } )
      //console.log("rStock search >>> ", newSymbol)
      const rStock = await window.$http.get('apisisecoin/getCoinLastPrice', { params: { symCd: newSymbol.name } })

      if (rStock != null) {
        //console.log("rStock >>>>> ", rStock)
        if (rStock.data.coc != null) {
          this.coc = rStock.data.coc
        }
        if (rStock.data.cot != null) {
          this.cot = rStock.data.coc
        }
        if (rStock.data.com != null) {
          this.com = rStock.data.com
        }

        if (rStock.data.coh != null) {
          this.updateCoh(rStock.data.coh);
        }
      }
    },
    //코인 유저데이터 조회
    searchCoinUsrData() {
      window.$store.dispatch('SocketCoinOms/selectUsrData', { accId: this.coinAccount.accId, symCd: this.coinSymbol.name, symNo: this.coinSymbol.symbolno, lv: this.coinLeverage, ordType: 'coin' });
    },
    //코인 포지션/마진모드/레버리지 조회
    selectCoinUsrPos() {
      window.$store.dispatch('SocketCoinOms/selectUsrPos', { accNo: this.coinAccount.accNo, symCd: this.coinSymbol.name });
      this.searchCoinUsrData();
    },
    positionChk() {
      //포지션 체크
      const positionList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
      if (positionList != undefined && positionList.length > 0) {
        let balQty = 0;
        let ordQty = 0;
        let mitQty = 0;
        positionList.forEach(data => {
          if (data.symCd == this.coinSymbol.name && data.ordType == "POS") {
            balQty += data.balQty;
          } else if (data.symCd == this.coinSymbol.name && data.ordType == "ORD") {
            ordQty += data.balQty;
          } else if (data.symCd == this.coinSymbol.name && data.ordType == "ST") {
            mitQty += data.balQty;
          }
        });

        if (balQty > 0 || ordQty > 0 || mitQty > 0) {
          this.marginModeFlg = false;
          this.cposFlg = false;
          this.leverageModeFlg = false;
        } else {
          this.marginModeFlg = true;
          this.cposFlg = true;
          this.leverageModeFlg = true;
        }
      }
    },
    //레버리지 팝업
    changeLeverage() {
      this.positionChk();

      this.popCoinLeverage = Number(this.coinLeverage);
      this.beforeLeverage = Number(this.coinLeverage);
      this.maxLv = this.tierList[0].maxLv;
      this.showLeverageSheet = true;
    },
    coinLeverageUp() {
      if (this.popCoinLeverage < this.maxLv) {
        this.popCoinLeverage++;
      }
      this.chkMaxOrdAmt(this.popCoinLeverage);
    },
    coinLeverageDown() {
      this.positionChk();
      //포지션이 있고, 격리이면 
      if (this.leverageModeFlg == false && this.marginMode == "2" && this.beforeLeverage >= this.popCoinLeverage) {
        return;
      }
      if (this.popCoinLeverage > 1) {
        this.popCoinLeverage--;
      }
      this.chkMaxOrdAmt(this.popCoinLeverage);
    },
    updateSliderLeverage(newVal) {
      console.log("newVal?", newVal, this.beforeLeverage, this.leverageModeFlg);
      this.popCoinLeverage = Number(newVal);
      this.chkMaxOrdAmt(newVal);
    },
    chkMaxOrdAmt(newVal) {
      console.log(newVal)
      this.tierList.forEach(tl => {
        if (tl.maxLv >= newVal) {
          this.maxOrdAmt = Utils.numberComma(tl.maxOrdAmt);
        }
      })
    },
    async selectCoinLeverage() {
      //변경불가 조건 체크필요
      const data = { accNo: this.coinAccount.accNo, symCd: this.coinSymbol.name, ordType: "coin" };

      await this.$lhttp.get('/api/order/rtPosOrdChk', { params: data }).then((response) => {
        if (response.code == 200) {
          if (response.data == null) {
            this.$alert.alert('코인선물 잔고/미체결 조회중 에러가 발생했습니다.', '안내');
            return;
          }
          // else if (response.data.coinOrdQty != undefined && response.data.coinOrdQty > 0){
          //   this.$alert.alert('코인선물 잔고/미체결 내역이 있으면 마진 모드 변경 불가합니다.', '안내');
          //   this.cpos = response.data.cpos;
          //   return;
          // }

          //포지션 모드 저장
          const saveData = { accNo: this.coinAccount.accNo, symNo: this.coinSymbol.symbolno, leverage: this.popCoinLeverage };
          this.$lhttp.post('/api/order/usrPos/save', saveData).then((res) => {
            this.selectCoinUsrPos();
            this.showLeverageSheet = false;
          });

        }
      });


    },
    //포지션모드 팝업
    changePositionMode() {
      this.positionChk();
      this.selCpos = this.cpos;
      this.showTradeModeSheet = true;
    },
    //포지션모드 변경
    chgPosition(type) {
      this.positionChk();
      if (this.cposFlg == true) {
        this.selCpos = type;
      }
    },
    //포지션모드 저장
    async savePositionMode() {
      const data = { accNo: this.coinAccount.accNo, symCd: this.coinSymbol.name, ordType: "coin" };

      await this.$lhttp.get('/api/order/rtPosOrdChk', { params: data }).then((response) => {
        console.log("response", response)
        if (response.code == 200) {
          if (response.data == null) {
            this.$alert.alert('코인선물 잔고/미체결 조회중 에러가 발생했습니다.', '안내');
            return;
          } else if (response.data.coinOrdQty != undefined && response.data.coinOrdQty > 0) {
            this.$alert.alert('코인선물 잔고/미체결 내역이 있으면 포지션 모드 변경 불가합니다.', '안내');
            this.cpos = response.data.cpos;
            return;
          }

          //포지션 모드 저장
          const saveData = { accNo: this.coinAccount.accNo, symNo: this.coinSymbol.symbolno, cpos: this.selCpos };
          console.log("saveData >>> ", saveData)
          this.$lhttp.post('/api/order/usrPos/save', saveData).then((res) => {
            this.selectCoinUsrPos();
            this.showTradeModeSheet = false;
          });

        }
      });


    },
    //마진모드 팝업
    chageMarginMode() {
      this.positionChk();

      this.selMarginMode = this.marginMode;

      this.showMarginSheet = true;
    },
    //마진모드 select 변경
    chgMarginMode(type) {
      console.log("marginMode", type)
      //변경
      this.positionChk();
      if (this.marginModeFlg == true) {
        this.selMarginMode = type;
      }
    },
    async selectMarginMode() {

      const data = { accNo: this.coinAccount.accNo, symCd: this.coinSymbol.name, ordType: "coin" };

      await this.$lhttp.get('/api/order/rtPosOrdChk', { params: data }).then((response) => {
        console.log("response", response)
        if (response.code == 200) {
          if (response.data == null) {
            this.$alert.alert('코인선물 잔고/미체결 조회중 에러가 발생했습니다.', '안내');
            return;
          } else if (response.data.coinOrdQty != undefined && response.data.coinOrdQty > 0) {
            this.$alert.alert('코인선물 잔고/미체결 내역이 있으면 마진 모드 변경 불가합니다.', '안내');
            this.marginMode = response.data.marginMode;
            return;
          }

          //포지션 모드 저장
          const saveData = { accNo: this.coinAccount.accNo, symNo: this.coinSymbol.symbolno, marginMode: this.selMarginMode };
          console.log("saveData >>> ", saveData)
          this.$lhttp.post('/api/order/usrPos/save', saveData).then((res) => {
            this.selectCoinUsrPos();
            this.showMarginSheet = false;
          });

        }
      });
    },
    //호가 선택
    selectRowItem(item) {
      if (this.stdOrdTpCd == "1") {
        this.stdOrdPrc = item.price;
      }
      this.setPriceCost();
      this.setCostUsdt();
    },
    //orderbook bg
    calculateWidth(sum) {
      const percentage = (sum / this.askBidMax) * 100;
      return `${percentage}%`; // 결과를 'px', '%' 등 단위로 반환
    },
    //햇지 진입/청산 (oepn close) 설정
    changeOpenClose(type) {
      this.openCloseMode = type;

      let message = "모바일 코인선물 주문창";
      if (type == "1") {
        message += " 진입 클릭";
      } else {
        message += " 청산 클릭";
      }
      this.sendCoinOmsLogMessage(message);
    },
    onSliderUpdate(value) {
      this.sliderValue = Number(value);
      this.stdOrdQty = `${value}%`;
    },
    onFocus() {
      this.onSliderUpdate(0);
      this.stdOrdQty = '';
    },
    // 가격 입력
    setStdOrdPrc() {
      this.setPriceCost();
      this.setCostUsdt();
    },
    // 가격 수량 Cost 계산
    setPriceCost() {
      if (this.stdOrdTpCd == "1") { //지정가
        if (this.stdOrdPrc != null && this.stdOrdPrc != '') {
          let sellPrc = this.stdOrdPrc;
          let buyPrc = this.stdOrdPrc;

          //시세보다 지정가가 작으면 매도가격을 매수1호가로 지정
          if (this.coinNowPrice.curPr > sellPrc) {
            sellPrc = this.coinCohPrice.bids[0][0];
          }

          //시세보다 지정가가 크면 매수가격을 매도1호가로 지정
          if (this.coinNowPrice.curPr < buyPrc) {
            buyPrc = this.coinCohPrice.asks[0][0];
          }

          if (this.stdOrdType == "USDT") { //usdt
            this.coinBuyQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn.ableUsdt, this.coinLeverage, buyPrc, this.coinSymbolInfo.lmtCmsR, this.coinSymbolInfo.costDotCnt);
            this.coinSellQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn.ableUsdt, this.coinLeverage, sellPrc, this.coinSymbolInfo.lmtCmsR, this.coinSymbolInfo.costDotCnt);
          } else { //코인
            this.coinBuyQty = Utils.getCoinOrdQty(this.coinDailyPlMn.ableUsdt, this.coinLeverage, buyPrc, this.coinSymbolInfo.lmtCmsR, this.coinSymbolInfo.costDotCnt);
            this.coinSellQty = Utils.getCoinOrdQty(this.coinDailyPlMn.ableUsdt, this.coinLeverage, sellPrc, this.coinSymbolInfo.lmtCmsR, this.coinSymbolInfo.costDotCnt);
          }
        } else {
          this.coinBuyUsdt = 0.00;
          this.coinSellUsdt = 0.00;
          if (this.stdOrdType == "USDT") { //usdt
            this.coinBuyQty = Utils.toFixInit(0, 2);
            this.coinSellQty = Utils.toFixInit(0, 2);
          } else { //코인
            this.coinBuyQty = Utils.toFixInit(0, this.coinSymbolInfo.costDotCnt);
            this.coinSellQty = Utils.toFixInit(0, this.coinSymbolInfo.costDotCnt);
          }
        }
      } else { //시장가
        if (this.coinCohPrice != undefined) {
          let sellPrc = this.coinCohPrice.bids[0][0]; //매도가격을 매수1호가로 지정
          let buyPrc = this.coinCohPrice.asks[0][0]; //매수가격을 매도1호가로 지정

          if (this.stdOrdType == "USDT") { //usdt
            this.coinBuyQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn.ableUsdt, this.coinLeverage, buyPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);
            this.coinSellQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn.ableUsdt, this.coinLeverage, sellPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);
          } else { //코인
            this.coinBuyQty = Utils.getCoinOrdQty(this.coinDailyPlMn.ableUsdt, this.coinLeverage, buyPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);
            this.coinSellQty = Utils.getCoinOrdQty(this.coinDailyPlMn.ableUsdt, this.coinLeverage, sellPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);
          }
        } else {
          this.coinBuyUsdt = 0.00;
          this.coinSellUsdt = 0.00;
          if (this.stdOrdType == "USDT") { //usdt
            this.coinBuyQty = Utils.toFixInit(0, 2);
            this.coinSellQty = Utils.toFixInit(0, 2);
          } else { //코인
            this.coinBuyQty = Utils.toFixInit(0, this.coinSymbolInfo.costDotCnt);
            this.coinSellQty = Utils.toFixInit(0, this.coinSymbolInfo.costDotCnt);
          }
        }
      }
    },
    //매도/매수 cost 구하기
    setCostUsdt() {
      let sellCostUsdt = 0.00;
      let buyCostUsdt = 0.00;
      //수량에 USDT로 입력할 경우 BTC 수량으로 계산
      const minOrdQty = this.coinSymbolInfo.minTrdAmt;

      let sellStdQty = this.stdOrdQty;
      let buyStdQty = this.stdOrdQty;

      if (this.stdOrdTpCd == "1") { //지정가
        if (this.stdOrdPrc != undefined && this.stdOrdPrc != null && this.stdOrdPrc != "" && this.stdOrdQty != undefined && this.stdOrdQty != null && this.stdOrdQty != "") {
          let sellPrc = this.stdOrdPrc;
          let buyPrc = this.stdOrdPrc;

          if (this.stdOrdQty.toString().indexOf("%") > -1) {
            if (this.stdOrdType == "USDT") {
              let maxSellQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn.ableUsdt, this.coinLeverage, buyPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);
              let maxBuyQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn.ableUsdt, this.coinLeverage, sellPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);

              sellStdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
              buyStdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);
            } else { //코인
              let maxSellQty = Utils.getCoinOrdQty(this.coinDailyPlMn.ableUsdt, this.coinLeverage, sellPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);
              let maxBuyQty = Utils.getCoinOrdQty(this.coinDailyPlMn.ableUsdt, this.coinLeverage, buyPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);
              sellStdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
              buyStdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);
            }
          }

          //시세보다 지정가가 작으면 매도가격을 매수1호가로 지정
          if (this.coinNowPrice.curPr > sellPrc) {
            sellPrc = this.coinCohPrice.bids[0][0];
          }

          //시세보다 지정가가 크면 매수가격을 매도1호가로 지정
          if (this.coinNowPrice.curPr < buyPrc) {
            buyPrc = this.coinCohPrice.asks[0][0];
          }

          sellCostUsdt = Utils.coinUsdtCost(sellStdQty, sellPrc, minOrdQty, this.stdOrdType, this.coinSymbolInfo.costDotCnt);
          buyCostUsdt = Utils.coinUsdtCost(buyStdQty, buyPrc, minOrdQty, this.stdOrdType, this.coinSymbolInfo.costDotCnt);
        }
      } else { //시장가
        if (this.stdOrdQty != undefined && this.stdOrdQty != null && this.stdOrdQty != "") {
          let sellPrc = this.coinCohPrice.bids[0][0]; //매도가격을 매수1호가로 지정
          let buyPrc = this.coinCohPrice.asks[0][0]; //매수가격을 매도1호가로 지정

          if (this.stdOrdQty.toString().indexOf("%") > -1) {
            if (this.stdOrdType == "USDT") {
              let maxSellQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn.ableUsdt, this.coinLeverage, buyPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);
              let maxBuyQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn.ableUsdt, this.coinLeverage, sellPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);

              sellStdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
              buyStdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);
            } else { //코인
              let maxSellQty = Utils.getCoinOrdQty(this.coinDailyPlMn.ableUsdt, this.coinLeverage, sellPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);
              let maxBuyQty = Utils.getCoinOrdQty(this.coinDailyPlMn.ableUsdt, this.coinLeverage, buyPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);

              sellStdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
              buyStdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);
            }
          }

          sellCostUsdt = Utils.coinUsdtCost(sellStdQty, sellPrc, minOrdQty, this.stdOrdType, this.coinSymbolInfo.costDotCnt);
          buyCostUsdt = Utils.coinUsdtCost(buyStdQty, buyPrc, minOrdQty, this.stdOrdType, this.coinSymbolInfo.costDotCnt);
        }
      }

      this.buyCostUsdt = buyCostUsdt;
      this.sellCostUsdt = sellCostUsdt;
    },
    //지정가, 시장가 설정
    changeOrdTpCd(ordTpCd) {
      this.stdOrdTpCd = ordTpCd.cd;
      this.stdOrdPrc = '';
      this.setPriceCost();
      this.setCostUsdt();

      let message = "코인선물 주문창";
      if (ordTpCd == "1") {
        message += " 지정가 클릭";
      } else {
        message += " 시장가 클릭";
      }
      this.sendCoinOmsLogMessage(message);
    },
    //금액/수량 초기화
    resetUsdt() {
      this.stdOrdPrc = '';
      this.stdOrdQty = '';
      this.stdOrdTpPrc = '';
      this.stdOrdSlPrc = '';
      this.onSliderUpdate(0);
      this.stdOrdQty = '';
    },
    //가격/수량 변경
    changeStdOrdType(chgVal) {
      this.stdOrdPrc = '';
      this.stdOrdType = chgVal.name;
      this.onSliderUpdate(0);
      this.stdOrdQty = '';
    },
    //익절 콤보 설정
    changeTpType(type) {
      this.stdOrdTpType = type;
    },
    //손절 콤보 설정
    changeSlType(type) {
      this.stdOrdSlType = type;
    },
    //익절/손절 체크
    setTpSlFlag(e) {
      let message = "코인선물 주문창 익절/손절";
      if (e.target.checked) {
        message += " 체크 클릭";
      } else {
        message += " 체크 해제 클릭";
      }
      this.sendCoinOmsLogMessage(message);
    },
    stdCoinSellBuy(type) {
      if (!this.stdOrdTpCd) {
        window.$alert.alert('지정가/시장가를 선택하세요.', '알림');
        return;
      } else if (this.stdOrdTpCd == "1" && (!this.stdOrdPrc || this.stdOrdPrc <= 0)) {
        window.$alert.alert('가격을 입력 해주세요.', '알림');
        return;
      }

      if (!this.stdOrdQty || this.stdOrdQty <= 0) {
        window.$alert.alert('수량을 입력 해주세요.', '알림');
        return;
      }

      // if ((type == "buy" && this.stdOrdQty == 0) || (type == "sell" && this.stdOrdQty == 0)){
      //   window.$alert.alert('주문가능 수량이 부족합니다.', '알림');
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnStdCoinFlag) {
        return;
      }

      let ordSdCd = "0";
      let message = "모바일 코인선물 주문창 주문";
      if (type == "sell") {
        ordSdCd = "1";
        message += " 매도";
      } else if (type == "buy") {
        ordSdCd = "2";
        message += " 매수";
      }

      message += " " + this.coinSymbol.name;

      let ordTpCd = this.stdOrdTpCd;
      let apiCode = "";
      let stTpCd = "2";
      let ordPrc = 0;
      let ordQty = 0;
      let opnCls = "1"; //진입 청산 구분
      let minOrdQty = this.coinSymbolInfo.minTrdAmt;

      //console.log("minOrdQty >>>> ", minOrdQty)

      if (this.openCloseMode != null) {
        opnCls = this.openCloseMode;
      }

      //수량에 USDT로 입력할 경우 BTC 수량으로 계산
      let stdOrdQty = this.stdOrdQty;

      let stdOrdPrc = this.stdOrdPrc;

      if (this.stdOrdTpCd == "2") { //시장가
        stdOrdPrc = this.coinNowPrice.curPr;
      }

      if (this.stdOrdQty.toString().indexOf("%") > -1) {
        if (this.stdOrdType == "USDT") {
          let maxSellQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn.ableUsdt, this.coinLeverage, stdOrdPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);
          let maxBuyQty = Utils.getCoinOrdUsdt(this.coinDailyPlMn.ableUsdt, this.coinLeverage, stdOrdPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);

          if (type == "sell") {
            stdOrdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
          } else if (type == "buy") {
            stdOrdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);
          }

        } else { //코인
          let maxSellQty = Utils.getCoinOrdQty(this.coinDailyPlMn.ableUsdt, this.coinLeverage, stdOrdPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);
          let maxBuyQty = Utils.getCoinOrdQty(this.coinDailyPlMn.ableUsdt, this.coinLeverage, stdOrdPrc, this.coinSymbolInfo.mktCmsR, this.coinSymbolInfo.costDotCnt);

          if (type == "sell") {
            stdOrdQty = Utils.coinUsdtPercent(maxSellQty, this.stdOrdQty, this.stdOrdType);
          } else if (type == "buy") {
            stdOrdQty = Utils.coinUsdtPercent(maxBuyQty, this.stdOrdQty, this.stdOrdType);
          }
        }
      }

      if (this.stdOrdType == "USDT") {
        ordQty = Utils.coinUsdtToBtc(stdOrdQty, this.coinNowPrice.curPr, minOrdQty);
      } else {
        ordQty = stdOrdQty;
      }

      //BTC 최소 수량 가능한지 체크
      if (ordQty < minOrdQty) {
        //console.log("ordQty, minOrdQty ", ordQty, minOrdQty)
        window.$alert.alert('주문가능 최소 수량을 확인 해주세요.', '알림');
        return;
      }


      if (this.stdOrdTpCd == "2") { //시장가
        apiCode = "COMS0001";
        message += "/시장가";
      } else if (this.stdOrdTpCd == "1") { //지정가
        apiCode = "COMS0001";
        ordPrc = this.stdOrdPrc;
        message += "/지정가";
      } else if (this.stdOrdTpCd == "3") { //MIT, 코인 stop은 아직 없음
        apiCode = "COMS0004";
        ordPrc = this.stdOrdPrc;
        message += "/MIT";

        //stTpCd : 스탑유형코드 // '1' 위방향 '2' 아래방향 (주문가격이 현재가보다 클경우 1 아닌경우 2)
        if (Number(this.coinNowPrice.curPr) == Number(this.stdOrdPrc)) {
          this.$notify({ group: 'notification', clean: true })

          this.$notify({
            group: 'notification',
            title: 'FAIL',
            text: '[주문거부] 현재가에 MIT주문이 불가능합니다.'
          });
          return;
        } else if (this.coinNowPrice.curPr < Number(this.stdOrdPrc)) {
          stTpCd = "1";
        }
      }

      message += "/수량(" + this.stdOrdType + ") ";
      message += this.stdOrdQty + "/가격 " + ordPrc;

      this.btnStdCoinFlag = false;

      let slTpCd1 = "";
      let slTpCd2 = "";
      let stdOrdTpType = "";
      let stdOrdSlType = "";
      if (this.showTpSlFlag) { //익절/손절 체크시
        //익절 가격이 있으면
        if (this.stdOrdTpPrc != undefined && this.stdOrdTpPrc != null && this.stdOrdTpPrc != "") {
          slTpCd1 = "1";
          stdOrdTpType = this.stdOrdTpType;
        }
        //손절 가격이 있으면
        if (this.stdOrdTpPrc != undefined && this.stdOrdTpPrc != null && this.stdOrdTpPrc != "") {
          slTpCd2 = "2";
          stdOrdSlType = this.stdOrdSlType;
        }
      }

      const order = {
        usrKey: Utils.getUuidv4(),
        accId: this.coinAccount.accId,
        symCd: this.coinSymbol.name,
        symNo: this.coinSymbol.symbolno,
        trdDt: '',
        ordId: 0,
        orgOrdNo: 0,
        ordSdCd: ordSdCd,
        ordTpCd: ordTpCd,
        ordPrc: ordPrc,
        ordQty: ordQty,
        repReqQty: 0,
        cxlReqQty: 0,
        stTpCd: stTpCd,
        //accPass : this.getUsrAccPass,
        lv: this.coinLeverage,
        opnCls: opnCls,
        slTpCd1: slTpCd1, //익절 여부
        prcTpCd1: stdOrdTpType, //1.Last, 2.Mark
        slPrc1: this.stdOrdTpPrc, // 가격
        slTpCd2: slTpCd2, //손절 여부
        prcTpCd2: stdOrdSlType, //1.Last, 2.Mark
        slPrc2: this.stdOrdSlPrc, // 가격
      }

      this.sendCoinOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert) {
        const orderData = { apiCode: apiCode, order: order, symbol: this.coinSymbol, qty: this.ordStdQty, price: Number(ordPrc), message: message };
        this.$modal.show("modalConfirmOrder", orderData);
      } else {
        this.sendCoinOmsMessage(apiCode, order);
      }

      //0.3초후 버튼 누를 수 있게 설정
      if (!this.btnStdCoinFlag) {
        setTimeout(() => {
          this.btnStdCoinFlag = true;
        }, 300);
      }
    },
    completeCoinOrder(res) {
      if (res != undefined && res.notify != undefined) {
        this.notifyAlert(res.notify)
      }
      const apiCode = res.apiCode;
      const body = res.body;

      //코인 일반주문 버튼 활성화
      this.btnStdCoinFlag = true;

      if (res.apiCode == "COMS9997") {
        console.log("COMS9997 >>>> ", body.serverType, body);
      }

      //포지션 조회
      if (this.coinAccount.accId && this.coinAccount.accId > 0) {
        this.searchCoinUsrData();
      }
    },
    procFailMessage(res) {
      let body = res.body;

      //기존 알림창 닫기
      this.$notify({ group: 'notification', clean: true })

      this.$notify({
        group: 'notification',
        title: 'FAIL',
        text: body.message
      })
    },
    notifyAlert(msgInfo) { //주문 알림 메시지
      //console.log("msgInfo", msgInfo)

      if (msgInfo != null) {
        if (this.orderSetting != null && this.orderSetting.soundAlert) {
          // 사운드 실행
          if (msgInfo.apiCode == 'OMS0001') { //주문완료
            if (msgInfo.body.ordSdCd == '1') {
              this.soundOn('/SELL_COMP.WAV')
            } else {
              this.soundOn('/BUY_COMP.WAV')
            }
          } else if (msgInfo.apiCode == 'OMS0002') {  //취소주문
            this.soundOn('/CANCEL_COMP.WAV')
          } else if (msgInfo.apiCode == 'OMS0003') {  //정정주문  
            this.soundOn('/MODIFY_COMP.WAV')
          } else if (msgInfo.apiCode == 'OMS0005') {  //MIT취소완료
            this.soundOn('/CANCEL_COMP.WAV')
          } else if (msgInfo.apiCode == 'OMS0006') {  //MIT정정완료
            this.soundOn('/MODIFY_COMP.WAV')
          } else if (msgInfo.apiCode == 'OMS0010') { //종목선택취소 
            this.soundOn('/CANCEL_COMP.WAV')
          } else if (msgInfo.apiCode == 'OMS0011') { //MIT종목선택취소
            this.soundOn('/CANCEL_COMP.WAV')
          } else if (msgInfo.apiCode == 'OMS0012') { //일괄취소
            this.soundOn('/CANCEL_COMP.WAV')
          }
        }

        if (this.orderSetting != null && this.orderSetting.messageAlert) {


          this.$notify({ group: msgInfo.group, clean: true })

          this.$notify(msgInfo)
        }
      }
    },
    soundOn(file) {
      //if (this.orderSetting != null && this.orderSetting.soundAlert){
      new Audio("/wav" + file).play();
      //}
    },
    //포지션, 미체결 목록
    async searchCoinPosOrdStList() {
      if (!this.coinAccount.accId) return;

      this.selectList = [];

      const dataMap = await window.$http.get('apisisecoin/getCocData', { params: {} });

      const cocData = dataMap.data.cocDataList;
      const comData = dataMap.data.comDataList;


      if (this.coinPosOrdStList.length == 0) {
        this.coinPosOrdStList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList']
      }

      if (this.coinPosOrdStList) {
        let rowPosData = [];
        let rowOrdSlData = [];
        this.coinPosOrdStList.forEach((data) => {
          //coc 코인 시세
          for (let coc in cocData) {
            if (coc == data.symCd) {
              data.nowPrice = cocData[coc].curPr;

            }
            if (this.cocMap.get(coc) == undefined || this.cocMap.get(coc).curPr == undefined) {
              this.cocMap.set(coc, cocData[coc]);
            }
          }

          //com 시장평균가?
          for (let com in comData) {
            if (com == data.symCd) {
              data.markPr = comData[com].markPr;
            }
            if (this.comMap.get(com) == undefined || this.comMap.get(com).markPr == undefined) {
              this.comMap.set(com, comData[com]);
            }
          }

          data.valPl = '';
          data.valRoi = 0;
          data.nowPrice = 0;
          if (data.balQty > 0 && data.ordType == "POS") {
            rowPosData.push(data);
          } else if (data.ordType == "ORD" || data.ordType == "SL") {

            let type = "지정가";
            if (data.ordType == 'SL') {
              if (data.slTpCd == "1") {
                type = "익절 시장가";
              } else if (data.slTpCd == "2") {
                type = "손절 시장가";
              }
            }

            data.typeNm = type;
            rowOrdSlData.push(data);
          }
        });



        this.coinPosList = rowPosData;

        this.coinOrdSlList = rowOrdSlData;

        this.setGridPrice();
      } else {
        //잔고 목록이 없으면 평가손익 초기화
        this.setGridPrice();
        this.selectList = [];
      }
    },
    //포지션 평가손익
    setGridPrice() {
      let totData = { totalValPl: 0, totalQty: 0, totalLossCut: 0, crossTotalValPl: 0, isolratedTotalValPl: 0 };

      if (this.coinPosList != null && this.coinPosList.length > 0) {
        let totalValPl = 0;
        let totalQty = 0;
        let crossTotalValPl = 0;
        let isolratedTotalValPl = 0;

        //let usdExr = 0;
        const coinDailyPlMn = window.$store.getters['SocketCoinOms/getCoinDailyPlMn'];
        //if (coinDailyPlMn && coinDailyPlMn.usdExR) usdExr = coinDailyPlMn.usdExR;

        this.coinPosList.forEach(data => {
          if (data.balQty > 0 && data.ordType == "POS") {
            totalQty += Number(data.balQty);

            let symbolData = null;

            this.coinSymbolList.forEach(item => {
              if (item.name == data.symCd) {
                symbolData = item;
                return;
              }
            });

            //청산가 시세
            if (this.comMap.get(data.symCd) != null) {
              data.markPr = this.comMap.get(data.symCd).markPr.toFixed(Utils.tikDotSz(symbolData));
              //Number(pnl).toFixed(tikDotSz(symbolData));
            }

            //let res = this.coinSymbolCalPrc(data, this.cocMap.get(data.symCd), this.coinSymbolList, usdExr, usdExHkd, hkdExR, data.lv);
            // 계산 공통사용변경
            // data - 그리드 1개 row data
            // cocMap
            // coinSymbolList         
            // lv 레버리지
            let res = Utils.coinSymbolCalPrc(data, this.cocMap.get(data.symCd), this.coinSymbolList, data.lv);

            data.valPl = Number(data.valPl).toFixed(Utils.tikDotSz(symbolData));
            data.valRoi = Number(data.valRoi).toFixed(Utils.tikDotSz(symbolData));
            data.mrgAmt = Number(data.mrgAmt).toFixed(Utils.tikDotSz(symbolData));
            data.execPrc = Number(data.execPrc).toFixed(Utils.tikDotSz(symbolData));

            //data = res.data; //데이터가 갱신 안되면 주석해제
            totalValPl = Utils.decimalAdd(totalValPl, res.data.valPl);

            // data.valPl = pnl;
            // data.valRoi = roi;
            //cross
            if (data.cposTpCd == "1") {
              crossTotalValPl = Utils.decimalAdd(crossTotalValPl, res.data.valPl);
            } else { //isolated
              isolratedTotalValPl = Utils.decimalAdd(isolratedTotalValPl, res.data.valPl);
            }
          }
        });

        totData.totalQty = totalQty;
        totData.totalValPl = totalValPl;
        totData.crossTotalValPl = crossTotalValPl;
        totData.isolratedTotalValPl = isolratedTotalValPl;
      } else {
        //this.$emit("updateTotalValPl", 0);
      }

      this.coinTotalValPl = totData;

      //this.$emit("updateTotalValPl", totData);
    },
    NumberComma(value) {
      if (value == undefined) {
        return 0;
      } else {
        return Utils.numberComma(value.toFixed(4));
      }
    },

    chgMargin(data) {
      this.showMarginEditSheet = true;
    },
    //자산이동
    coinTrasnfer() {
      this.showModal('modalDepositWithdraw');
    },
    //미체결 주문 정정 팝업
    modifyOrderPop(data) {
      //초기화
      this.modOrdPrc = '';
      this.modOrdQty = '';

      //선택 데이터
      this.modOrdInfo = data;
      this.modOrdPrc = data.execPrc;
      this.modOrdQty = data.balQty;

      for (let item of this.coinSymbolInfoList) {
        if (item.symNo == this.modOrdInfo.symNo) {
          this.modOrdType = item.artcCd;
          break;
        }
      }

      this.showEditOrderSheet = true;
    },
    cancelModifyOrder() {
      console.log("data", this.modOrdInfo)
      let message = "모바일 코인 체결리스트 미체결 정정 취소 클릭 " + this.modOrdInfo.symCd;
      this.sendCoinOmsLogMessage(message);
      this.showEditOrderSheet = false;
    },
    modifyOrder() {
      console.log("modifyOrder", this.modOrdInfo)
      if (!this.modOrdPrc || this.modOrdPrc <= 0) {
        window.$alert.alert('가격을 입력 해주세요.', '알림');
        return;
      }

      if (!this.modOrdQty || this.modOrdQty <= 0) {
        window.$alert.alert('수량을 입력 해주세요.', '알림');
        return;
      }

      let ordQty = 0;
      let minOrdQty = 0.001;

      //최소수량 종목에 맞게 업데이트
      for (let item of this.coinSymbolInfoList) {
        if (item.symNo == this.modOrdInfo.symNo) {
          minOrdQty = item.minTrdAmt;
          break;
        }
      }

      ordQty = this.modOrdQty;

      //코인 최소 수량 가능한지 체크
      if (ordQty < minOrdQty) {
        console.log("ordQty, minOrdQty ", ordQty, minOrdQty)
        window.$alert.alert('주문가능 최소 수량을 확인 해주세요.', '알림');
        return;
      }


      //가격 수량이 같으면 업데이트 안함
      if (this.modOrdPrc != this.modOrdInfo.execPrc || this.modOrdQty != this.modOrdInfo.balQty) {
        this.modOrdInfo.ordPrc = this.modOrdPrc;
        this.modOrdInfo.ordQty = this.modOrdQty;
        this.saveModifyOrd(this.modOrdInfo);
      }

      let message = "코인 체결리스트 미체결 정정 확인 클릭 " + this.modOrdInfo.symCd;
      this.sendCoinOmsLogMessage(message);

      this.showEditOrderSheet = false;
    },
    saveModifyOrd(data) {
      if (data.symCd === undefined
        || data.execPrc === undefined) {
        return;
      }

      if (data.ordId == undefined || data.ordId == 0) {
        window.$alert.alert('원주문 정보가 없습니다.')
        return;
      }

      let orgOrders = new Array();
      let totCnt = 0;
      let totOrdQty = 0;
      let modifyPrc = data.ordPrc;
      const originPrice = data.execPrc;
      const targetPrice = modifyPrc;

      totCnt++;
      totOrdQty += Number(data.ordQty);

      const orgOrder = {
        accId: this.coinAccount.accId,
        symCd: this.coinSymbol.name,
        symNo: this.coinSymbol.symbolno,
        trdDt: data.trdDt,
        ordId: 0,
        orgOrdNo: data.ordId,
        ordSdCd: data.ordSdCd,
        ordTpCd: data.ordTpCd,
        ordPrc: modifyPrc,
        ordQty: data.ordQty,
        repReqQty: data.ordQty,
        cxlReqQty: 0,
        orgUsrKey: data.usrKey,
        lv: data.lv,
        targetPrice: targetPrice,
      }

      orgOrders.push(orgOrder);

      let order = {
        usrKey: Utils.getUuidv4(),
        accId: this.coinAccount.accId,
        symCd: data.symCd,
        symNo: data.symNo,
        //accPass : this.getUsrAccPass,
        orderList: orgOrders,
        ordSdCd: data.ordSdCd
      }

      let coinSymbolData;

      this.coinSymbolList.forEach(symbol => {
        if (symbol.name == data.symCd) {
          coinSymbolData = symbol;
        }
      })

      let message = "모바일 코인 주문 ";
      if (data.ordSdCd == "1") {
        message += " 매도";
      } else if (data.ordSdCd == "2") {
        message += " 매수";
      }

      let ordPrc = 0;

      message += " " + data.symCd;

      if (data.ordTpCd == "2") { //시장가
        message += "/시장가";
      } else if (data.ordTpCd == "1") { //지정가
        ordPrc = modifyPrc;
        message += "/지정가";
      }

      message += "/수량 " + totOrdQty + "/가격 " + modifyPrc;

      this.sendCoinOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert) {
        const orderData = { apiCode: "COMS0003", order: order, symbol: coinSymbolData, qty: totOrdQty, price: originPrice, targetPrice: targetPrice, message: message };
        this.$modal.show("modalConfirmOrder", orderData);
      } else {
        this.sendCoinOmsMessage('COMS0003', order)

      }
    },
    //미체결 TP/SL 팝업
    modifyTpSlPop(data) {
      console.log("modifyTpSlPop ", data)
      //선택 데이터
      this.ordInfo = data;
      this.showOrdTpSlFlag = true;
      this.searchOrdInfo();

      // for (let item of this.coinSymbolInfoList) {
      //   if (item.symNo == this.modOrdInfo.symNo) {
      //     this.modOrdType = item.artcCd;
      //     break;
      //   }
      // }      
    },
    cancelOrdSl(data) {
      if (data.ordType == 'ORD') {
        this.cancelStock(data);
      } else if (data.ordType == 'SL') {
        this.cancelTpSl(data);
      }
    },
    //미체결 주문 취소
    cancelStock(data) {
      if (data.symCd === undefined
        || data.execPrc === undefined) {
        return;
      }

      let orgOrders = new Array();
      let totCnt = 0;
      let totOrdQty = 0;

      totCnt++;
      totOrdQty += data.ordQty;

      const orgOrder = {
        accId: this.coinAccount.accId,
        symCd: data.symCd,
        symNo: data.symNo,
        trdDt: data.trdDt,
        ordId: 0,
        orgOrdNo: data.ordId,
        ordSdCd: data.ordSdCd,
        ordTpCd: data.ordTpCd,
        ordPrc: data.execPrc,
        ordQty: data.ordQty,
        repReqQty: data.repReqQty,
        cxlReqQty: data.ordQty,
        orgUsrKey: data.usrKey,
      }

      orgOrders.push(orgOrder);

      let order = {
        usrKey: Utils.getUuidv4(),
        accId: this.coinAccount.accId,
        symCd: data.symCd,
        symNo: data.symNo,
        //accPass : this.getUsrAccPass,
        orderList: orgOrders,
        ordSdCd: data.ordSdCd
      }

      let coinSymbolData;

      this.coinSymbolList.forEach(symbol => {
        if (symbol.name == data.symCd) {
          coinSymbolData = symbol;
        }
      })

      let message = "모바일 코인 미체결리스트 지정가 취소 클릭 " + data.symCd;
      this.sendCoinOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert) {
        const orderData = { apiCode: "COMS0002", order: order, symbol: coinSymbolData, qty: totOrdQty, price: data.execPrc, message: message };
        this.$modal.show("modalConfirmOrder", orderData);
      } else {
        this.sendCoinOmsMessage('COMS0002', order)

      }
    },
    //미체결 TP/SL 취소
    cancelTpSl(data) {
      if (data.symCd === undefined
        || data.execPrc === undefined) {
        return;
      }

      if (data.posSeq == undefined || data.posSeq == 0) {
        window.$alert.alert('포지션 정보가 없습니다.')
        return;
      }

      let orgTpSlOrders = new Array()
      let totCnt = 0;
      let totOrdQty = 0;
      totCnt++;
      totOrdQty += data.ordQty

      const orgOrder = {
        accId: this.coinAccount.accId,
        symCd: data.symCd,
        symNo: data.symNo,
        trdDt: data.trdDt,
        cposTpCd: data.cposTpCd,
        slId: data.ordId,
        slTpCd: data.slTpCd,
        prcTpCd: data.prcTpCd,
        slOrdPrc: data.execPrc,
        slOrdQty: data.ordQty,
        posSeq: data.posSeq,
        slOrdSdCd: data.ordSdCd,
        ordSdCd: data.ordSdCd,
      }

      orgTpSlOrders.push(orgOrder);

      let order = {
        usrKey: Utils.getUuidv4(),
        accId: this.coinAccount.accId,
        symCd: this.coinSymbol.name,
        symNo: this.coinSymbol.symbolno,
        slTpCd: data.slTpCd,
        //accPass : this.getUsrAccPass,
        orderList: orgTpSlOrders
      }

      let message = "모바일 코인 미체결리스트";
      if (data.slTpCd == "1") {
        message += " 익절";
      } else if (data.slTpCd == "2") {
        message += " 손절";
      }

      message += " 취소 클릭 " + data.symCd;
      this.sendCoinOmsLogMessage(message);

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert) {
        let coinSymbolData;

        this.coinSymbolList.forEach(coinSymbol => {
          if (coinSymbol.name == data.symCd) {
            coinSymbolData = coinSymbol;
          }
        })

        const orderData = { apiCode: "COMS0008", order: order, symbol: coinSymbolData, qty: totOrdQty, price: data.execPrc, message: message };
        this.$modal.show("modalConfirmOrder", orderData);
      } else {
        this.sendCoinOmsMessage('COMS0008', order);
      }
    },
    //전체 청산
    clearOrderAll(type) {
      if (this.coinAccount == undefined || this.coinAccount.accId == undefined || this.coinAccount.accId == 0) {
        window.$alert.alert('선택된 계좌가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnClearAllFlag) {
        return;
      }

      if (type == 'all' || type == 'symbol') {
        let msg = "모바일 현종목";
        let symCd = this.coinSymbol.name;
        let symNo = this.coinSymbol.symbolno;
        if (type == 'all') {
          msg = "모바일 전체";
          symCd = "";
        }

        this.btnClearAllFlag = false;

        const order = {
          usrKey: Utils.getUuidv4(),
          accId: this.coinAccount.accId,
          symCd: symCd,
          symNo: symNo,
          trdDt: '',
          clearType: type,
          //accPass : this.getUsrAccPass,
        }

        let message = msg;
        message += " 청산 클릭";
        this.sendCoinOmsLogMessage(message);

        if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert) {
          const orderData = { apiCode: "COMS0013", order: order, symbol: this.coinSymbol, qty: 0, price: 0, message: message };
          this.$modal.show("modalConfirmOrder", orderData);
        } else {
          this.sendCoinOmsMessage("COMS0013", order);
        }
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnClearAllFlag) {
        setTimeout(() => {
          this.btnClearAllFlag = true
        }, 1500)
      }
    },
    cancelAll(type) { //전/현종목 취소
      if (this.coinAccount == undefined || this.coinAccount.accId == undefined || this.coinAccount.accId == 0) {
        window.$alert.alert('계좌가 정보가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnCancelAllFlag) {
        return;
      }

      if (type == 'all' || type == 'symbol') {
        let msg = "모바일 현종목";
        let symCd = this.coinSymbol.name;
        let symNo = this.coinSymbol.symbolno;
        if (type == 'all') {
          msg = "모바일 전종목";
          symCd = "";
        }

        this.btnCancelAllFlag = false;

        const order = {
          usrKey: Utils.getUuidv4(),
          accId: this.coinAccount.accId,
          symCd: symCd,
          symNo: symNo,
          trdDt: '',
          clearType: type,
          //accPass : this.getUsrAccPass,
        }

        let message = msg;
        message += " 취소 클릭";
        this.sendCoinOmsLogMessage(message);

        if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert) {
          const orderData = { apiCode: "COMS0018", order: order, symbol: this.coinSymbol, qty: 0, price: 0, message: message };
          this.$modal.show("modalConfirmOrder", orderData);
        } else {
          this.sendCoinOmsMessage("COMS0018", order);
        }
      }

      //1.5초후 버튼 누를 수 있게 설정
      if (!this.btnCancelAllFlag) {
        setTimeout(() => {
          this.btnCancelAllFlag = true
        }, 1500)
      }
    },
    sendCoinOmsMessage(apiCode, body) {
      //console.log("sendCoinOmsMessage", apiCode, body)
      //팝업주문창여부 확인
      if (window.name == undefined || window.name == null || window.name == "") {
        if (!window.$store.getters['SocketCoinOms/getIsConnectCoinOms']) {
          window.$alert.alert('코인 주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
          return
        }
        //비밀번호 추가
        // body.accPass = this.getUsrAccPass; 
        window.$store.dispatch('SocketCoinOms/sendCoinOmsMessage', { apiCode: apiCode, body: body })
      } else {
        if (!window.opener.$store.getters['SocketCoinOms/getIsConnectCoinOms']) {
          window.$alert.alert('코인 주문서버 연결이 불안정합니다.\n새로고침 후 다시 시도해주세요.');
          return
        }

        window.opener.$store.dispatch('SocketCoinOms/sendCoinOmsMessage', { apiCode: apiCode, body: body })
      }
    },
    //포지션 TP/SL 초기화
    resetPosTpSl() {
      this.ordInfo = {};
      this.orgOrdInfoList = [];
      this.ordTpType = '1';
      this.ordSlType = '1';
      this.tpOrdPrc = '';
      this.slOrdPrc = '';
      this.tpId = 0;
      this.slId = 0;
    },
    //포지션 TP/SL 팝업
    posTpSlOrder(selData) {
      if (selData != undefined) {
        this.resetPosTpSl();

        //선택 데이터
        this.ordInfo = selData;

        //this.com.markPr = (Math.floor(this.ordInfo.markPr * 100) / 100).toFixed(this.tikDotSz);
        //TP/SL 창 활성화
        this.showTPSLSheet = true;

        this.searchOrdInfo();
      }
    },
    checkValuePoint(event) {
      return Utils.inputPoint(event);
    },
    //TP/SL 콤보 설정
    changeTpSlType(index, type) {
      console.log("changeTpSlType ", index, type)
      this.ordTpSlType[index] = type;
    },
    //tpsl 목록 조회
    async searchOrdInfo() {
      // 
      // ordInfoList
      let data = {};


      if (this.showTPSLSheet == true) {
        data = { posSeq: this.ordInfo.posSeq };
      } else if (this.showOrdTpSlFlag == true) {
        data = { ordId: this.ordInfo.ordId };
      }



      await this.$lhttp.get('/api/order/getCoinOrdSlList', { params: data }).then((response) => {
        if (response.code == 200) {

          if (this.showTPSLSheet == true) {
            for (let item of response.data) {
              //원본 저장
              this.orgOrdInfoList.push({ slId: item.slId, slOrdPrc: item.slOrdPrc, slOrdQty: item.slOrdQty, slOrdSdCd: item.slOrdSdCd, slOrdStatCd: item.slOrdStatCd, slTpCd: item.slTpCd, prcTpCd: item.prcTpCd });
              if (item.slTpCd == "1") {
                this.tpId = item.slId;
                this.tpOrdPrc = item.slOrdPrc;
              } else if (item.slTpCd == "2") {
                this.slId = item.slId;
                this.slOrdPrc = item.slOrdPrc;
              }
            }
          } else if (this.showOrdTpSlFlag == true) {
            this.ordInfoList = Object.assign([], response.data);
            //원본 저장
            for (let item of response.data) {
              this.orgOrdInfoList.push({ slId: item.slId, slOrdPrc: item.slOrdPrc, slOrdQty: item.slOrdQty, slOrdSdCd: item.slOrdSdCd, slOrdStatCd: item.slOrdStatCd, slTpCd: item.slTpCd, prcTpCd: item.prcTpCd });
            }
          }
        }
      });
    },
    //미체결 TP/SL 저장
    saveTpSl() {
      let chkYn = "N";
      let saveInfo = { ordInfo: this.ordInfo, saveList: [] };
      //변경 내용이 있는지 확인
      this.ordInfoList.forEach((item, index) => {
        //가격타입
        let addYn = "N";
        if (item.prcTpCd != this.orgOrdInfoList[index].prcTpCd) {
          addYn = "Y";
          //수량
        } else if (item.slOrdQty != this.orgOrdInfoList[index].slOrdQty) {
          addYn = "Y";
          //가격
        } else if (item.slOrdPrc != this.orgOrdInfoList[index].slOrdPrc) {
          addYn = "Y";
        }

        if (addYn == "Y") {
          saveInfo.saveList.push(item);
          chkYn = "Y";
        }
      });

      if (chkYn == "Y" && saveInfo.saveList.length > 0) {
        //COMS0007
        if (saveInfo.ordInfo.symCd === undefined
          || saveInfo.saveList === undefined) {
          return;
        }

        if (saveInfo.ordInfo.ordId == undefined || saveInfo.ordInfo.ordId == 0) {
          window.$alert.alert('원주문 정보가 없습니다.')
          return;
        }

        let tpSlOrders = new Array();
        let totCnt = saveInfo.saveList.length;
        let totOrdQty = 0;
        let ordId = 0;

        if (saveInfo.ordInfo.ordId != undefined && saveInfo.ordInfo.ordId > 0) {
          ordId = saveInfo.ordInfo.ordId;
        }

        totCnt++;
        totOrdQty += Number(saveInfo.ordQty);

        for (const item of saveInfo.saveList) {
          const tpSlOrder = {
            accId: this.coinAccount.accId,
            symCd: item.symCd,
            symNo: item.symNo,
            trdDt: item.trdDt,
            ordId: item.ordId,
            slId: item.slId,
            ordSdCd: item.slOrdSdCd,
            ordTpCd: item.ordTpCd,
            ordPrc: item.ordPrc,
            slOrdSdCd: item.slOrdSdCd,
            slOrdPrc: item.slOrdPrc,
            slOrdQty: item.slOrdQty,
            slTpCd: item.slTpCd,
            prcTpCd: item.prcTpCd,
            orgUsrKey: saveInfo.ordInfo.usrKey,
          };

          tpSlOrders.push(tpSlOrder);
        }

        let order = {
          usrKey: Utils.getUuidv4(),
          accId: this.coinAccount.accId,
          accNo: this.coinAccount.accNo,
          symNo: saveInfo.ordInfo.symNo,
          symCd: saveInfo.ordInfo.symCd,
          ordId: ordId,
          trdDt: saveInfo.ordInfo.trdDt,
          //accPass : this.getUsrAccPass,
          orderList: tpSlOrders
        }

        let coinSymbolData;

        this.coinSymbolList.forEach(symbol => {
          if (symbol.name == saveInfo.ordInfo.symCd) {
            coinSymbolData = symbol;
          }
        })

        let message = "코인 TP/SL 주문 수정";

        this.sendCoinOmsLogMessage(message);

        if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert) {
          const orderData = { apiCode: "COMS0007", order: order, symbol: coinSymbolData, qty: 0, price: 0, targetPrice: 0, message: message };
          this.$modal.show("modalConfirmOrder", orderData);
        } else {
          this.sendCoinOmsMessage('COMS0007', order)
        }
        this.showOrdTpSlFlag = false;
      } else {
        this.showOrdTpSlFlag = false;
      }
    },
    //포지션 TP/SL 저장
    saveTpSlPos() {
      let saveInfo = { ordInfo: this.ordInfo, saveList: [] };
      let saveYn = "N";
      let slOrdSdCd = "0";
      if (this.ordInfo.ordSdCd == "1") { //매도
        slOrdSdCd = "2"; //매수
      } else if (this.ordInfo.ordSdCd == "2") { //매수
        slOrdSdCd = "1"; //매도
      }

      //수정이면
      if (this.orgOrdInfoList.length > 0) {
        let slYn = "N";
        let tpYn = "N";
        for (let item of this.orgOrdInfoList) {
          if (item.slTpCd == "1") { //익절
            if (Number(item.slOrdPrc) != Number(this.tpOrdPrc)) {
              item.slOrdPrc = this.tpOrdPrc;
              saveYn = "Y";
            }
            if (item.prcTpCd != this.ordTpType) {
              saveYn = "Y";
            }
            slYn = "Y";
          } else if (item.slTpCd == "2") { //손절
            if (Number(item.slOrdPrc) != Number(this.slOrdPrc)) {
              item.slOrdPrc = this.slOrdPrc;
              saveYn = "Y";
            }
            if (item.prcTpCd != this.ordSlType) {
              saveYn = "Y";
            }
            tpYn = "Y";
          }
          if (saveYn == "Y") {
            item.orgSlOrdPrc = item.slOrdPrc; //삭제할 가격
            saveInfo.saveList.push(item);
          }
        }

        //TP/SL 중 1개만 저장되어있으면 나머지 신규로 생성
        if (slYn == "N" && this.tpOrdPrc != null && Number(this.tpOrdPrc) > 0) { // 익절
          let item = { slId: 0, posSeq: this.ordInfo.posSeq, slOrdPrc: this.tpOrdPrc, slOrdQty: 0, slTpCd: "1", prcTpCd: this.ordTpType, slOrdSdCd: slOrdSdCd };
          saveInfo.saveList.push(item);
          saveYn = "Y";
        } else if (tpYn == "N" && this.slOrdPrc != null && Number(this.slOrdPrc) > 0) { // 손절
          let item = { slId: 0, posSeq: this.ordInfo.posSeq, slOrdPrc: this.slOrdPrc, slOrdQty: 0, slTpCd: "2", prcTpCd: this.ordSlType, slOrdSdCd: slOrdSdCd };
          saveInfo.saveList.push(item);
          saveYn = "Y";
        }
      } else { //신규저장
        if (this.tpOrdPrc != null && Number(this.tpOrdPrc) > 0) { //익절
          let item = { slId: 0, posSeq: this.ordInfo.posSeq, slOrdPrc: this.tpOrdPrc, slOrdQty: 0, slTpCd: "1", prcTpCd: this.ordTpType, slOrdSdCd: slOrdSdCd };
          saveInfo.saveList.push(item);
          saveYn = "Y";
        }
        if (this.slOrdPrc != null && Number(this.slOrdPrc) > 0) { //손절
          let item = { slId: 0, posSeq: this.ordInfo.posSeq, slOrdPrc: this.slOrdPrc, slOrdQty: 0, slTpCd: "2", prcTpCd: this.ordSlType, slOrdSdCd: slOrdSdCd };
          saveInfo.saveList.push(item);
          saveYn = "Y";
        }
      }

      console.log("saveInfo List?? ", saveInfo)

      //저장
      if (saveYn == "Y") {
        if (saveInfo.ordInfo.symCd === undefined
          || saveInfo.saveList === undefined) {
          return;
        }

        if (saveInfo.ordInfo.posSeq == undefined || saveInfo.ordInfo.posSeq == 0) {
          window.$alert.alert('포지션 정보가 없습니다.')
          return;
        }

        let tpSlOrders = new Array();
        let totCnt = saveInfo.saveList.length;
        let totOrdQty = 0;
        let ordId = 0;


        totCnt++;
        totOrdQty += saveInfo.ordQty;

        for (const item of saveInfo.saveList) {
          const tpSlOrder = {
            accId: this.coinAccount.accId,
            symCd: saveInfo.ordInfo.symCd,
            symNo: saveInfo.ordInfo.symNo,
            trdDt: item.trdDt == undefined ? '' : item.trdDt,
            ordId: 0,
            slId: item.slId,
            ordSdCd: item.slOrdSdCd,
            ordTpCd: '',
            ordPrc: saveInfo.ordInfo.execPrc,
            slOrdSdCd: item.slOrdSdCd,
            slOrdPrc: item.slOrdPrc,
            slOrdQty: item.slOrdQty,
            slTpCd: item.slTpCd,
            prcTpCd: item.prcTpCd,
            posSeq: saveInfo.ordInfo.posSeq,
            orgUsrKey: saveInfo.ordInfo.usrKey,
            orgSlOrdPrc: item.orgSlOrdPrc, //삭제할 원가격
          };

          tpSlOrders.push(tpSlOrder);
        }

        let order = {
          usrKey: Utils.getUuidv4(),
          accId: this.coinAccount.accId,
          accNo: this.coinAccount.accNo,
          symNo: saveInfo.ordInfo.symNo,
          symCd: saveInfo.ordInfo.symCd,
          ordId: 0,
          trdDt: saveInfo.ordInfo.trdDt,
          //accPass : this.getUsrAccPass,
          orderList: tpSlOrders
        }
        console.log("order >>>>> ", order)

        let coinSymbolData;

        this.coinSymbolList.forEach(symbol => {
          if (symbol.name == saveInfo.ordInfo.symCd) {
            coinSymbolData = symbol;
          }
        })

        let message = "모바일 코인 포지션 TP/SL 주문";

        this.sendCoinOmsLogMessage(message);

        if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert) {
          const orderData = { apiCode: "COMS0007", order: order, symbol: coinSymbolData, qty: 0, price: 0, targetPrice: 0, message: message };
          this.$modal.show("modalConfirmOrder", orderData);
        } else {
          this.sendCoinOmsMessage('COMS0007', order)
        }
      }

      //TP/SL 닫기
      this.showTPSLSheet = false;
    },
    //시장가 청산
    orderClear(selData) {
      if (this.coinAccount == undefined || this.coinAccount.accId == undefined || this.coinAccount.accId == 0) {
        window.$alert.alert('코인 계좌 정보가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      if (!selData) {
        this.$alert.alert("선택한 주문이 없습니다.", '알림');
        return;
      }

      // if (this.orderFlag){
      //   return;
      // }
      let coinSymbolData = null;
      this.coinSymbolList.forEach(item => {
        if (item.name == selData.symCd) {
          coinSymbolData = item;
          return;
        }
      });

      let message = "모바일 코인 체결리스트 시장가 청산 클릭 " + selData.symCd;
      this.sendCoinOmsLogMessage(message);

      let selectList = [{ symCd: selData.symCd }];

      const order = {
        accId: this.coinAccount.accId,
        trdDt: '',
        //accPass : this.getUsrAccPass,
        ordSdCd: selData.ordSdCd,
        coinSymbolList: selectList,
        cposTpCd: selData.cposTpCd,
      }

      if (this.orderSetting != undefined && this.orderSetting.clearMessageAlert) {
        const orderData = { apiCode: "COMS0014", order: order, symbol: coinSymbolData, qty: selData.balQty, price: selData.execPrc, message: message };
        this.$modal.show("modalConfirmOrder", orderData);
      } else {
        this.sendCoinOmsMessage("COMS0014", order);
      }
    },
    //지정가 청산 팝업
    limitClearOrd(selData) {
      this.showLimitClearSheet = true;
      this.limitOrdPrc = '';
      this.limitOrdInfo = selData;
    },
    //포지션 지정가 청산 주문
    saveLimitOrder() {
      console.log("selData", this.limitOrdInfo)
      if (this.coinAccount == undefined || this.coinAccount.accId == undefined || this.coinAccount.accId == 0) {
        window.$alert.alert('코인 계좌 정보가 없습니다.', '알림')
        return;
      }

      // if (!this.getUsrAccPass){
      //   window.$alert.alert('계좌 비밀번호를 확인하세요.', '알림')
      //   return;
      // }

      if (this.limitOrdPrc == undefined || this.limitOrdPrc == 0 || this.limitOrdPrc == '') {
        window.$alert.alert('가격을 입력 해주세요.', '알림');
        return;
      }

      //주문내역이 있으면 리턴 결과 오기전 재주문 불가
      if (!this.btnStdCoinFlag) {
        return;
      }

      //"모바일 코인선물 지정가 청산 주문 매수 BTCUSDT/지정가/수량(undefined) undefined/가격 100000"

      let ordSdCd = "0";
      let message = "모바일 코인선물 지정가 청산 주문";
      if (this.limitOrdInfo.ordSdCd == "2") {
        ordSdCd = "1";
        message += " 매도";
      } else if (this.limitOrdInfo.ordSdCd == "1") {
        ordSdCd = "2";
        message += " 매수";
      }

      message += " " + this.limitOrdInfo.symCd;

      let ordTpCd = "1"; //지정가
      let apiCode = "";
      let ordPrc = this.limitOrdPrc;
      let ordQty = 0; //전부 청산으로 수량0으로 입력
      let opnCls = "2"; //진입 청산 구분

      apiCode = "COMS0001";
      message += "/지정가";

      message += "/수량 ";
      message += ordQty + "/가격 " + ordPrc;

      this.btnStdCoinFlag = false;

      const order = {
        usrKey: Utils.getUuidv4(),
        accId: this.coinAccount.accId,
        symCd: this.limitOrdInfo.symCd,
        symNo: this.limitOrdInfo.symNo,
        trdDt: '',
        ordId: 0,
        orgOrdNo: 0,
        ordSdCd: ordSdCd,
        ordTpCd: ordTpCd,
        ordPrc: ordPrc,
        ordQty: ordQty,
        repReqQty: 0,
        cxlReqQty: 0,
        //accPass : this.getUsrAccPass,
        lv: this.coinLeverage,
        opnCls: opnCls,
        posSeq: this.limitOrdInfo.posSeq,
        cposTpCd: this.limitOrdInfo.cposTpCd
      }

      this.sendCoinOmsLogMessage(message);

      let symbolData = null;

      this.coinSymbolList.forEach(item => {
        if (item.name == this.limitOrdInfo.symCd) {
          symbolData = item;
          return;
        }
      });

      if (this.orderSetting != undefined && this.orderSetting.ordMessageAlert) {
        const orderData = { apiCode: apiCode, order: order, symbol: symbolData, qty: this.ordStdQty, price: Number(ordPrc), message: message };
        this.$modal.show("modalConfirmOrder", orderData);
      } else {
        this.sendCoinOmsMessage(apiCode, order);
      }

      //0.3초후 버튼 누를 수 있게 설정
      if (!this.btnStdCoinFlag) {
        setTimeout(() => {
          this.btnStdCoinFlag = true;
        }, 300);
      }

      this.showLimitClearSheet = false;
    },
    minusBtn(type) {
      if (type == "stdOrdPrc") { //가격
        if (!isNaN(this.stdOrdPrc)) {
          this.stdOrdPrc = Utils.decimalSubtract(this.stdOrdPrc, this.coinSymbolInfo.tikSz);
        }
      } else if (type == "stdOrdQty") { //수량
        if (!isNaN(this.stdOrdQty)) {
          let tikSz = this.coinSymbolInfo.tikSz;
          if (this.stdOrdType != "USDT") {
            tikSz = this.coinSymbolInfo.minTrdAmt;
          }
          this.stdOrdQty = Utils.decimalSubtract(this.stdOrdQty, tikSz);
        }
      } else if (type == "stdOrdTpPrc") { //익절가격
        if (!isNaN(this.stdOrdTpPrc)) {
          this.stdOrdTpPrc = Utils.decimalSubtract(this.stdOrdTpPrc, this.coinSymbolInfo.tikSz);
        }
      } else if (type == "stdOrdSlPrc") { //손절가격
        if (!isNaN(this.stdOrdSlPrc)) {
          this.stdOrdSlPrc = Utils.decimalSubtract(this.stdOrdSlPrc, this.coinSymbolInfo.tikSz);
        }
      }
    },
    plusBtn(type) {
      if (type == "stdOrdPrc") { //가격
        if (!isNaN(this.stdOrdPrc)) {
          this.stdOrdPrc = Utils.decimalAdd(this.stdOrdPrc, this.coinSymbolInfo.tikSz);
        }
      } else if (type == "stdOrdQty") { //수량
        if (!isNaN(this.stdOrdQty)) {
          let tikSz = this.coinSymbolInfo.tikSz;
          if (this.stdOrdType != "USDT") {
            tikSz = this.coinSymbolInfo.minTrdAmt;
          }
          this.stdOrdQty = Utils.decimalAdd(this.stdOrdQty, tikSz);
        }
      } else if (type == "stdOrdTpPrc") { //익절가격
        if (!isNaN(this.stdOrdTpPrc)) {
          this.stdOrdPrc = Utils.decimalAdd(this.stdOrdTpPrc, this.coinSymbolInfo.tikSz);
        }
      } else if (type == "stdOrdSlPrc") { //손절가격
        if (!isNaN(this.stdOrdSlPrc)) {
          this.stdOrdSlPrc = Utils.decimalAdd(this.stdOrdSlPrc, this.coinSymbolInfo.tikSz);
        }
      }
    },









    handleVisibilityChange() {
      if (document.hidden) {
        this.pauseStartPage('P');
      } else {
        this.pauseStartPage('S');
      }
    },
    pauseStartPage(type) {
      const curr = new Date();

      if (type == "P") { //절전모드
        this.time = curr.getTime();

        this.disConnect();
        this.disConnectMsg();
        console.log("disConnect ....");
      } else if (type == "S") { //절전모드 해제
        const res = this.loginChk();
        res.then(response => {
          const data = response.data;
          if (data.code != 200) {
            this.activeTab = 'order';
            window.$store.dispatch('Login/logout');
          } else {
            if (data.loginUserInfo != undefined && data.loginUserInfo.authId != undefined) {
              this.connectSokect();
              //message 소켓 서버 연결
              this.connectMsg();

              setTimeout(() => {
                this.selectSymbolList();
              }, 1000)

              //10분이 넘어가면 새로고침
              const seconds = Math.floor((Number(curr.getTime()) - Number(this.time)) / 1000);
              if (seconds > 300) {
                window.location.reload();
              }

              console.log("connect ....", seconds);
            } else {
              this.activeTab = 'order';
              window.$store.dispatch('Login/logout');
            }
          }
        }).catch((e) => {
          this.mobileSelectedTab = 'chart';
          window.$store.dispatch('Login/logout');
        });
      }
    },
    async loginChk() {
      let res = window.$store.dispatch('Login/refresh');
      return res;

    },
    showSlideMenu(name) {
      this.showSide = false;
      if (name == "showAccountSheet") {
        this.sendCoinOmsLogMessage("모바일 계좌 클릭");
        this.showAccountSheet = true;
      } else if (name == "showDepositSheet") {
        this.sendCoinOmsLogMessage("모바일 입출금 클릭");
        this.showDepositSheet = true;
      }

    },
    showModal(name) {
      if (name == "modalSettings") {
        this.sendCoinOmsLogMessage("모바일 환경설정 클릭");
      } else if (name == "modalNotice") {
        this.sendCoinOmsLogMessage("모바일 공지사항 클릭");
      } else if (name == "modalLogout") {
        this.sendCoinOmsLogMessage("모바일 공지사항 클릭");
      } else if (name == "modalTransactionTable") {
        this.sendCoinOmsLogMessage("모바일 거래내역 클릭");
      } else if (name == "modalProfitLossTable") {
        this.sendCoinOmsLogMessage("모바일 매매손익 클릭");
      } else if (name == "modalDepositWithdraw") {
        this.sendCoinOmsLogMessage("모바일 입출금신청 클릭");
      } else if (name == "modalDepositWithdrawTable") {
        this.sendCoinOmsLogMessage("모바일 입출금내역 클릭");
      }

      let params = { account: this.account, coinAccount: this.coinAccount, mobileYn: "Y" };
      this.$modal.show(name, params);
      let message = "모바일 ";

      if (name == "modalDepositWithdraw") {
        message = "입출금신청 클릭";
      } else if (name == "modalDepositWithdrawTable") {
        message = "입출금내역 클릭";
      } else if (name == "modalTransactionTable") {
        message = "거래내역 클릭";
      } else if (name == "modalProfitLossTable") {
        message = "매매손익 클릭";
      } else if (name == "modalSettings") {
        message = "환경설정 클릭";
      }

      this.sendCoinOmsLogMessage(message);

      this.$modal.show(name, params);
      console.log(name);
    },
    sendCoinOmsLogMessage(message) { //코인 사용자 로그 저장
      window.$store.dispatch('SocketCoinOms/setLogMsg', { message: message });
    },
    disConnect() {
      console.log("coin disconnect....");
      //코인 주문
      if (window.$store.getters['SocketCoinOms/getIsConnectCoinOms']) {
        //
        window.$store.dispatch('SocketCoinOms/setCoinDisconnect');
      }

      //코인 소켓 시세
      if (window.$store.getters['SocketCoinPrice/getIsConnectCoinPrice']) {
        //setDisconnect
        window.$store.dispatch('SocketCoinPrice/setCoinDisconnect');
      }

      //폴링 코인 시세
      if (window.$store.getters['SocketCoinPrice/getIsPolingCoinPrice']) {
        //clear polling
        window.$store.dispatch('SocketCoinPrice/setClearCoinPolling');
      }
    },
    //메시지 서버
    disConnectMsg() {
      if (window.$store.getters['SocketMsg/getIsConnectMsg']) {
        //clear polling
        window.$store.dispatch('SocketMsg/setDisconnect');
      }
    },

  },
};
</script>
