<template>
  <div>
    <button class="btn-link" @click="showModify(params)" v-if="params.data.slOrdCnt == 0 && params.data.ordType == 'ORD'">정정</button>
    <span v-if="params.data.ordType == 'SL'">--</span>
  </div>
</template>

<script>
export default {
	name: 'AgModify',
	methods: {
    showModify(params) {
      this.$modal.show("modalEditOrder", params.data);
    },
	},
}
</script>