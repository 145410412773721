var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"width":"500px"}},[_c('SliderLeverage',{attrs:{"min":0,"max":50,"divisor":5}})],1),_c('br'),_c('br'),_c('br'),_c('p',[_vm._v("inputs h30,12px mobile h40,13px")]),_c('input',{attrs:{"type":"text","placeholder":"ID"}}),_c('input',{attrs:{"type":"password","placeholder":"PW"}}),_vm._m(0),_c('br'),_c('br'),_c('p',[_vm._v("buttons h30,12px mobile h40,13px")]),_vm._m(1),_c('br'),_c('br'),_c('p',[_vm._v("dropdown h30,12px mobile h40,13px")]),_vm._m(2),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('p',[_vm._v("tab h40,13px, mobile h40,13px")]),_vm._m(3),_c('br'),_c('br'),_c('br'),_c('p',[_vm._v("checkmark 12px mobile 13px")]),_vm._m(4),_vm._m(5),_c('br'),_c('br'),_c('br'),_c('p',[_vm._v("check")]),_c('Checkbox',{attrs:{"label":"매수체걸"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-unit"},[_c('input',{attrs:{"type":"text"}}),_c('span',{staticClass:"unit"},[_vm._v("원")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-container"},[_c('button',{staticClass:"btn-primary darkgray"},[_vm._v("취소")]),_c('button',{staticClass:"btn-primary blue"},[_vm._v("입금신청")]),_c('button',{staticClass:"btn-primary line"},[_vm._v("+100만")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-dropdown"},[_c('div',{staticClass:"dropdown"},[_c('div',{staticClass:"selected"},[_vm._v("1. 일반")]),_c('img',{attrs:{"src":require("@/assets/icons/dropdown-triangle.svg"),"alt":""}})]),_c('ul',{staticClass:"dropdown-list"},[_c('li',[_vm._v("1. 일반")]),_c('li',[_vm._v("2. 모의투자")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-nav-container"},[_c('div',{staticClass:"tab active"},[_vm._v("입금신청")]),_c('div',{staticClass:"tab"},[_vm._v("출금신청")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkbox-btn"},[_c('img',{staticClass:"checkbox-off",attrs:{"src":require("@/assets/icons/checkbox-off.svg"),"alt":""}}),_c('img',{staticClass:"checkbox-on",attrs:{"src":require("@/assets/icons/checkbox-on.svg"),"alt":""}}),_c('span',[_vm._v("매수체결")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkbox-default"},[_c('input',{attrs:{"type":"checkbox","id":"all","value":""}}),_c('label',{attrs:{"for":"all"}},[_vm._v("매수체결")])])
}]

export { render, staticRenderFns }