<template>
  <button class="btn-link" @click="showModal('modalTPSlAll', params)">추가</button>
</template>

<script>
export default {
name: 'AgTpSl',
methods: {
  showModal(name, params) {
    this.$modal.show(name, params.data);
  },
},
}
</script>