<template>
  <div>
    <modal name="modalTPSL" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">익절(TP)/손절(SL)</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="modal-tpsp">
          <div class="label table-label" v-if="tpSlCnt<2">주문A가 실행되면 주문B를 제출합니다.</div>
          <div class="label table-label" v-if="tpSlCnt==2">주문A가 실행되면 주문B, 주문C를 제출합니다.</div>
          <table class="modal-tpsp-table">
            <thead>
              <tr>
                <th>주문A</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="label">{{ ordInfo.ordTpCd == "1" ? "지정":"시장" }}</div>
                  <div class="value">{{ ordInfo.ordType == "ORD" ? "미체결":"체결" }} </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">사이드</div>
                  <div class="value"><span class="red" :class="{'red':ordInfo.ordSdCd == '2', 'blue':ordInfo.ordSdCd == '1'}">{{ ordInfo.ordSdNm }}</span></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">금액</div>
                  <div class="value">{{ ordInfo.execPrc }} USDT</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="division-line-tpsl" ><span v-if="tpSlCnt == 1"></span>
            <img src="@/assets/icons/tpspc-line.svg" alt="" v-if="tpSlCnt == 2">
          </div>

          <div :class="{slGrid:ordInfoList != undefined && ordInfoList.length > 1}">
            <table class="modal-tpsp-table" v-for="(item, index) of ordInfoList" :key="index">
              <thead>
                <tr>
                  <th>주문{{ item.ordEng }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="label">{{ item.slTpCd == '1' ? '익절':'손절' }} 시장가</div>
                    <div class="value">{{ ordInfo.ordType == "ORD" ? "보류중": "신규"}}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label">사이드</div>
                    <div class="value"><span class="red" :class="{'red':item.slOrdSdCd == '2', 'blue':item.slOrdSdCd == '1'}">{{ item.slOrdSdNm }}</span></div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label">수량</div>
                    <div class="value">{{ NumberComma(item.slOrdQty) }} BTC</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label">가격</div>
                    <div class="value">
                      <div class="order-input-container no-border">
                        <input type="text" placeholder="가격" :value="item.slOrdPrc" @input="item.slOrdPrc = checkValuePoint($event)" maxlength="13" :disabled="ordInfo.ordType == 'ORD' ? false: true" />
                        <div class="unit">USDT</div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="label">발동</div>
                    <div class="value">
                      <CoinCodeDropDown grpCd="PRCTP" v-model="item.prcTpCd" width="64" height="72" popup @input="changeTpSlType(index, $event)"  />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>

          <div class="alert-notice">
            <img src="@/assets/icons/alert-blue.svg" alt="" />
            One-Triggers-a One Cancels the-Other(OTOCO) 주문을 사용하면 두 개의 주문, 즉 기본 주문과 두 개의 보조 주문을 동시에 제출할 수 있습니다.
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <!-- <button class="btn-primary darkgray" @click="closeModal()">취소</button> -->
        <button class="btn-primary blue" @click="saveTpSl">확인</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Utils from '@/modules/utils';
import CoinCodeDropDown from '@/components/coin/CoinCodeDropDown.vue';
export default {
  name: 'CoinModalTPSL',
  components: {
    CoinCodeDropDown,
  },
  data() {
    return {
      width: '',
      height: '',
      ordInfo: {},
      ordInfoList: [],
      orgOrdInfoList: [],
      tpSlCnt:0,
      ordTpSlType:['1','1'],
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 500;
      this.height = 600;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    opened(){
    },
    beforeOpen(e){
      console.log("beforeOpen e.prams", e.params);

      //초기화
      this.ordInfo = [];
      this.orgOrdInfoList = [];
      this.ordInfoList = [];
      
      this.ordInfo = e.params;
      this.tpSlCnt = e.params.slOrdCnt;

      this.searchOrdInfo();



    },
    //tpsl 목록 조회
    async searchOrdInfo(){
      // 
      // ordInfoList
      const data = {ordId:this.ordInfo.ordId};
      await this.$lhttp.get('/api/order/getCoinOrdSlList', { params: data }).then((response) => { 
        if (response.code == 200){
          console.log("response", response);
          this.ordInfoList = Object.assign([], response.data);
          //원본 저장
          for (let item of response.data){
            this.orgOrdInfoList.push({slId:item.slId, slOrdPrc:item.slOrdPrc, slOrdQty:item.slOrdQty, slOrdSdCd:item.slOrdSdCd, slOrdStatCd:item.slOrdStatCd, slTpCd:item.slTpCd, prcTpCd:item.prcTpCd});
          }
        }
      });

      
    },
    //TP/SL 저장
    saveTpSl(){
      let chkYn = "N";
      let saveInfo = {ordInfo: this.ordInfo, saveList:[]};
      //변경 내용이 있는지 확인
      this.ordInfoList.forEach((item, index) => {
        //가격타입
        let addYn = "N";
        if (item.prcTpCd != this.orgOrdInfoList[index].prcTpCd){
          addYn = "Y";
        //수량
        }else if (item.slOrdQty != this.orgOrdInfoList[index].slOrdQty){
          addYn = "Y";
        //가격
        }else if (item.slOrdPrc != this.orgOrdInfoList[index].slOrdPrc){
          addYn = "Y";
        }

        if (addYn == "Y"){
          saveInfo.saveList.push(item);
          chkYn = "Y";
        }
      });

      if (chkYn == "Y" && saveInfo.saveList.length > 0){
        // this.$alert.confirm('익절/손절을 수정 하시겠습니까?', '확인')
        //     .then((response) => {
        //       //coinpage에서 저장 처리
        //       this.$emit("event:saveTpSl", saveInfo);
        //       this.closeModal();
        //     });

        this.$emit("event:saveTpSl", saveInfo);
        this.closeModal();
      }else{
        this.closeModal();
      }
    },
    checkValuePoint(event){
      return Utils.inputPoint(event);
    },
    closeModal() {
      this.$modal.hide('modalTPSL');
    },
    NumberComma(value) {
      if (value == undefined){
        return 0;
      }else{
        return Utils.numberComma(value.toFixed(4));
      } 
    },
    //콤보 설정
    changeTpSlType(index, type) {
      console.log("changeTpSlType ", index, type)
      this.ordTpSlType[index] = type;
    },
    //손절 콤보 설정
    // changeSlType(type) {
    //   this.ordSlType = type;
    // },
  },
};
</script>
<style lang="scss" scoped>
.slGrid{
  display: grid; grid-template-columns: 1fr 1fr; gap: 10px;
}
</style>
