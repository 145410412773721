<template>
  <div>
    <modal name="modalMarginEdit" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
    <div class="modal-header">
        <h2 class="modal-header-title">마진 조정</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
    </div>

    <div class="modal-body">
        <div class="modal-margin-edit">
        <div class="margin-edit-balance-row">
            <div class="label">가격격(USDT)</div>
            <div class="value">
            최대 {{ selMrgTpCd == '1' ? '추가':'제거' }} 가능 금액: {{ NumberComma(Number(maxMrgAmt)) }} USDT
            </div>
        </div>
        <div class="order-input-container margin-edit">
            <!-- <label for="">익절</label> -->
            <div class="base-dropdown no-border">
              <CoinDropDown id="marginEditType" :options="mrgTpCdList" :default="mrgTpCdList[0].name" :value="selMrgTpCd" @marginEditType="changeMarginEdit" />
            </div>
            <input type="number" placeholder="" v-model="modifyMrgPrc" />
            <button class="unit-btn" @click="setMaxAmt">Max</button>
        </div>
        <ul class="margin-edit-table-row">
            <li>
            <div class="label">현재 할당된 마진</div>
            <div class="value">{{ NumberComma(Number(this.mrgAmt)) }} USDT</div>
            </li>
            <li>
            <div class="label">최대 {{ selMrgTpCd == '1' ? '추가':'제거' }} 가능</div>
            <div class="value">{{ NumberComma(Number(maxMrgAmt)) }} USDT</div>
            </li>
            <li>
            <div class="label">{{ selMrgTpCd == '1' ? '추가':'제거' }} 후 예상 청산 가격</div>
            <div class="value">{{ NumberComma(Number(execClearPrc)) }} USDT</div>
            </li>
        </ul>
        </div>
    </div>

    <div class="modal-footer">
        <!-- <button class="btn-primary darkgray" @click="closeModal()">취소</button> -->
        <button class="btn-primary blue" @click="saveMrgEdit">확인</button>
    </div>
    </modal>
</div>
</template>
  
<script>
import CoinDropDown from '@/components/coin/CoinDropDown.vue';
import Utils from '@/modules/utils';
export default {
  name: 'CoinModalMarginEdit',
  components: {
    CoinDropDown,
  },
  data() {
    return {
      width: '',
      height: '',
      marginAction: '추가',
      modifyMrgPrc: '',
      selMrgTpCd: '1',
      mrgTpCdList: [{cd:'1', name: '추가'}, {cd:'2', name: '제거'}],
      marginData: {},
      mrgAmt: '',
      maxMrgAmt: '',
      execClearPrc: '',
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 500;
      this.height = 290;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    NumberComma(value) {
      if (value == undefined) {
        return 0;
      } else {
        return Utils.numberComma(Utils.toFixNum(value, 2));
      }
    },
    opened(){
    
    },
    beforeOpen(e){
      console.log("beforeOpen e.prams", e.params);

      // 초기화
      this.marginData = {};
      this.selMrgTpCd= '1';
      this.modifyMrgPrc = '';
      this.mrgAmt = '';
      this.maxMrgAmt = '',

      this.marginData = e.params;
      this.dailyPlMn = window.$store.getters['SocketCoinOms/getCoinDailyPlMn'];

      this.mrgAmt = this.marginData.mrgAmt;
      this.maxMrgAmt = this.dailyPlMn.ableUsdt;
      
    },
    //콤보박스 변경
    changeMarginEdit(chgVal){
      console.log("changeMarginEdit >>>> ", chgVal);
      this.selMrgTpCd = chgVal.cd;

      this.modifyMrgPrc = '';

      this.setMaxMrgAmt();
    },
    //금액 계산
    setMaxMrgAmt(){
      //최소 금액 구하기
      let mrgAmt = Utils.toFixNum(((this.marginData.execPrc * this.marginData.balQty) / this.marginData.lv), 2);

      let message = "코인 마진조정 팝업 ";      

      if (this.selMrgTpCd == "1"){
        this.maxMrgAmt = this.dailyPlMn.ableUsdt;
        message += "가격콤보 추가 선택";
      }else{
        this.maxMrgAmt = Utils.toFixNum((Number(this.mrgAmt) - Number(mrgAmt)), 2);
        message += "가격콤보 제거 선택"
      }

      this.sendCoinOmsLogMessage(message);

      //예상 청산 가격 계산 필요
      
    },
    setMaxAmt(){
      this.modifyMrgPrc = Utils.toFixNum(this.maxMrgAmt, 2);

      this.sendCoinOmsLogMessage("코인 마진조정 팝업 Max 버튼 클릭");
    },
    //확인
    saveMrgEdit(){
      if (Number(this.modifyMrgPrc) > Number(this.maxMrgAmt)){
        window.$alert.alert('최대 가능 금액을 초과 할 수 없습니다.', '알림');
        return;
      }else if (Number(this.modifyMrgPrc) == 0){
        //가격 변동없음
      }else{
        this.marginData.modifyMrgPrc = this.modifyMrgPrc;
        this.marginData.mrgTpCd = this.selMrgTpCd;
        this.$emit("event:marginEdit", this.marginData);
      }

      this.sendCoinOmsLogMessage("코인 마진조정 팝업 확인버튼 클릭");

      this.closeModal();      
    },
    sendCoinOmsLogMessage(message) { //코인 사용자 로그 저장
      window.$store.dispatch('SocketCoinOms/setLogMsg', { message: message });
    },
    closeModal() {
      this.$modal.hide('modalMarginEdit');
    },
  },
};
</script>
<style lang="scss" scoped></style>
  