<template>
  <div>
    <modal name="modalProfitLossTable" classes="main-modal" :adaptive="true" :width="width" :height="height"
      @before-open="beforeOpen" @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">매매손익</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>
      <div class="modal-body">
        <div class="tab-nav-container">
          <div class="tab" :class="{ active: selectedTab === 'tab1' }" @click="selectedTab = 'tab1'">일별손익</div>
          <div class="tab" :class="{ active: selectedTab === 'tab2' }" @click="selectedTab = 'tab2'">손익내역</div>
        </div>
        <div class="tab-content" v-show="selectedTab === 'tab1'">
          <div class="filter-container">
            <div class="filter-row">
              <div class="inner">
                <label for="" class="row-label">영업일</label>
                <div class="datepicker-container desktop">
                  <DatePicker v-model="gridSearch.fromDate" />
                  ~
                  <DatePicker v-model="gridSearch.toDate" />
                </div>
                <div class="datepicker-container mobile">
                  <DatePicker v-model="gridSearch.fromDate" />
                  ~
                  <DatePicker v-model="gridSearch.toDate" />
                </div>
              </div>
              <div class="inner">
                <!-- <button class="btn-primary darkgray">로그내역</button> -->
                <button class="btn-search" @click="search()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  조회
                </button>
              </div>
            </div>
          </div>

          <div class="ag-container" style="height: 420px">
            <AgCoinProfitLossTable :account="account" :setGridSearch="setGridSearch" />
          </div>
        </div>
        <div class="tab-content" v-show="selectedTab === 'tab2'">
          <div class="filter-container">
            <div class="filter-row">
              <div class="inner">
                <label for="" class="row-label">영업일</label>
                <div class="datepicker-container desktop">
                  <DatePicker v-model="gridSearch3.fromDate" />
                  ~
                  <DatePicker v-model="gridSearch3.toDate" />
                </div>
                <div class="datepicker-container mobile">
                  <DatePicker v-model="gridSearch3.fromDate" />
                  ~
                  <DatePicker v-model="gridSearch3.toDate" />
                </div>
              </div>
              <div class="inner">
                <!-- <button class="btn-primary darkgray">로그내역</button> -->
                <button class="btn-search" @click="search1()">
                  <img src="@/assets/icons/icon-search.svg" alt="" />
                  조회
                </button>
              </div>
            </div>
          </div>

          <div class="ag-container" style="height: 420px">
            <AgCoinExecListTable :account="account" :setGridSearch="setGridSearch3" />
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">hi</div> -->
    </modal>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import Checkbox from '@/components/Checkbox.vue';
import AgCoinProfitLossTable from '@/components/agTables/AgCoinProfitLossTable.vue';
import AgCoinExecListTable from '@/components/agTables/AgCoinExecListTable.vue';
import Utils from '@/modules/utils'
export default {
  name: 'ModalProfitLossTable',
  components: {
    DatePicker,
    Checkbox,
    AgCoinProfitLossTable,
    AgCoinExecListTable,
  },
  data() {
    return {
      selectedTab: 'tab1',
      width: '',
      height: '',
      account: {
        accNo: '',
        accId: 0,
      },
      gridSearch: {
        accId: 0,
        //accPass: '',
        fromDate: '',
        toDate: '',
        execYn: false,
      },
      gridSearch3: {
        accId: 0,
        //accPass: '',
        fromDate: '',
        toDate: '',
      },
      setGridSearch: {
        accId: 0,
        //accPass: '',
        fromDate: '',
        toDate: '',
      },
      setGridSearch3: {
        accId: 0,
        //accPass: '',
        fromDate: '',
        toDate: '',
      },
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 1000;
      this.height = 700;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() { },

  methods: {
    closeModal() {
      this.$modal.hide('modalProfitLossTable');
    },
    opened() {

    },
    beforeOpen(e) {
      //그리드 초기화
      this.selectedTab = 'tab1';
      this.selectTrdDt();

      this.gridSearch.execYn = false;

      this.account = Object.assign({}, e.params.account);
    },
    search() {
      this.gridSearch.accId = this.account.accId;
      this.setGridSearch = Object.assign({}, this.gridSearch);
    },
    search1() {
      this.gridSearch3.accId = this.account.accId;
      this.setGridSearch3 = Object.assign({}, this.gridSearch3);
    },
    async selectTrdDt() {
      const res = await window.$http.get('api/order/trdDt', { params: {} });
      if (res != undefined && res.data != undefined) {
        this.gridSearch.fromDate = res.data.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
        this.gridSearch.toDate = res.data.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
        this.gridSearch3.fromDate = res.data.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
        this.gridSearch3.toDate = res.data.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
      } else {
        this.gridSearch.fromDate = Utils.getToday();
        this.gridSearch.toDate = Utils.getToday();
        this.gridSearch3.fromDate = Utils.getToday();
        this.gridSearch3.toDate = Utils.getToday();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.filter-container {
  &.logtable {
    margin-top: 76px;
  }
}

.datepicker-container {
  margin-left: 0;

  &.desktop {
    @media (max-width: $mobile) {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @media (max-width: $mobile) {
      display: flex;

      .mx-datepicker {
        min-width: unset;
        width: unset;
        flex: 1;
      }
    }
  }
}

.checkbox-default {
  @media (max-width: $mobile) {
    display: none;
  }
}

.btn-primary {
  &.darkgray {

    /* margin-left: 20px; */
    @media (max-width: $mobile) {
      display: none;
    }
  }
}
</style>
