<template>
  <div class="ag-time">
    <div class="date">{{ valuePnl(params.data) }}</div>
    <div class="time">({{ valueRoe(params.data) }})</div>
  </div>
</template>
  
<script>
import Utils from '@/modules/utils';
export default {
  name: 'AgTpSl',
  computed:{
    getCoinSymbolList: function(){
      let symbolList = window.$store.getters['Symbol/getCoinSymbolList']
      return symbolList;
    },
  },
  weatch:{
    getCoinSymbolList(coinSymbolList){
      this.coinSymbolList = coinSymbolList;
    },
  },
  data() {
    return {
      coinSymbolList: [],
    }
  },
  created() {

  },
  mounted() {
    if (this.coinSymbolList == undefined || this.coinSymbolList.length == 0){
        this.coinSymbolList = window.$store.getters['Symbol/getCoinSymbolList'];
    }
  },
  methods: {
    showModal(name, params) {
      this.$modal.show(name, params.data);
    },
    tikDotSz(coinSymbol){
      if (coinSymbol == null){
        return 0;
      }
      return Utils.tikDotSz(coinSymbol);
    },
    valuePnl(data){
        let val = 0;
        if (data.valPl == undefined || data.valPl == null){
          val =  0;
        }else{
          let coinSymbol = null;
            this.coinSymbolList.forEach(item => {
            if (item.name == data.symCd){
              coinSymbol = item;
                return;
            }
            });
            //val = Number(data.valPl).toFixed(this.tikDotSz(coinSymbol));
            val = Utils.toFixNum(data.valPl, 2);
        }
        val = val + " USDT";

        return val;
    },
    valueRoe(data){
      let val = 0;
        if (data.valRoi == undefined || data.valRoi == null){
          val =  0;
        }else{
          let coinSymbol = null;
            this.coinSymbolList.forEach(item => {
            if (item.name == data.symCd){
              coinSymbol = item;
                return;
            }
            });
            //val = Number(data.valRoi).toFixed(this.tikDotSz(coinSymbol));
            val = Utils.toFixNum(data.valRoi, 2);
        }
        val = val + "%";

        return val;
    },
            //cellClassRules: cellClassRulesMinus,
            // valueFormatter: (d) =>{
            //   let val = 0;
            //   if (d.value == undefined || d.value == null){
            //     val =  0;
            //   }else{
            //     let coinSymbol = null;
            //       this.coinSymbolList.forEach(item => {
            //       if (item.name == d.data.symCd){
            //         coinSymbol = item;
            //           return;
            //       }
            //       });
            //       val = Number(d.value).toFixed(this.tikDotSz(coinSymbol));
            //   }
            //   val = val + " USDT";

            //   return val;
            //   //gridUtils.numberComma
            // }
  },
}
</script>
<style lang="scss" scoped>
.ag-time {
  text-align: center;
  font-size: 12px;
	line-height: 12px;
	.date {
    font-weight: bold;
  }
}
</style>