<template>
	<div>
    <span v-if="params.data.slOrdCnt == 0 || params.data.ordType == 'SL'">--</span>
		<button class="btn-link" @click="showModal('modalTPSL', params.data)" v-if="params.data.slOrdCnt > 0" >보기</button>
	</div>
</template>

<script>
export default {
  name: 'AgViewTpSl',
  components: {
  },
  computed: {
  },
  watch: {

  },
  data() {
    return {

    }
  },
  created(){
  },
  mounted() {
  },
  methods:{
		showModal(name, data) {
      this.$modal.show(name, data);
      console.log(name, data);
    },
  },

}
</script>