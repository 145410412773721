<template>
  <div>
    <modal name="modalLimitClose" classes="main-modal" :adaptive="true" :width="width" :height="height"
      @before-open="beforeOpen"
      @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">지정가 종료</h2>
        <button @click="closeLimit()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="modal-edit-order">
          <div class="order-input-group">
            <div class="order-input-container">
              <label for="">가격</label>
              <input type="number" v-model="lmtOrdPrc" placeholder="가격" value="0" />
              <div class="unit">USDT</div>
            </div>

            <div class="order-input-container">
              <label for="">수량</label>
              <input type="number" v-model="lmtOrdQty" placeholder="수량" value="0" />
              <div class="unit">{{ lmtOrdType }}</div>
            </div>
          </div>
        </div>
			</div>

      <div class="modal-footer">
        <!-- <button class="btn-primary darkgray" @click="closeModal()">취소</button> -->
        <button class="btn-primary blue" @click="limitOrder()">확인</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Utils from '@/modules/utils';
export default {  
  name: 'CoinModalLimitClose',

  data() {
    return {
      width: '',
      height: '',
      limitData: null,
      lmtOrdPrc: '',
      lmtOrdQty: '',
      lmtOrdType: 'BTC',
      coinSymbolInfo: null,
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 500;
      this.height = 228;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    opened(){
      
    },
    beforeOpen(e){
      console.log("beforeOpen e.prams", e.params)

      this.limitData = e.params;
      // 초기화
      this.lmtOrdPrc = 0;
      this.lmtOrdQty = 0;

      //이전 가격 수량 설정
      //this.lmtOrdPrc = e.params.execPrc;
      this.lmtOrdPrc = e.params.nowPrice;
      this.lmtOrdQty = e.params.balQty - e.params.ordQty; //포지션 수량 - 청산 수량

      const coinSymbolInfoList = window.$store.getters['Symbol/getCoinSymbolInfoList']

      for (let item of coinSymbolInfoList) {
        if (item.symNo == this.limitData.symNo) {
          this.coinSymbolInfo = item;
          this.lmtOrdType = item.artcCd;
          break;
        }
      }

      let message = "코인 포지션 리스트 지정가 클릭 "+ e.params.symCd;
      this.sendCoinOmsLogMessage(message);

      
    },
    closeModal() {
      this.$modal.hide('modalLimitClose');
    },
    closeLimit(){
      let message = "코인 포지션 리스트 지정가 닫기 클릭 "+ this.limitData.symCd;
      this.sendCoinOmsLogMessage(message);
      this.closeModal();
    },
    limitOrder(){
      if (!this.lmtOrdPrc || this.lmtOrdPrc <= 0){
        window.$alert.alert('가격을 입력 해주세요.', '알림');
        return;
      }
      
      if (!this.lmtOrdQty || this.lmtOrdQty <= 0){
        window.$alert.alert('수량을 입력 해주세요.', '알림');
        return;
      }

      let ordQty = 0;
      let minOrdQty = this.coinSymbolInfo.minTrdAmt; //db에서 읽어와야함

      ordQty = this.lmtOrdQty;

      //코인 최소 수량 가능한지 체크
      if (ordQty < minOrdQty){
        console.log("ordQty, minOrdQty ", ordQty, minOrdQty)
        window.$alert.alert('주문가능 최소 수량을 확인 해주세요.', '알림');
        return;
      }


      //포지션 수량 초과 체크
      if (Number(ordQty) > (Number(this.limitData.balQty) - Number(this.limitData.ordQty))){
        console.log("ordQty, limitData.ordQty ", ordQty, this.limitData.balQty)        
        window.$alert.alert('포지션 수량을 초과 할 수 없습니다.<br/>(미체결 청산 수량 포함)', '알림');
        return;
      }

      this.limitData.ordPrc = this.lmtOrdPrc;
      this.limitData.ordQty = this.lmtOrdQty;
      this.$emit("limitOrd", this.limitData);

      let message = "코인 포지션 리스트 지정가 확인 클릭 "+ this.limitData.symCd;
      this.sendCoinOmsLogMessage(message);

      this.closeModal();
    },
    sendCoinOmsLogMessage(message){ //코인 사용자 로그 저장
      window.$store.dispatch('SocketCoinOms/setLogMsg', {message:message});
    },
  },
};
</script>
<style lang="scss" scoped></style>
