<template>
  <div>
    <modal name="modalTPSlAll" classes="main-modal" :adaptive="true" :width="width" :height="height"
    @before-open="beforeOpen"
    @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">전체 포지션 이익실현(TP)/스탑로스(SL)</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="modal-tpsl-all">
          <table class="modal-tpsl-all-table">
            <tbody>
              <tr>
                <td>
                  <div class="label">심볼</div>
                  <div class="value"><span class="orange">{{ ordInfo.symCd}} {{ ordInfo.lv }}x</span></div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">진입가격</div>
                  <div class="value">{{ ordInfo.execPrc }} USDT</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="label">시장평균가</div>
                  <div class="value">{{ com.markPr }} USDT</div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="order-input-group">
            <div class="order-input-container">
              <label for="">이익실현(TP)</label>
              <input type="number" placeholder="" v-model="tpOrdPrc" />
              <div class="unit">
                <CoinCodeDropDown grpCd="PRCTP" v-model="ordTpType" width="64" height="72" popup />
              </div>
            </div>
            <p><span class="blue">시장평균가</span>가 <span class="blue">{{ tpOrdPrc == '' ? 0 : tpOrdPrc }}</span>에 도달하면 이익실현 시장가 주문을 발동하여 포지션을 종료합니다. 예상 PNL은 다음과 같습니다. <span class="red">-USDT</span></p>
          </div>

          <div class="order-input-group">
            <div class="order-input-container">
              <label for="">스탑로스(SL)</label>
              <input type="number" placeholder="" v-model="slOrdPrc" />
              <div class="unit">
                <CoinCodeDropDown grpCd="PRCTP" v-model="ordSlType" width="64" height="72" popup />
              </div>
            </div>
            <p><span class="blue">시장평균가</span>가 <span class="blue">{{ slOrdPrc == '' ? 0 : tpOrdPrc }}</span>에 도달하면 이익실현 시장가 주문을 발동하여 포지션을 종료합니다. 예상 PNL은 다음과 같습니다. <span class="blue">-USDT</span></p>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn-primary blue" @click="cancelTpSl('1')" v-if="tpId > 0">TP 취소</button>
        <button class="btn-primary blue" @click="cancelTpSl('2')" v-if="slId > 0">SL 취소</button>
        <button class="btn-primary blue" @click="saveTpSl">확인</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Utils from '@/modules/utils';
import CoinCodeDropDown from '@/components/coin/CoinCodeDropDown.vue';
export default {
  name: 'CoinModalTPSLAll',
  components: {
    CoinCodeDropDown,
  },

  computed: {
    //소켓 코인 시세
    changeCocData: function () {
      let cocData = window.$store.getters['SocketCoinPrice/getCocData'];
      return cocData;
    },
    //소켓 코인 청산가
    changeComData: function(){
      let comData = window.$store.getters['SocketCoinPrice/getComData']
      return comData
    },
    //폴링시세
    changeCocDataList: function () {
      let cocData = window.$store.getters['SocketCoinPrice/getCocDataList'];
      return cocData;
    },
    tikDotSz() {
      console.log("this.coinSymbol", this.coinSymbol)
      return Utils.tikDotSz(this.coinSymbol);
    },

  },
  watch: {
    //소켓시세
    changeCocData(newPrice) {
      //console.log("changeOvcData >>>> ", newPrice)
      //
    },
    //폴링시세
    changeCocDataList(newPrice) {
      //
    },
    //소켓 코인 청산가
    changeComData(newPrice){
      if (newPrice != null && this.ordInfo != null && newPrice.symbol == this.ordInfo.symCd){
        this.com.markPr = (Math.floor(newPrice.markPr * 100) / 100).toFixed(this.tikDotSz);
      }
    },
  },
  data() {
    return {
      width: '',
      height: '',
      ordTpType:'1',
      ordSlType:'1',
      ordInfo:{},
      coinSymbol: {},
      com: {markPr:0},
      coinSymbolInfoList:null,
      ordInfoList: [],
      orgOrdInfoList: [],
      tpOrdPrc: '',
      slOrdPrc: '',
      slId: 0,
      tpId: 0,
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 500;
      this.height = 472;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {},

  methods: {
    opened(){

    },
    beforeOpen(e){
      console.log("beforeOpen e.prams", e.params);

      //초기화
      this.ordInfo = {};
      this.orgOrdInfoList = [];
      this.ordTpType = '1';
      this.ordSlType = '1';
      this.tpOrdPrc = '';
      this.slOrdPrc = '';
      this.tpId = 0;
      this.slId = 0;
      this.ordInfo = e.params;

      this.coinSymbolList = window.$store.getters['Symbol/getCoinSymbolList'];

      this.coinSymbolList.forEach(item => {
        if (item.name == this.ordInfo.symCd){
          this.coinSymbol = item;
        }
      });

      this.com.markPr = (Math.floor(this.ordInfo.markPr * 100) / 100).toFixed(this.tikDotSz);

      this.searchOrdInfo();
    },
    //tpsl 목록 조회
    async searchOrdInfo(){
      // 
      // ordInfoList
      const data = {posSeq:this.ordInfo.posSeq};
      await this.$lhttp.get('/api/order/getCoinOrdSlList', { params: data }).then((response) => { 
        if (response.code == 200){
          console.log("response", response);
          //this.ordInfoList = Object.assign([], response.data);
          //원본 저장
          for (let item of response.data){
            this.orgOrdInfoList.push({slId:item.slId, slOrdPrc:item.slOrdPrc, slOrdQty:item.slOrdQty, slOrdSdCd:item.slOrdSdCd, slOrdStatCd:item.slOrdStatCd, slTpCd:item.slTpCd, prcTpCd:item.prcTpCd});
            if (item.slTpCd == "1"){
              this.tpId = item.slId;
              this.tpOrdPrc = item.slOrdPrc;
            }else if (item.slTpCd == "2"){
              this.slId = item.slId;
              this.slOrdPrc = item.slOrdPrc;
            }
          }
        }
      });
    },
    closeModal() {
      this.$modal.hide('modalTPSlAll');
    },
    changeTpSlType(){
      console.log("changeTpSlType", this.ordTpType);
    },
    //취소
    cancelTpSl(type){
      let saveItem =null;
      for (let item of this.orgOrdInfoList){
        if (type == item.slTpCd){
          saveItem = item;
          break;
        }
      }

      if (saveItem == null){
        window.$alert.alert('TP/SL 정보가 없습니다.')
        return;
      }

      let saveInfo = {ordInfo: this.ordInfo, tpSlData:saveItem};
      this.$emit("event:cancelTpSlPos", saveInfo);
      this.closeModal();
    },
    saveTpSl(){
      let saveInfo = {ordInfo: this.ordInfo, saveList:[]};
      let saveYn = "N";
      let slOrdSdCd = "0";
      if (this.ordInfo.ordSdCd == "1"){ //매도
        slOrdSdCd = "2"; //매수
      }else if (this.ordInfo.ordSdCd == "2"){ //매수
        slOrdSdCd = "1"; //매도
      }

      //수정이면
      if (this.orgOrdInfoList.length > 0){
        let slYn = "N";
        let tpYn = "N";
        for (let item of this.orgOrdInfoList){
          if (item.slTpCd == "1"){ //익절
            if (Number(item.slOrdPrc) != Number(this.tpOrdPrc)){
              item.slOrdPrc = this.tpOrdPrc;
              saveYn = "Y";
            }
            if (item.prcTpCd != this.ordTpType){
              saveYn = "Y";
            }
            slYn = "Y";
          }else if (item.slTpCd == "2"){ //손절
            if (Number(item.slOrdPrc) != Number(this.slOrdPrc)){
              item.slOrdPrc = this.slOrdPrc;
              saveYn = "Y";
            }
            if (item.prcTpCd != this.ordSlType){
              saveYn = "Y";
            }
            tpYn = "Y";
          }
          if (saveYn == "Y"){
            item.orgSlOrdPrc = item.slOrdPrc; //삭제할 가격
            saveInfo.saveList.push(item);
          }
        }

        //TP/SL 중 1개만 저장되어있으면 나머지 신규로 생성
        if (slYn == "N" && this.tpOrdPrc != null && Number(this.tpOrdPrc) > 0){ // 익절
          let item = {slId:0, posSeq:this.ordInfo.posSeq, slOrdPrc:this.tpOrdPrc, slOrdQty:0, slTpCd:"1", prcTpCd:this.ordTpType, slOrdSdCd:slOrdSdCd};
          saveInfo.saveList.push(item);
          saveYn = "Y";
        }else if (tpYn == "N" && this.slOrdPrc != null && Number(this.slOrdPrc) > 0){ // 손절
          let item = {slId:0, posSeq:this.ordInfo.posSeq, slOrdPrc:this.slOrdPrc, slOrdQty:0, slTpCd:"2", prcTpCd:this.ordSlType, slOrdSdCd:slOrdSdCd};
          saveInfo.saveList.push(item);
          saveYn = "Y";
        }
      }else { //신규저장
        if (this.tpOrdPrc != null && Number(this.tpOrdPrc) > 0){ //익절
          let item = {slId:0, posSeq:this.ordInfo.posSeq, slOrdPrc:this.tpOrdPrc, slOrdQty:0, slTpCd:"1", prcTpCd:this.ordTpType, slOrdSdCd:slOrdSdCd};
          saveInfo.saveList.push(item);
          saveYn = "Y";
        }
        if (this.slOrdPrc != null && Number(this.slOrdPrc) > 0){ //손절
          let item = {slId:0, posSeq:this.ordInfo.posSeq, slOrdPrc:this.slOrdPrc, slOrdQty:0, slTpCd:"2", prcTpCd:this.ordSlType, slOrdSdCd:slOrdSdCd};
          saveInfo.saveList.push(item);
          saveYn = "Y";
        }
      }

      console.log("saveInfo List?? ", saveInfo)

      //저장
      if (saveYn == "Y"){
        this.$emit("event:saveTpSl", saveInfo);
        this.closeModal();
      }else{
        this.closeModal();
      }

    },
  },
};
</script>
<style lang="scss" scoped></style>
