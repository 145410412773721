<template>
  <div class="base-dropdown no-border" :id="id" ref="dropdown">
    <div class="dropdown" @click="handleDropdown()" :style="{height:cdHeight + 'px'}">
      <div class="selected">{{ selectedName }}</div>
      <img src="@/assets/icons/dropdown-triangle.svg" alt="" class="dropdown-triangle" />
    </div>
    <ul class="dropdown-list" v-if="isDropdownVisible">
      <li
        v-for="(option, index) in options"
        :key="index"
        @click="
          selectedOption = option.name;
          isDropdownVisible = false;
          $emit(id, option);
        "
      >
      {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CoinDropDown',
  props: {
    options: {
      type: Array,
      required: true,
    },
    id: String,
    default: String,
    width: [String, Number],
    height: [String, Number],
    full: Boolean,
    popup: Boolean,
    cdHeight: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    }
  },
  computed: {
    selectedName() {
      let rtn = ''  
      if ( this.value != undefined && this.value) {
        for (let i in this.options) {
          if ( this.value == this.options[i].cd ) {
            rtn = this.options[i].name
            break
          }  
        }
        return rtn
      }else{
        return this.selectedOption;
      }
    }
  },
  watch: {
    default: {
      handler(newOption) {
        console.log("디폴트 콤보...", newOption)
          this.selectedOption = newOption;
      },
      deep:true
    }
  },
  data() {
    return {
      selectedOption: '',
      isDropdownVisible: false,
    };
  },


    mounted() {
    this.selectedOption = this.default ? this.default : this.options[0];
    window.addEventListener('click', this.closeDropdown);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.closeDropdown);
  },

  methods: {
    handleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
        closeDropdown(element) {
      if (!this.$refs.dropdown.contains(element.target)) {
        this.isDropdownVisible = false;
      }
    },
  },
};
</script>
  