<template>
  <div>
    <modal name="modalInstruments" classes="main-modal" :adaptive="true" :width="width" :height="height"
      @before-open="beforeOpen"
      @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">종목선택</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>
      <div class="modal-body">
        <div class="tab-nav-container">
          <div class="tab active">코인선물</div>
        </div>
        <div class="tab-content" v-if="selectedInstrument === 'tab3'">
          <div class="ag-container" :style="{height: agContainerHeight + 'px'}">
            <AgCoinTable
            @selectCoinSymbol="selectCoinSymbol"
            />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import AgCoinTable from '@/components/coin/AgCoinTable.vue';
import AgOptionsTable from '@/components/agTables/AgOptionsTable.vue';
export default {
components: { AgOptionsTable, AgCoinTable },
  name: 'ModalInstruments',
  data() {
    return {
      selectedInstrument: 'tab3',
      agContainerHeight: ''
    };
  },
  created() {
    if (window.innerWidth > 1200) {
      this.width = 1000;
      this.height = 600;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },
  mounted() {
    this.getAgContainerHeight()
  },
  methods: {
    closeModal() {
      this.$modal.hide('modalInstruments');
    },
    opened(){

    },
    beforeOpen(e){

    },
    show(name) {
      this.$modal.show(name)
    },
    getAgContainerHeight() {
      this.agContainerHeight = window.innerHeight - 60 - 112;
      // console.log(this.agContainerHeight);
    },
    selectCoinSymbol(){
      this.closeModal();
    }
  },
};
</script>