<template>
  <div>
    <modal name="modalLeverageSelect" classes="main-modal" :adaptive="true" :width="width" :height="height"
      @before-open="beforeOpen" @opened="opened">
      <div class="modal-header">
        <h2 class="modal-header-title">레버리지 조정</h2>
        <button @click="closeModal()"><img src="@/assets/icons/icon-close-modal.svg" alt="" /></button>
      </div>

      <div class="modal-body">
        <div class="modal-leverage-select">
          <div class="leverage-select-input">
            <div class="label">레버리지</div>
            <div class="input-group">
              <button @click="coinLeverageDown"><img src="@/assets/icons/minus-black.svg" alt=""></button>
              <!-- <div class="leverage-input">{{ coinLeverage }}x</div> -->
              <input type="text" v-model="formattedValue" maxlength="4" class="leverage-input">
              <button @click="coinLeverageUp"><img src="@/assets/icons/plus-black.svg" alt=""></button>
            </div>
          </div>
          <div>
            <SliderLeverage :leverageModeFlg="leverageModeFlg" @updateSliderLeverage="updateSliderLeverage"
              :value="Number(coinLeverage)" :min="1" :max=Number(maxLv) :divisor="5" />
          </div>
          <p>• 현재 레버리지에서의 최대 포지션 : {{ this.maxOrdAmt }} USDT</p>
          <p>• 레버리지 변경은 미체결 포지션 및 미체결 주문에도 적용됩니다.</p>
          <p v-if="leverageModeFlg == false">• 오픈 포지션이 있는 격리 마진 모드에서는 레버리지 감소가 지원되지 않습니다.</p>
          <div class="alert-notice">[10x]와 같은 더 높은 레버리지를 선택하면 청산 위험이 커집니다. 항상 위험 수준을 관리하세요.</div>
          <!-- <div class="alert-notice" v-if="leverageModeFlg == false">오픈 포지션이 있는 격리 마진 모드에서는 레버리지 감소가 지원되지 않습니다. </div> -->
        </div>
      </div>

      <div class="modal-footer">
        <!-- <button class="btn-primary darkgray" @click="closeModal()">취소</button> -->
        <button class="btn-primary blue" @click="selectCoinLeverage()">확인</button>
      </div>
    </modal>
  </div>
</template>

<script>
import SliderLeverage from '@/components/coin/SliderLeverage.vue';
import Utils from '@/modules/utils';
export default {
  components: { SliderLeverage },
  name: 'CoinModalLeverageSelect',

  data() {
    return {
      width: '',
      height: '',
      coinLeverage: 1,
      beforeLeverage: 1,
      leverageModeFlg: true,
      maxLv: 1,
      tierList: [],
      maxOrdAmt: ''
    };
  },

  created() {
    if (window.innerWidth > 1200) {
      this.width = 500;
      this.height = 380;
    } else {
      this.width = '100%';
      this.height = '100%';
    }
  },

  mounted() {
  },
  computed: {
    formattedValue: {
      get() {
        return `${this.coinLeverage}x`; // aaa 뒤에 X를 붙여서 표시
      },
      set(value) {
        const numericValue = parseInt(value.replace(/X$/, ''), 10); // 숫자만 추출
        if (!isNaN(numericValue)) {
          this.coinLeverage = numericValue;
        }
      },
    },
  },
  methods: {
    opened() {
    },
    beforeOpen(e) {
      //console.log("beforeOpen e.prams", e.params);
      //{marginMode: this.marginMode, coinAccount: this.coinAccount, coinSymbol:this.coinSymbol }

      // 초기화
      this.coinLeverage = 1;
      this.coinSymbol = null;
      this.coinAccount = null;

      this.coinLeverage = Number(e.params.coinLeverage);
      this.beforeLeverage = Number(e.params.coinLeverage);
      this.coinSymbol = e.params.coinSymbol;
      this.coinAccount = Object.assign({}, e.params.coinAccount);
      this.maxLv = e.params.tierList[0].maxLv
      this.tierList = e.params.tierList
      this.positionChk();
    },
    coinLeverageUp() {
      if (this.coinLeverage < this.maxLv) {
        this.coinLeverage++;
      }
      this.chkMaxOrdAmt(this.coinLeverage);
    },
    coinLeverageDown() {
      this.positionChk();
      if (this.leverageModeFlg == false && this.beforeLeverage >= this.coinLeverage) {
        return;
      }
      if (this.coinLeverage > 1) {
        this.coinLeverage--;
      }
      this.chkMaxOrdAmt(this.coinLeverage);
    },
    updateSliderLeverage(newVal) {
      this.coinLeverage = Number(newVal);
      this.chkMaxOrdAmt(newVal);
    },
    chkMaxOrdAmt(newVal) {
      //console.log(newVal)
      this.tierList.forEach(tl => {
        if (tl.maxLv >= newVal) {
          this.maxOrdAmt = Utils.numberComma(tl.maxOrdAmt);
        }
      })
    },
    async selectCoinLeverage() {
      //변경불가 조건 체크필요
      const data = { accNo: this.coinAccount.accNo, symCd: this.coinSymbol.name, ordType: "coin" };

      await this.$lhttp.get('/api/order/rtPosOrdChk', { params: data }).then((response) => {
        if (response.code == 200) {
          if (response.data == null) {
            this.$alert.alert('코인선물 잔고/미체결 조회중 에러가 발생했습니다.', '안내');
            return;
          }
          // else if (response.data.coinOrdQty != undefined && response.data.coinOrdQty > 0){
          //   this.$alert.alert('코인선물 잔고/미체결 내역이 있으면 마진 모드 변경 불가합니다.', '안내');
          //   this.cpos = response.data.cpos;
          //   return;
          // }

          //포지션 모드 저장
          const saveData = { accNo: this.coinAccount.accNo, symNo: this.coinSymbol.symbolno, leverage: this.coinLeverage };
          this.$lhttp.post('/api/order/usrPos/save', saveData).then((res) => {
            this.$emit("event:coinLeverage");
            this.closeModal();
          });

        }
      });


    },
    positionChk() {
      //포지션 체크
      const positionList = window.$store.getters['SocketCoinOms/getCoinPosOrdStList'];
      //console.log("positionList ", positionList)
      if (positionList != undefined && positionList.length > 0) {
        let balQty = 0;
        let ordQty = 0;
        let mitQty = 0;
        positionList.forEach(data => {
          if (data.symCd == this.coinSymbol.name && data.ordType == "POS") {
            balQty += data.balQty;
          } else if (data.symCd == this.coinSymbol.name && data.ordType == "ORD") {
            ordQty += data.balQty;
          } else if (data.symCd == this.coinSymbol.name && data.ordType == "ST") {
            mitQty += data.balQty;
          }
        });

        if (balQty > 0 || ordQty > 0 || mitQty > 0) {
          this.leverageModeFlg = false;
        } else {
          this.leverageModeFlg = true;
        }

      } else {
        this.leverageModeFlg = true;
      }
    },
    closeModal() {
      this.$modal.hide('modalLeverageSelect');
    },
  },
};
</script>
<style lang="scss" scoped></style>
